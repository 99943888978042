import styled from "styled-components";

const SolidButton = styled.button`
  padding: 1rem 2rem;
  border-radius: 16px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  font-family: "Quicksand", sans-serif;
  width: 360px;
  max-width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  transition: transform 0.01s ease;

  &.primary {
    background: #8747d4;
    color: white;
    border: none;
    box-shadow: 0 4px 0 #6535a3;
    position: relative;
    top: 0;

    &:hover {
      background: #9859e0;
    }

    &:active {
      top: 4px;
      box-shadow: 0 0 0 #6535a3;
    }
  }

  &.google-play {
    background: white;
    color: #333;
    border: 2px solid #e5e5e5;
    padding: 0.75rem 2rem;

    img {
      height: 32px;
      width: auto;
    }

    &:hover {
      background: #f7f7f7;
    }
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export default SolidButton;
