import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import CourseContent from "./CourseContent";
import { useAmazonContext } from "../../Contexts/AmazonContext";
import api from "../api";
import ColorThief from "colorthief";
import { motion, AnimatePresence } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faShare } from "@fortawesome/free-solid-svg-icons";
import { useHistory, Link } from "react-router-dom";
import StartCard from "./StartCard"; // Import the StartCard component
import SideNavRowQuiz from "../sidebar/SideNavRowQuiz";
import SideNavRowLesson from "../sidebar/SideNavRowLesson";
import LottieAnimation from "../LottieAnimation";

const Container = styled.div`
  max-width: 64rem;
  margin: 0 auto;
  padding: 1rem;
  padding-bottom: 5rem; // Add padding to prevent content from being hidden behind the fixed bar
`;

const AnimatedContainer = styled(motion.div)`
  max-width: 64rem;
  margin: 0 auto;
  padding: 1rem;
  padding-bottom: 5rem;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 1rem;
  left: 1rem;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: ${(props) => (props.isDarkMode ? "white" : "black")};
  z-index: 10;
`;

const Header = styled.div`
  text-align: center;
`;

const CourseImage = styled.img`
  margin: 0 auto;
  width: 200px;
  height: auto;
  margin-bottom: 1rem;
`;

const CourseTitle = styled.h1`
  font-size: 1.875rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
`;

const AuthorName = styled.p`
  font-size: 1.125rem;
`;

const InfoBox = styled.div`
  background-color: #d2cfe6;
  padding: 1rem;
  border-radius: 0.5rem;
  display: flex;
  justify-content: space-around;
  margin: 2rem 0;
`;

const InfoItem = styled.div`
  display: flex;
  align-items: center;
  color: #8747d4;
  font-weight: bold;
  text-align: center;
`;

const ComingSoonText = styled(motion.div)`
  color: #8747d4;
  font-weight: bold;
  font-size: 1.5rem;
  text-align: center;
  width: 100%;
`;

const Section = styled.section`
  margin-bottom: 2rem;
`;

const SectionTitle = styled.h2`
  font-size: 3vh;
  font-weight: 600;
  margin-bottom: 0.5rem;
`;

const List = styled.ul`
  list-style-type: disc;
  padding-left: 1.25rem;
  font-size: 2vh;
`;

const ListItem = styled.li`
  margin-bottom: 0.25rem;
`;

const OrderedList = styled.ol`
  list-style-type: decimal;
  padding-left: 1.25rem;
`;

const SmallText = styled.p`
  font-size: 2vh;

  margin-top: 0.5rem;
`;

const Button = styled.button`
  background-color: #3b82f6;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: #2563eb;
  }
`;

const FixedBottomBar = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1rem;
  background-color: white;
  border-top: 1px solid #8747d4;
  background: #8747d4;
  display: flex;
  justify-content: center;
`;

const StartCourseButton = styled(Button)`
  width: 100%;
  background-color: #10b981;

  &:hover {
    background-color: #059669;
  }

  @media only screen and (min-width: 601px) {
    width: 30%;
  }
`;

const TabContainer = styled.div`
  display: flex;
  border-bottom: 1px solid #e5e7eb;
  margin-bottom: 1rem;
`;

const Tab = styled.button`
  padding: 0.5rem 1rem;
  background-color: ${(props) => (props.active ? "#f3f4f6" : "transparent")};
  border: none;
  border-bottom: 2px solid
    ${(props) => (props.active ? "#3b82f6" : "transparent")};
  cursor: pointer;
  font-weight: ${(props) => (props.active ? "bold" : "normal")};
  transition: all 0.2s;

  &:hover {
    background-color: #f3f4f6;
  }
`;

const TabContent = styled.div`
  padding: 1rem 0;
`;

const FullScreenContinueButton = styled.button`
  font-family: "Quicksand", sans-serif;
  padding: 1rem 2rem;
  font-size: 1rem;
  width: 360px;
  max-width: 95%;
  border: 0;
  border-radius: 16px;
  font-weight: bold;
  color: white;
  transition: transform 0.01s ease;
  cursor: pointer;
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0 4px 0 #6535a0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background-color: #8747d4;

  &:hover {
    filter: brightness(110%);
  }

  &:active {
    transform: translateX(-50%) translateY(4px);
    box-shadow: none;
  }

  @media (max-width: 768px) {
    width: 100%;
    font-size: 1.2rem;
    padding: 0.8rem 1.6rem;
  }
`;

const StartCardContainer = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #d2cfe6;
  z-index: 1000;
`;

const ShareButton = styled.button`
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: ${(props) => (props.isDarkMode ? "white" : "black")};
  z-index: 10;
`;

const StartButtonContainer = styled.div`
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 360px;
  max-width: 95%;
  z-index: 1000;
`;

const SkeletonLoader = styled(motion.div)`
  width: 90%;
  height: 80px;
  margin-left: 5%;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  border-radius: 10px;
  margin-top: 10px;
`;

const LottieContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
`;

function CourseSummaryPage(props) {
  //set the courseTitle and courseDetails for whatever course the User is on and store in AmazonContext to be used across app
  let {
    courseTitleUserIsOn,
    setCourseTitleUserIsOn,
    courseDetails,
    setCourseDetails,
    topicDatafromBackEnd,
    setTopicDatafromBackEnd,
    exampleDatafromBackEnd,
    setExampleDatafromBackEnd,
    entryStore,
    setEntryStore,
    subContainer,
    progressData,
    setProgressData,
    chapterProgressData,
    setChapterProgressData,
    isDarkMode,
  } = useAmazonContext();

  const [activeTab, setActiveTab] = useState("about");
  const [numberOfQuizQuestions, setNumberOfQuizQuestions] = useState(0);
  const [numberOfLessons, setNumberOfLessons] = useState(0);
  const [numberOfVideos, setNumberOfVideos] = useState(0);
  const [approxCourseDuration, setApproxCourseDuration] = useState(0);
  const [newEntryStore, setNewEntryStore] = useState(null);
  const history = useHistory();
  const [isClosing, setIsClosing] = useState(false);
  const [showStartCard, setShowStartCard] = useState(false);
  const [textColor, setTextColor] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isLessonProgressEmpty, setIsLessonProgressEmpty] = useState(null);
  const [isLastLessonOfChapter, setIsLastLessonOfChapter] = useState(false);
  const [isFirstLessonOfChapter, setIsFirstLessonOfChapter] = useState(false);
  const [chapterOfLastCompletedLesson, setChapterOfLastCompletedLesson] =
    useState(null);
  const [lastUnlockedLessonNumber, setLastUnlockedLessonNumber] =
    useState(null);
  const [lastUnlockedLessonTitle, setLastUnlockedLessonTitle] = useState(null);
  const [lastUnlockedLessonImage, setLastUnlockedLessonImage] = useState(null);

  // Add these new state variables for tracking unlocked content
  const [unlockedLessons, setUnlockedLessons] = useState([]);
  const [unlockedChapters, setUnlockedChapters] = useState([]);
  const [unlockedQuizzes, setUnlockedQuizzes] = useState([]);

  const startAudio = useRef(
    new Audio(
      "https://hhcourses-assets.s3.us-east-2.amazonaws.com/General/Audio/CourseStart.mp3"
    )
  );

  // Function to handle Start button click
  const handleStartClick = () => {
    if (isLoggedIn) {
      // Add small delay to see button press animation
      setTimeout(() => {
        setShowStartCard(true);
        startAudio.current.play();
      }, 150); // 150ms delay to see the button press
    } else {
      setTimeout(() => {
        const currentPath = history.location.pathname;
        history.push(`/LoginPage?redirect=${encodeURIComponent(currentPath)}`);
      }, 150);
    }
  };
  const handleClose = () => {
    setIsClosing(true);

    // Reset context values
    setCourseTitleUserIsOn("");
    setCourseDetails(null);
    setTopicDatafromBackEnd([]);
    setExampleDatafromBackEnd([]);
    setEntryStore([]);

    setTimeout(() => {
      history.push("/home");
    }, 500);
  };

  useEffect(() => {
    startAudio.current.load();
  }, []);

  useEffect(() => {
    const courseFromUrl = props.match.params.courseTitle;
    console.log("Setting course title from URL:", {
      courseFromUrl,
      previousTitle: courseTitleUserIsOn,
    });
    setCourseTitleUserIsOn(courseFromUrl);
  }, [props.match.params.courseTitle, setCourseTitleUserIsOn]);

  useEffect(() => {
    const loadCourseData = async () => {
      setIsLoading(true);
      setCourseTitleUserIsOn(props.match.params.courseTitle);

      // Clear existing data
      setNewEntryStore(null);
      setEntryStore([]);
      setTopicDatafromBackEnd([]);
      setExampleDatafromBackEnd([]);
      setCourseDetails(null);

      try {
        // Load course details first
        const courseResponse = await api.get("/coursesData");
        const allCourses = courseResponse.data;
        const courseChosen = allCourses.find(
          (o) =>
            o.courseTitle.replace(/\s/g, "") === props.match.params.courseTitle
        );
        setCourseDetails(courseChosen);

        if (courseChosen) {
          // Load all course data in parallel
          const [sideBarData, topicsData, examplesData] = await Promise.all([
            api.get(
              `/${props.match.params.courseTitle}/sideBarData?collectionName=${courseChosen.courseTopicsCollectionName}&modelName=${courseChosen.courseTopicsModelName}`
            ),
            api.get(
              `/${props.match.params.courseTitle}/topicsData?collectionName=${courseChosen.courseTopicsCollectionName}&modelName=${courseChosen.courseTopicsModelName}`
            ),
            api.get(
              `/examplesData?collectionName=${courseChosen.courseExamplesCollectionName}&modelName=${courseChosen.courseExamplesModelName}`
            ),
          ]);

          setEntryStore(sideBarData.data);
          setTopicDatafromBackEnd(topicsData.data);
          setExampleDatafromBackEnd(examplesData.data);
        }
      } catch (error) {
        console.error("Error loading course data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    loadCourseData();
  }, [props.match.params.courseTitle]);

  useEffect(() => {
    courseDetails &&
      api
        .get(
          `/${courseTitleUserIsOn}/topicsData?collectionName=${courseDetails.courseTopicsCollectionName}&modelName=${courseDetails.courseTopicsModelName}`
        )
        .then((response) => {
          //counting topic youtube links

          let numberOfLessons = 0;
          let numberOfTopicsWithSections = 0;
          let numberOfVideos = 0;

          for (let i = 0; i < response.data.length; i++) {
            numberOfLessons += response.data[i].sections.length;
            if (response.data[i].sections.length !== 0) {
              numberOfTopicsWithSections += 1;
            }
            if (response.data[i].topicYoutubeLink) {
              numberOfVideos += 1;
            }
          }

          setNumberOfLessons(numberOfLessons);
          setNumberOfVideos(numberOfVideos);
          let timeToCompleteEachLesson = 0.7;
          let timeToCompleteEachTopicQuiz = 1;
          let addedBufferTime = 1;
          const calculatedDuration = timeToCompleteEachLesson * numberOfLessons;
          setApproxCourseDuration(Math.ceil(calculatedDuration));
          console.log(response.data);
        });
    courseDetails &&
      api
        .get(
          `/examplesData?collectionName=${courseDetails.courseExamplesCollectionName}&modelName=${courseDetails.courseExamplesModelName}`
        )
        .then((response) => {
          // setExampleDatafromBackEnd(response.data);
          setNumberOfQuizQuestions(response.data.length);
          console.log(response.data);
        });
  }, [courseDetails]);
  console.log(entryStore, topicDatafromBackEnd, exampleDatafromBackEnd);

  // let numberOfLessons = 0;
  // let numberOfVideos = 0;
  let numberOfTopicsWithSections = 0;

  // counting section youtube links
  // for (let i = 0; i < topicDatafromBackEnd.length; i++) {
  //   numberOfLessons += topicDatafromBackEnd[i].sections.length;
  //   if (topicDatafromBackEnd[i].sections.length !== 0) {
  //     //if the topic has a section
  //     numberOfTopicsWithSections += 1;
  //     for (let j = 0; j < topicDatafromBackEnd[i].sections.length; j++) {
  //       if (topicDatafromBackEnd[i].sections[j].sectonYoutubeLink) {
  //         //count the sections with videos
  //         numberOfVideos += 1;
  //       }
  //     }
  //   }
  // }

  console.log(numberOfLessons);
  console.log(numberOfVideos);
  console.log(numberOfTopicsWithSections);

  console.log(topicDatafromBackEnd);

  // let numberOfQuizQuestions = exampleDatafromBackEnd.length;

  console.log(numberOfQuizQuestions);

  // let approxCourseDuration = timeToCompleteEachLesson * numberOfLessons;

  console.log(approxCourseDuration);

  async function calculateDominantColor(imageUrl) {
    try {
      const cloudFrontUrl = convertToCloudFrontUrl(imageUrl);
      const response = await fetch(cloudFrontUrl, {
        method: "GET",
        headers: { "Cache-Control": "no-cache" },
      });
      if (response.ok) {
        const blob = await response.blob();
        return new Promise((resolve) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            const image = new Image();
            image.src = reader.result;
            image.onload = () => {
              const colorThief = new ColorThief();
              const dominantColorRGB = colorThief.getColor(image);
              resolve(`rgb(${dominantColorRGB.join(",")})`);
            };
          };
          reader.readAsDataURL(blob);
        });
      }
    } catch (error) {
      console.error("Error fetching image:", error);
      return null;
    }
  }

  async function addDominantColors(entryStore) {
    return Promise.all(
      entryStore.map(async (topic) => {
        const updatedEntries = await Promise.all(
          topic.entries.map(async (entry) => {
            if (entry.entries && entry.entries[0] && entry.entries[0].entries) {
              const firstSectionImage =
                entry.entries[0].entries[0].sectionImage;
              const dominantColor = await calculateDominantColor(
                firstSectionImage
              );
              return {
                ...entry,
                dominantColor, // Add dominantColor here
              };
            }
            return entry;
          })
        );
        return {
          ...topic,
          entries: updatedEntries,
        };
      })
    );
  }

  // In your CourseSummaryPage component:
  useEffect(() => {
    if (entryStore) {
      addDominantColors(entryStore)
        .then((newStore) => {
          setNewEntryStore(newStore);
          console.log("New Entry Store:", newStore); // Add this line to check the result
        })
        .catch((error) =>
          console.error("Error adding dominant colors:", error)
        );
    }
  }, [entryStore]);

  console.log(newEntryStore);

  useEffect(() => {
    if (newEntryStore?.[0]?.entries?.[0]?.dominantColor) {
      const luminance = calculateLuminance(
        newEntryStore?.[0]?.entries?.[0]?.dominantColor
      );
      setTextColor(luminance > 0.5 ? "#000" : "#fff");
    }
  }, [newEntryStore]);

  const calculateLuminance = (color) => {
    const rgb = color.match(/\d+/g);
    const luminance = (0.299 * rgb[0] + 0.587 * rgb[1] + 0.114 * rgb[2]) / 255;
    return luminance;
  };

  useEffect(() => {
    const storedIsLoggedIn = localStorage.getItem("isLoggedIn");
    setIsLoggedIn(storedIsLoggedIn === "true");
  }, []);

  const handleShare = async () => {
    const courseUrl = window.location.href;
    const courseTitle = courseDetails?.courseCardTitle || "This course";
    const shareText = `Check out ${courseTitle} on HH Courses!`;

    if (navigator.share) {
      try {
        await navigator.share({
          title: courseTitle,
          text: shareText,
          url: courseUrl,
        });
      } catch (error) {
        console.error("Error sharing:", error);
      }
    } else {
      // Fallback for browsers that don't support the Web Share API
      try {
        const fullShareText = `${shareText}\n${courseUrl}`;
        await navigator.clipboard.writeText(fullShareText);
        alert("Course information copied to clipboard!");
      } catch (error) {
        console.error("Error copying to clipboard:", error);
      }
    }
  };

  const convertToCloudFrontUrl = (s3Url) => {
    if (!s3Url) return s3Url;
    return s3Url.replace(
      "https://hhcourses-assets.s3.us-east-2.amazonaws.com",
      "https://d10jmd9bhq0s4v.cloudfront.net"
    );
  };

  // First useEffect to fetch progress data
  useEffect(() => {
    const userEmail = localStorage.getItem("userEmail");
    if (!userEmail) {
      console.error("User email not found in local storage");
      return;
    }

    const fetchProgress = async () => {
      try {
        const response = await api.get("/fetchProgressRecord", {
          params: {
            userEmail: userEmail,
          },
        });
        setProgressData(response.data);
      } catch (error) {
        console.error("Error fetching progress data:", error);
      }
    };

    fetchProgress();
  }, []);

  // Second useEffect to fetch chapter progress data
  useEffect(() => {
    const userEmail = localStorage.getItem("userEmail");
    if (!userEmail) {
      console.error("User email not found in local storage");
      return;
    }

    const fetchChapterProgress = async () => {
      try {
        const response = await api.get("/fetchChapterProgressRecord", {
          params: {
            userEmail: userEmail,
          },
        });
        setChapterProgressData(response.data);
      } catch (error) {
        console.error("Error fetching chapter progress data:", error);
      }
    };

    fetchChapterProgress();
  }, []);

  // Third useEffect for processing the data (like in Sidenav)
  useEffect(() => {
    console.log(progressData);
    console.log(courseTitleUserIsOn);

    if (!progressData || !entryStore || !courseTitleUserIsOn) return;

    // Simplify data structure exactly as in Sidenav
    function simplifyData(data) {
      return (
        data &&
        data
          .map((topicHeader) => {
            return topicHeader.entries.map((chapter) => {
              return {
                chapterNumber: chapter.topicNumber,
                chapterTitle: chapter.title,
                lessons: chapter.entries[0].entries.map((lesson) => {
                  return {
                    lessonNumber: lesson.sectionNumber,
                    lessonTitle: lesson.title,
                    lessonImage: lesson.sectionImage,
                  };
                }),
              };
            });
          })
          .flat()
      );
    }

    const simplifiedChaptersLessonsData = simplifyData(entryStore);
    console.log(simplifiedChaptersLessonsData);

    // Generate unlocked lessons exactly as in Sidenav
    function generateunlockedLessons(
      progressData,
      simplifiedChaptersLessonsData,
      chapterProgressData,
      courseTitleUserIsOn
    ) {
      const unlockedLessons = [];

      const userProgress =
        progressData &&
        progressData.find(
          (progress) => progress.courseProgress === courseTitleUserIsOn
        );
      if (userProgress) {
        const { lessonProgress } = userProgress;
        const lastCompletedLesson = lessonProgress[lessonProgress.length - 1];
        const chapterContainingLastCompletedLesson =
          simplifiedChaptersLessonsData &&
          simplifiedChaptersLessonsData.find((chapter) => {
            return chapter.lessons.some(
              (lesson) => lesson.lessonNumber === lastCompletedLesson
            );
          });

        if (chapterContainingLastCompletedLesson) {
          const { lessons } = chapterContainingLastCompletedLesson;
          const lastCompletedLessonIndex = lessons.findIndex(
            (lesson) => lesson.lessonNumber === lastCompletedLesson
          );
          if (lastCompletedLessonIndex === lessons.length - 1) {
            unlockedLessons.push(...lessonProgress);
            const nextChapterIndex =
              simplifiedChaptersLessonsData.findIndex(
                (chapter) =>
                  chapter.chapterNumber ===
                  chapterContainingLastCompletedLesson.chapterNumber
              ) + 1;
            const nextChapter = simplifiedChaptersLessonsData[nextChapterIndex];
            if (nextChapter) {
              const chapterProgress =
                chapterProgressData &&
                chapterProgressData.find(
                  (progress) => progress.courseProgress === courseTitleUserIsOn
                )?.chapterProgress;
              if (
                chapterProgress &&
                chapterProgress.includes(
                  chapterContainingLastCompletedLesson.chapterNumber
                )
              ) {
                const nextLesson = nextChapter.lessons[0];
                unlockedLessons.push(nextLesson.lessonNumber);
              }
            }
          } else {
            const nextLesson = lessons[lastCompletedLessonIndex + 1];
            unlockedLessons.push(...lessonProgress, nextLesson.lessonNumber);
          }
        }
      }

      return unlockedLessons;
    }

    const unlockedLessons = generateunlockedLessons(
      progressData,
      simplifiedChaptersLessonsData,
      chapterProgressData,
      courseTitleUserIsOn
    );
    setUnlockedLessons(unlockedLessons);

    // Generate unlocked quizzes
    function generateUnlockedQuizzes(
      progressData,
      simplifiedChaptersLessonsData,
      courseTitleUserIsOn
    ) {
      const unlockedQuizzes = [];

      const userProgress =
        progressData &&
        progressData.find(
          (progress) => progress.courseProgress === courseTitleUserIsOn
        );
      if (userProgress) {
        simplifiedChaptersLessonsData &&
          simplifiedChaptersLessonsData.forEach((chapter) => {
            const lastLessonNumber =
              chapter.lessons[chapter.lessons.length - 1].lessonNumber;
            const isLastLessonCompleted =
              userProgress.lessonProgress.includes(lastLessonNumber);
            if (isLastLessonCompleted) {
              unlockedQuizzes.push(chapter.chapterNumber);
            }
          });
      }

      return unlockedQuizzes;
    }

    // Generate unlocked chapters
    function generateUnlockedChapters(
      simplifiedChaptersLessonsData,
      unlockedLessons
    ) {
      const unlockedChapters = [];

      simplifiedChaptersLessonsData &&
        simplifiedChaptersLessonsData.forEach((chapter) => {
          const hasUnlockedLesson = chapter.lessons.some((lesson) =>
            unlockedLessons.includes(lesson.lessonNumber)
          );
          if (hasUnlockedLesson) {
            unlockedChapters.push(chapter.chapterNumber);
          }
        });

      return unlockedChapters;
    }

    const unlockedQuizzes = generateUnlockedQuizzes(
      progressData,
      simplifiedChaptersLessonsData,
      courseTitleUserIsOn
    );
    const unlockedChapters = generateUnlockedChapters(
      simplifiedChaptersLessonsData,
      unlockedLessons
    );

    setUnlockedQuizzes(unlockedQuizzes);
    setUnlockedChapters(unlockedChapters);

    // Calculate last unlocked lesson info
    const lastUnlockedLessonNumber =
      unlockedLessons[unlockedLessons.length - 1];
    const { title: lastUnlockedLessonTitle, image: lastUnlockedLessonImage } =
      getLessonTitle(lastUnlockedLessonNumber, simplifiedChaptersLessonsData);

    setLastUnlockedLessonNumber(lastUnlockedLessonNumber);
    setLastUnlockedLessonTitle(lastUnlockedLessonTitle);
    setLastUnlockedLessonImage(lastUnlockedLessonImage);

    // Calculate if last lesson is first/last of chapter
    const currentCourseProgress =
      progressData &&
      progressData.find(
        (progress) => progress.courseProgress === courseTitleUserIsOn
      );

    if (currentCourseProgress) {
      const lastCompletedLesson =
        currentCourseProgress.lessonProgress.slice(-1)[0];
      console.log("Last completed lesson:", lastCompletedLesson);

      const chapterWithLastLesson = simplifiedChaptersLessonsData.find(
        (chapter) =>
          chapter.lessons.some(
            (lesson) => lesson.lessonNumber === lastCompletedLesson
          )
      );

      if (chapterWithLastLesson) {
        const { lessons } = chapterWithLastLesson;
        const lastCompletedLessonIndex = lessons.findIndex(
          (lesson) => lesson.lessonNumber === lastCompletedLesson
        );

        // Check if this chapter's quiz is already completed
        const chapterNumber = chapterWithLastLesson.chapterNumber;
        const isChapterQuizCompleted =
          chapterProgressData &&
          chapterProgressData
            .find((progress) => progress.courseProgress === courseTitleUserIsOn)
            ?.chapterProgress.includes(chapterNumber);

        // Only show quiz if chapter quiz isn't completed yet
        setIsLastLessonOfChapter(
          lastCompletedLessonIndex === lessons.length - 1 &&
            !isChapterQuizCompleted
        );
        setIsFirstLessonOfChapter(lastCompletedLessonIndex === 0);
        setChapterOfLastCompletedLesson(chapterWithLastLesson);

        // If chapter quiz is completed, show next chapter's first lesson
        if (isChapterQuizCompleted) {
          const nextChapterIndex =
            simplifiedChaptersLessonsData.findIndex(
              (chapter) =>
                chapter.chapterNumber === chapterWithLastLesson.chapterNumber
            ) + 1;
          if (nextChapterIndex < simplifiedChaptersLessonsData.length) {
            const nextChapter = simplifiedChaptersLessonsData[nextChapterIndex];
            const firstLesson = nextChapter.lessons[0];
            setLastUnlockedLessonNumber(firstLesson.lessonNumber);
            setLastUnlockedLessonTitle(firstLesson.lessonTitle);
            setLastUnlockedLessonImage(firstLesson.lessonImage);
          }
        }
      }
    }

    // Set lesson progress empty state
    setIsLessonProgressEmpty(
      currentCourseProgress && currentCourseProgress.lessonProgress.length === 0
    );
  }, [progressData, entryStore, courseTitleUserIsOn, chapterProgressData]);

  const renderStartButton = () => {
    console.log("Rendering start button with states:", {
      isLoggedIn,
      isLessonProgressEmpty,
      isLastLessonOfChapter,
      isFirstLessonOfChapter,
      chapterOfLastCompletedLesson,
      lastUnlockedLessonNumber,
      lastUnlockedLessonTitle,
      lastUnlockedLessonImage,
    });

    return (
      <StartButtonContainer>
        {!isLoggedIn ? (
          <FullScreenContinueButton onClick={handleStartClick}>
            LOGIN TO START
          </FullScreenContinueButton>
        ) : isLessonProgressEmpty == null ? (
          <FullScreenContinueButton onClick={handleStartClick}>
            START
          </FullScreenContinueButton>
        ) : (
          <AnimatePresence>
            {!lastUnlockedLessonNumber ? (
              <SkeletonLoader
                initial={{ opacity: 0 }}
                animate={{
                  opacity: 1,
                  backgroundPosition: ["100% 0", "-100% 0"],
                }}
                exit={{ opacity: 0 }}
                transition={{
                  backgroundPosition: {
                    duration: 1,
                    repeat: Infinity,
                    ease: "linear",
                  },
                }}
              />
            ) : isLastLessonOfChapter && !isFirstLessonOfChapter ? (
              <motion.div
                className="ContinueSideNavContainer"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
              >
                {/* <div className="LessonTitleContainer">
                  <span className="LessonNumber" style={{ color: "#8747d4" }}>
                    Continue
                  </span>
                </div> */}
                <Link
                  to={`/${courseTitleUserIsOn}/quiz/${chapterOfLastCompletedLesson.chapterNumber}`}
                >
                  <motion.div
                    whileTap={{ scale: 0.8 }}
                    transition={{ type: "spring", stiffness: 400, damping: 17 }}
                  >
                    <motion.div
                      animate={{ y: [-5, 5, -5] }}
                      transition={{ duration: 2, repeat: Infinity }}
                    >
                      <SideNavRowQuiz
                        number={chapterOfLastCompletedLesson.chapterNumber}
                        title={chapterOfLastCompletedLesson.chapterTitle}
                        width="90%"
                        marginLeft="5%"
                      />
                    </motion.div>
                  </motion.div>
                </Link>
              </motion.div>
            ) : (
              <motion.div
                className="ContinueSideNavContainer"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
              >
                {/* <div className="LessonTitleContainer">
                  <span className="LessonNumber" style={{ color: "#8747d4" }}>
                    Continue
                  </span>
                </div> */}
                <Link
                  to={`/${courseTitleUserIsOn}/lesson/${lastUnlockedLessonNumber}`}
                >
                  <motion.div
                    whileTap={{ scale: 0.8 }}
                    transition={{ type: "spring", stiffness: 400, damping: 17 }}
                  >
                    <motion.div
                      animate={{ y: [-5, 5, -5] }}
                      transition={{ duration: 2, repeat: Infinity }}
                    >
                      <SideNavRowLesson
                        backgroundColor="white"
                        image={lastUnlockedLessonImage}
                        number={lastUnlockedLessonNumber}
                        title={lastUnlockedLessonTitle}
                        width="90%"
                        marginLeft="5%"
                        borderRadius="14% 2% 2% 14% / 50% 10% 10% 50%"
                      />
                    </motion.div>
                  </motion.div>
                </Link>
              </motion.div>
            )}
          </AnimatePresence>
        )}
      </StartButtonContainer>
    );
  };

  // Add this helper function
  function getLessonTitle(lessonNumber, simplifiedChaptersLessonsData) {
    for (const chapter of simplifiedChaptersLessonsData) {
      const lesson = chapter.lessons.find(
        (lesson) => lesson.lessonNumber === lessonNumber
      );
      if (lesson) {
        return {
          title: lesson.lessonTitle,
          image: lesson.lessonImage,
        };
      }
    }
    return { title: "", image: "" };
  }

  const renderInfoBox = () => {
    if (courseDetails?.courseCategory === "Coming Soon") {
      return (
        <InfoBox>
          <ComingSoonText
            initial={{ opacity: 0, y: 20 }}
            animate={{
              opacity: [1, 0.5, 1],
              y: [0, -10, 0],
            }}
            transition={{
              duration: 2,
              repeat: Infinity,
              ease: "easeInOut",
            }}
          >
            Coming Soon
          </ComingSoonText>
        </InfoBox>
      );
    }

    return (
      <InfoBox>
        <InfoItem className={isDarkMode ? "darkThemeFont" : "lightThemeFont"}>
          {numberOfLessons}
          <br /> Lessons
        </InfoItem>
        <InfoItem className={isDarkMode ? "darkThemeFont" : "lightThemeFont"}>
          {approxCourseDuration} <br />
          mins
        </InfoItem>
      </InfoBox>
    );
  };

  const shouldShowStartButton = courseDetails?.courseCategory !== "Coming Soon";

  return (
    <AnimatePresence>
      {!isClosing && (
        <>
          <AnimatedContainer
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{
              duration: 0.5,
            }}
          >
            {!showStartCard && (
              <>
                <CloseButton onClick={handleClose} isDarkMode={isDarkMode}>
                  <FontAwesomeIcon icon={faTimes} />
                </CloseButton>
                <Header>
                  <CourseImage
                    src={
                      courseDetails &&
                      convertToCloudFrontUrl(courseDetails.courseImage)
                    }
                    alt={courseDetails && courseDetails.courseCardTitle}
                  />
                  <CourseTitle
                    className={isDarkMode ? "darkThemeFont" : "lightThemeFont"}
                  >
                    {courseDetails && courseDetails.courseCardTitle}
                  </CourseTitle>
                  <AuthorName
                    className={isDarkMode ? "darkThemeFont" : "lightThemeFont"}
                  >
                    by {courseDetails && courseDetails.courseAuthor}
                  </AuthorName>
                </Header>

                {renderInfoBox()}

                {courseDetails?.courseCategory !== "Coming Soon" && (
                  <TabContainer>
                    <Tab
                      className={
                        activeTab !== "about"
                          ? isDarkMode
                            ? "darkThemeFont"
                            : "lightThemeFont"
                          : ""
                      }
                      active={activeTab === "about"}
                      onClick={() => setActiveTab("about")}
                    >
                      About
                    </Tab>
                    <Tab
                      className={
                        activeTab !== "chapters"
                          ? isDarkMode
                            ? "darkThemeFont"
                            : "lightThemeFont"
                          : ""
                      }
                      active={activeTab === "chapters"}
                      onClick={() => setActiveTab("chapters")}
                    >
                      Chapters
                    </Tab>
                  </TabContainer>
                )}

                <TabContent>
                  {activeTab === "about" && (
                    <>
                      <Section>
                        <SectionTitle
                          className={
                            isDarkMode ? "darkThemeFont" : "lightThemeFont"
                          }
                        >
                          What's inside?
                        </SectionTitle>
                        <p
                          className={
                            isDarkMode ? "darkThemeFont" : "lightThemeFont"
                          }
                          style={{ fontSize: "2vh" }}
                        >
                          {courseDetails && courseDetails.courseDescription}
                        </p>
                      </Section>

                      <Section>
                        <SectionTitle
                          className={
                            isDarkMode ? "darkThemeFont" : "lightThemeFont"
                          }
                        >
                          You'll learn
                        </SectionTitle>
                        <List>
                          {courseDetails?.courseGoals?.map((goal, index) => (
                            <ListItem
                              className={
                                isDarkMode ? "darkThemeFont" : "lightThemeFont"
                              }
                              key={index}
                            >
                              {goal}
                            </ListItem>
                          ))}
                        </List>
                      </Section>

                      <Section>
                        <SectionTitle
                          className={
                            isDarkMode ? "darkThemeFont" : "lightThemeFont"
                          }
                        >
                          About {courseDetails?.courseAuthor}
                        </SectionTitle>
                        <p
                          className={
                            isDarkMode ? "darkThemeFont" : "lightThemeFont"
                          }
                          style={{ fontSize: "2vh" }}
                        >
                          {courseDetails?.courseAuthorBio}
                        </p>
                        {courseDetails?.courseReferenceDescription && (
                          <SmallText
                            className={
                              isDarkMode ? "darkThemeFont" : "lightThemeFont"
                            }
                          >
                            {courseDetails.courseReferenceDescription}
                          </SmallText>
                        )}
                      </Section>
                    </>
                  )}

                  {activeTab === "chapters" &&
                    courseDetails?.courseCategory !== "Coming Soon" && (
                      <Section>
                        {isLoading ? (
                          <LottieContainer>
                            <LottieAnimation
                              lottieFile="https://hhcourses-assets.s3.us-east-2.amazonaws.com/General/LottieFiles/loading.json"
                              loop={true}
                            ></LottieAnimation>
                          </LottieContainer>
                        ) : (
                          <CourseContent newEntryStore={newEntryStore} />
                        )}
                      </Section>
                    )}
                </TabContent>

                {courseDetails && courseDetails.pdfUrl && (
                  <div style={{ textAlign: "center", marginBottom: "2rem" }}>
                    <Button>Get Full Book PDF</Button>
                  </div>
                )}

                {shouldShowStartButton && renderStartButton()}
              </>
            )}
          </AnimatedContainer>

          <AnimatePresence>
            {showStartCard && (
              <StartCardContainer
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.5 }}
              >
                <StartCard
                  nextChapterNumber={
                    newEntryStore?.[0]?.entries?.[0]?.topicNumber ||
                    "First Lesson"
                  }
                  nextChapterTitle={
                    newEntryStore?.[0]?.entries?.[0]?.title || "First Lesson"
                  }
                  nextChapterFirstLessonNumber={
                    newEntryStore?.[0]?.entries?.[0]?.entries?.[0]?.entries?.[0]
                      ?.sectionNumber || "First Lesson"
                  }
                  nextChapterFirstLessonTitle={
                    newEntryStore?.[0]?.entries?.[0]?.entries?.[0]?.entries?.[0]
                      ?.title || "First Lesson"
                  }
                  nextChapterFirstLessonImage={
                    newEntryStore?.[0]?.entries?.[0]?.entries?.[0]?.entries?.[0]
                      ?.sectionImage || "First Lesson"
                  }
                  dominantColor={
                    newEntryStore?.[0]?.entries?.[0]?.dominantColor || "#967bb6"
                  }
                  textColor={textColor}
                  courseTitleUserIsOn={courseTitleUserIsOn}
                />
              </StartCardContainer>
            )}
          </AnimatePresence>
        </>
      )}
    </AnimatePresence>
  );
}
export default CourseSummaryPage;
