import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useAmazonContext } from "./Contexts/AmazonContext";
import Footer from "./components/Footer";
import Topic from "./components/Topic";
import Quiz from "./components/Quiz";
import Button from "./components/Button";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { BrowserRouter as Redirect } from "react-router-dom";
import "./App.css";
import MainSideNav from "./components/sidebar/MainSideNav";
import CourseMenuPage from "./components/landingPage/CourseMenuPage";
import CourseLandingPage from "./components/landingPage/CourseLandingPage";
import CourseStats from "./components/landingPage/CourseStats";
import LoginPage from "./components/userAuth/LoginPage";
import Logout from "./components/userAuth/Logout";
import LogoutMessage from "./components/userAuth/LogoutMessage";
import Lesson from "./components/Lesson";
import GetStarted from "./components/userAuth/GetStarted";
import WelcomeMessageOne from "./components/userAuth/WelcomeMessageOne";
import WelcomeMessageTwo from "./components/userAuth/WelcomeMessageTwo";
import WelcomeMessageThree from "./components/userAuth/WelcomeMessageThree";
import WelcomeMessageFour from "./components/userAuth/WelcomeMessageFour";
import LessonDemo from "./components/LessonDemo";
import WelcomeMessageFive from "./components/userAuth/WelcomeMessageFive";
import WelcomeMessageNotifications from "./components/userAuth/WelcomeMessageNotifications";
import WelcomeMessageCourses from "./components/userAuth/WelcomeMessageCourses";
import WelcomeMessageSeven from "./components/userAuth/WelcomeMessageSeven";
import { useHistory } from "react-router-dom";
import LessonBuilder from "./components/lessonBuilder/LessonBuilder";
import axios from "axios";
import CourseHomePage from "./components/landingPageNew/CourseHomePage";
import CourseSummaryPage from "./components/landingPageNew/CourseSummaryPage";
import Library from "./components/landingPageNew/Library";
import Profile from "./components/landingPageNew/Profile";
import Dashboard from "./components/landingPageNew/Dashboard";
import "./global.css";
import ReactNativeLoginHandling from "./components/userAuth/ReactNativeLoginHandling";
import MarketingHeader from "./components/marketingPage/MarketingHeader";
import Leaderboard from "./components/landingPageNew/Leaderboard";
import PrivacyPolicy from "./components/PrivacyPolicy";
import FeedbackForum from "./components/landingPageNew/FeedbackForum";
import UpdateModal from "./components/UpdateModal";
import api from "./components/api";
import UserLessonsDetail from "./components/landingPageNew/UserLessonsDetail";
import UserProfileDetail from "./components/landingPageNew/UserProfileDetail";
import DailyTopPerformer from "./components/landingPageNew/DailyTopPerformer";

// PrivateRoute prevents accessibilty of contents if the user is not logged in
const PrivateRoute = ({
  component: Component,
  isLoggedIn,
  isTokenExpired,
  renewSession,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn && !isTokenExpired ? (
          <Component {...props} />
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
};

// AdminRoute prevents access to components if the user is not an admin
const AdminRoute = ({
  component: Component,
  isLoggedIn,
  userRole,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn && userRole === "admin" ? (
          <Component {...props} />
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
};

function App() {
  // Initialize states
  const {
    courseTitleUserIsOn,
    entryStore,
    isDarkMode,
    setIsDarkMode,
    userRole,
    setUserRole,
    navOpen,
    setNavOpen,
    showUpdateModal,
    setShowUpdateModal,
    updateInfo,
    setUpdateInfo,
    showCompletionModal,
    setShowCompletionModal,
  } = useAmazonContext();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [tokenExpiration, setTokenExpiration] = useState(null);
  const history = useHistory();

  // Function to check if the token is expired
  const isTokenExpired = () => {
    return tokenExpiration && Date.now() > tokenExpiration;
  };

  console.log(userRole);

  useEffect(() => {
    // Check localStorage for authentication state and expiration time
    const storedIsLoggedIn = localStorage.getItem("isLoggedIn");
    const storedTokenExpiration = localStorage.getItem("tokenExpiration");

    if (storedIsLoggedIn && storedTokenExpiration) {
      // If authentication state and expiration time are found, check if token is still valid
      const expirationTime = parseInt(storedTokenExpiration);
      if (expirationTime > Date.now()) {
        setIsLoggedIn(true);
        setTokenExpiration(expirationTime);
      } else {
        // Token has expired, clear localStorage
        localStorage.removeItem("isLoggedIn");
        localStorage.removeItem("tokenExpiration");
      }
    }

    //fetch user role from local and set state:
    setUserRole(localStorage.getItem("userRole"));

    checkUpdateStatus();
  }, []);

  const checkUpdateStatus = async () => {
    try {
      // Don't show modal on landing page
      if (window.location.pathname === "/") {
        return;
      }

      const userEmail = localStorage.getItem("userEmail");
      console.log("Checking update status for:", userEmail);

      const response = await api.get("/checkUpdateStatus", {
        params: { userEmail },
      });

      console.log("Update status response:", response.data);

      if (response.data.showUpdate) {
        setUpdateInfo(response.data);
        setShowUpdateModal(true);
      }
    } catch (error) {
      console.error("Error checking update status:", error);
    }
  };

  const handleUpdate = async () => {
    try {
      const userEmail = localStorage.getItem("userEmail");
      await api.post("/trackUpdateClick", {
        userEmail,
      });

      // Close the update modal
      setShowUpdateModal(false);

      // Set a timeout to show completion modal after 3 seconds
      setTimeout(() => {
        setShowCompletionModal(true);
      }, 3000);

      // Open Play Store in new tab
      window.open(
        "https://play.google.com/store/apps/details?id=com.anonymous.hhcourses",
        "_blank"
      );
    } catch (error) {
      console.error("Error tracking update click:", error);
    }
  };

  const handleCloseUpdateModal = () => {
    setShowUpdateModal(false);
  };

  const handleCompleteClick = () => {
    setShowCompletionModal(false);
  };

  // Add a class to the body element based on the dark mode state
  document.body.className = isDarkMode ? "darkbody" : "lightbody";

  return (
    // <div>
    //   <Router>
    //     <Switch>
    //       <MarketingHeader />
    //     </Switch>
    //   </Router>
    // </div>
    <div className="App">
      {/* Router and routes */}
      <Router>
        <Switch>
          <Route path="/" exact component={MarketingHeader} />
          <Route path="/privacy-policy" exact component={PrivacyPolicy} />

          <AdminRoute
            path="/lesson-builder"
            exact
            component={LessonBuilder}
            isLoggedIn={isLoggedIn}
            userRole={userRole}
          />

          <Route
            path="/home"
            exact
            render={() => (isLoggedIn ? <CourseHomePage /> : <GetStarted />)}
          />

          <Route
            path="/LogoutMessage"
            exact
            render={() => <LogoutMessage setIsLoggedIn={setIsLoggedIn} />}
          />
          <Route
            path="/LoginPage"
            exact
            render={() =>
              isLoggedIn ? (
                <CourseHomePage />
              ) : (
                <LoginPage
                  setIsLoggedIn={setIsLoggedIn}
                  setTokenExpiration={setTokenExpiration}
                />
              )
            }
          />
          <Route
            path="/ReactNativeLoginHandling"
            exact
            render={() =>
              isLoggedIn ? (
                <CourseHomePage />
              ) : (
                <ReactNativeLoginHandling
                  setIsLoggedIn={setIsLoggedIn}
                  setTokenExpiration={setTokenExpiration}
                />
              )
            }
          />

          <Route
            path="/WelcomeMessageOne"
            exact
            component={WelcomeMessageOne}
          />
          <Route
            path="/WelcomeMessageTwo"
            exact
            component={WelcomeMessageTwo}
          />
          <Route
            path="/WelcomeMessageThree"
            exact
            component={WelcomeMessageThree}
          />
          <Route
            path="/WelcomeMessageFour"
            exact
            component={WelcomeMessageFour}
          />
          <Route
            path="/WelcomeMessageFive"
            exact
            component={WelcomeMessageFive}
          />
          <Route
            path="/WelcomeMessageNotifications"
            exact
            component={WelcomeMessageNotifications}
          />
          <Route
            path="/WelcomeMessageSeven"
            exact
            component={WelcomeMessageSeven}
          />
          <Route
            path="/WelcomeMessageCourses"
            exact
            component={WelcomeMessageCourses}
          />
          <Route path="/LoginPage" exact component={LoginPage} />
          <Route path="/LessonDemo" exact component={LessonDemo} />

          {/* Protected Routes */}
          <PrivateRoute
            path="/"
            exact
            component={CourseHomePage}
            isLoggedIn={isLoggedIn}
          />
          <PrivateRoute
            path="/library"
            exact
            component={Library}
            isLoggedIn={isLoggedIn}
          />
          <PrivateRoute
            path="/profile"
            exact
            component={Profile}
            isLoggedIn={isLoggedIn}
          />
          <PrivateRoute
            path="/dashboard"
            exact
            component={Dashboard}
            isLoggedIn={isLoggedIn}
          />
          <PrivateRoute
            path="/user-lessons-detail"
            exact
            component={UserLessonsDetail}
            isLoggedIn={isLoggedIn}
          />
          <PrivateRoute
            path="/user-profile-detail"
            exact
            component={UserProfileDetail}
            isLoggedIn={isLoggedIn}
          />
          <PrivateRoute
            path="/daily-top-performer"
            exact
            component={DailyTopPerformer}
            isLoggedIn={isLoggedIn}
          />
          <PrivateRoute
            path="/feedback-forum"
            exact
            component={FeedbackForum}
            isLoggedIn={isLoggedIn}
          />
          <PrivateRoute
            path="/:courseTitle/topic/:topicNumber"
            component={Topic}
            isLoggedIn={isLoggedIn}
          />
          <PrivateRoute
            path="/:courseTitle/quiz/:topicNumber"
            component={Quiz}
            isLoggedIn={isLoggedIn}
          />
          <PrivateRoute
            path="/leaderboard"
            exact
            component={Leaderboard}
            isLoggedIn={isLoggedIn}
          />
          <Route path="/:courseTitle" exact component={CourseSummaryPage} />
          <PrivateRoute
            path="/:courseTitle/start"
            exact
            component={CourseLandingPage}
            isLoggedIn={isLoggedIn}
          />
          <PrivateRoute
            path="/:courseTitle/lesson/:lessonNumber"
            component={Lesson}
            isLoggedIn={isLoggedIn}
          />
        </Switch>
      </Router>

      {showUpdateModal && updateInfo && !showCompletionModal && (
        <UpdateModal
          message={updateInfo.message}
          isMandatory={updateInfo.isMandatory}
          onUpdate={handleUpdate}
          onClose={handleCloseUpdateModal}
          isComplete={false}
        />
      )}

      {showCompletionModal && (
        <UpdateModal isComplete={true} onCompleteClick={handleCompleteClick} />
      )}
    </div>
  );
}

export default App;
