import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import api from "../api";
import { useAmazonContext } from "../../Contexts/AmazonContext";
import { Home, BookOpen, User } from "lucide-react";
import LibraryProgressBar from "./LibraryProgressBar";
import styled from "styled-components";
import SideNavRowQuiz from "../sidebar/SideNavRowQuiz";
import SideNavRowLesson from "../sidebar/SideNavRowLesson";
import { motion } from "framer-motion";
import { Header, NavButton } from "./landingPageSharedStyledComponents";
import CourseProgressBar from "../sidebar/CourseProgressBar";
import BottomNavBar from "./BottomNavBar"; // Import the new BottomNavBar component
import LottieAnimation from "../LottieAnimation";

const LibraryContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  max-height: calc(
    100vh - 150px
  ); // Adjust based on your header and footer heights
`;

const LottieContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: #fff; // Optional: add a background color

  > div {
    width: 100%;
    max-width: 300px; // Adjust this value as needed

    @media only screen and (min-width: 601px) {
      max-width: 50%;
    }
  }
`;

const BookWrapper = styled.div`
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  width: 100%;
  padding: 10px;
  margin-bottom: 30px;
  /* background-color: #d2cfe6; */
  border-radius: 0.5rem;
`;

const BookCover = styled.div`
  flex: 0 0 auto;
  width: 200px;
  margin: 10px auto; // This will center the BookCover horizontally

  scroll-snap-align: start;
  position: relative;
`;

const CoverImage = styled.img`
  width: 100%;

  height: ${200 * (2250 / 1410)}px; // Maintain aspect ratio
  object-fit: cover;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;

  &:hover {
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2);
  }
`;

function Library() {
  const [startedCourses, setStartedCourses] = useState([]);
  const [courseData, setCourseData] = useState({});
  const {
    progressData,
    chapterProgressData,
    setProgressData,
    setChapterProgressData,
  } = useAmazonContext();
  const [isLoading, setIsLoading] = useState(true);

  console.log(progressData);

  const userEmail = localStorage.getItem("userEmail");
  useEffect(() => {
    console.log(userEmail);
    if (!userEmail) {
      console.error("User email not found in local storage");
      return;
    }

    const fetchProgress = async () => {
      try {
        const response = await api.get("/fetchProgressRecord", {
          params: { userEmail: userEmail },
        });
        setProgressData(response.data);
      } catch (error) {
        console.error("Error fetching progress data:", error);
      }
    };

    const fetchChapterProgress = async () => {
      try {
        const response = await api.get("/fetchChapterProgressRecord", {
          params: { userEmail: userEmail },
        });
        setChapterProgressData(response.data);
      } catch (error) {
        console.error("Error fetching chapter progress data:", error);
      }
    };

    fetchProgress();
    fetchChapterProgress();
  }, [userEmail]);

  useEffect(() => {
    const fetchStartedCourses = async () => {
      if (!progressData || !chapterProgressData) {
        setIsLoading(false);
        return;
      }

      setIsLoading(true);
      try {
        const response = await api.get("/coursesData");
        const allCourses = response.data;

        const coursesWithProgress = allCourses.filter((course) => {
          const courseProgress = progressData.find(
            (progress) =>
              progress.courseProgress === course.courseTitle.replace(/\s/g, "")
          );
          return (
            courseProgress &&
            courseProgress.lessonProgress &&
            courseProgress.lessonProgress.length > 0
          );
        });

        setStartedCourses(coursesWithProgress);

        // Fetch detailed data for each started course
        const courseDataPromises = coursesWithProgress.map(async (course) => {
          const courseTitle = course.courseTitle.replace(/\s/g, "");
          const sideBarResponse = await api.get(
            `/${courseTitle}/sideBarData?collectionName=${course.courseTopicsCollectionName}&modelName=${course.courseTopicsModelName}`
          );
          return { courseTitle, data: sideBarResponse.data };
        });

        const allCourseData = await Promise.all(courseDataPromises);
        const courseDataObj = {};
        allCourseData.forEach(({ courseTitle, data }) => {
          courseDataObj[courseTitle] = simplifyData(data);
        });
        setCourseData(courseDataObj);
      } catch (error) {
        console.error("Error fetching started courses:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchStartedCourses();
  }, [progressData, chapterProgressData]);

  function simplifyData(data) {
    return (
      data &&
      data
        .map((topicHeader) => {
          return topicHeader.entries.map((chapter) => {
            return {
              chapterNumber: chapter.topicNumber,
              chapterTitle: chapter.title,
              lessons: chapter.entries[0].entries.map((lesson) => {
                return {
                  lessonNumber: lesson.sectionNumber,
                  lessonTitle: lesson.title,
                  lessonImage: lesson.sectionImage,
                };
              }),
            };
          });
        })
        .flat()
    );
  }

  function getCourseDetails(courseTitle, simplifiedData) {
    const courseProgressData = progressData.find(
      (progress) => progress.courseProgress === courseTitle
    );

    // If there's no course progress data or simplified data, return null
    if (!courseProgressData || !simplifiedData) {
      return null;
    }

    const lessonProgress = courseProgressData.lessonProgress;
    const lastCompletedLesson = lessonProgress[lessonProgress.length - 1];

    const chapterOfLastCompletedLesson = simplifiedData.find((chapter) =>
      chapter.lessons.some(
        (lesson) => lesson.lessonNumber === lastCompletedLesson
      )
    );

    if (!chapterOfLastCompletedLesson) return null;

    const { lessons } = chapterOfLastCompletedLesson;
    const lastCompletedLessonIndex = lessons.findIndex(
      (lesson) => lesson.lessonNumber === lastCompletedLesson
    );

    // Check if this chapter's quiz is completed (if chapter progress data exists)
    const courseChapterProgressData = chapterProgressData?.find(
      (progress) => progress.courseProgress === courseTitle
    );

    const chapterNumber = chapterOfLastCompletedLesson.chapterNumber;
    const isChapterQuizCompleted =
      courseChapterProgressData?.chapterProgress?.includes(chapterNumber) ||
      false;

    const isLastLessonOfChapter =
      lastCompletedLessonIndex === lessons.length - 1;
    const isFirstLessonOfChapter = lastCompletedLessonIndex === 0;

    let nextLessonDetails;
    if (isLastLessonOfChapter && !isChapterQuizCompleted) {
      // Show quiz for current chapter
      nextLessonDetails = {
        type: "quiz",
        chapterNumber: chapterOfLastCompletedLesson.chapterNumber,
        chapterTitle: chapterOfLastCompletedLesson.chapterTitle,
      };
    } else if (isChapterQuizCompleted) {
      // Show first lesson of next chapter
      const nextChapterIndex =
        simplifiedData.findIndex(
          (chapter) =>
            chapter.chapterNumber === chapterOfLastCompletedLesson.chapterNumber
        ) + 1;
      if (nextChapterIndex < simplifiedData.length) {
        const nextChapter = simplifiedData[nextChapterIndex];
        const firstLesson = nextChapter.lessons[0];
        nextLessonDetails = {
          type: "lesson",
          lessonNumber: firstLesson.lessonNumber,
          lessonTitle: firstLesson.lessonTitle,
          lessonImage: firstLesson.lessonImage,
        };
      }
    } else {
      // Show next lesson in current chapter
      const nextLesson = lessons[lastCompletedLessonIndex + 1];
      nextLessonDetails = {
        type: "lesson",
        lessonNumber: nextLesson.lessonNumber,
        lessonTitle: nextLesson.lessonTitle,
        lessonImage: nextLesson.lessonImage,
      };
    }

    return {
      isLastLessonOfChapter,
      isFirstLessonOfChapter,
      chapterOfLastCompletedLesson,
      nextLessonDetails,
      isLessonProgressEmpty: lessonProgress.length === 0,
    };
  }

  const convertToCloudFrontUrl = (s3Url) => {
    if (!s3Url) return s3Url;
    return s3Url.replace(
      "https://hhcourses-assets.s3.us-east-2.amazonaws.com",
      "https://d10jmd9bhq0s4v.cloudfront.net"
    );
  };

  if (isLoading) {
    return (
      <div>
        <LottieContainer>
          <LottieAnimation
            lottieFile={convertToCloudFrontUrl(
              "https://hhcourses-assets.s3.us-east-2.amazonaws.com/General/LottieFiles/loading.json"
            )}
            loop={true}
          ></LottieAnimation>
        </LottieContainer>
        <BottomNavBar></BottomNavBar>
      </div>
    );
  }

  if (!startedCourses.length) {
    return (
      <div>
        <Header className="sideNavChapterHeader">
          <p>My Library</p>
        </Header>
        <LibraryContainer>
          <p
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              fontSize: "1.5rem",
              textAlign: "center",
              padding: "20px",
            }}
          >
            You haven't started any courses yet!
          </p>
        </LibraryContainer>
        <BottomNavBar></BottomNavBar>
      </div>
    );
  }

  return (
    <div>
      <Header className="sideNavChapterHeader">
        <p>My Library</p>
      </Header>
      <LibraryContainer>
        {startedCourses.map((course) => {
          const courseTitle = course.courseTitle.replace(/\s/g, "");
          const simplifiedData = courseData[courseTitle];
          const courseDetails = getCourseDetails(courseTitle, simplifiedData);

          return (
            <BookWrapper key={course.id}>
              <BookCover>
                <CoverImage
                  src={convertToCloudFrontUrl(course.courseImage)}
                  alt={`${course.courseTitle} cover`}
                />
              </BookCover>

              <CourseProgressBar
                courseTitleUserIsOn={courseTitle}
                progressData={progressData}
                chapterProgressData={chapterProgressData}
                simplifiedChaptersLessonsData={simplifiedData}
              />

              {courseDetails?.nextLessonDetails && (
                <div className="ContinueSideNavContainer">
                  {courseDetails.nextLessonDetails.type === "quiz" ? (
                    <Link
                      to={`/${courseTitle}/quiz/${courseDetails.nextLessonDetails.chapterNumber}`}
                    >
                      <motion.div
                        whileTap={{ scale: 0.8 }}
                        transition={{
                          type: "spring",
                          stiffness: 400,
                          damping: 17,
                        }}
                      >
                        <motion.div
                          animate={{ y: [-5, 5, -5] }}
                          transition={{ duration: 2, repeat: Infinity }}
                        >
                          <SideNavRowQuiz
                            number={
                              courseDetails.nextLessonDetails.chapterNumber
                            }
                            title={courseDetails.nextLessonDetails.chapterTitle}
                            width="90%"
                            marginLeft="5%"
                          />
                        </motion.div>
                      </motion.div>
                    </Link>
                  ) : (
                    <Link
                      to={`/${courseTitle}/lesson/${courseDetails.nextLessonDetails.lessonNumber}`}
                    >
                      <motion.div
                        whileTap={{ scale: 0.8 }}
                        transition={{
                          type: "spring",
                          stiffness: 400,
                          damping: 17,
                        }}
                      >
                        <motion.div
                          animate={{ y: [-5, 5, -5] }}
                          transition={{ duration: 2, repeat: Infinity }}
                        >
                          <SideNavRowLesson
                            backgroundColor="white"
                            image={courseDetails.nextLessonDetails.lessonImage}
                            number={
                              courseDetails.nextLessonDetails.lessonNumber
                            }
                            title={courseDetails.nextLessonDetails.lessonTitle}
                            width="90%"
                            marginLeft="5%"
                            borderRadius="14% 2% 2% 14% / 50% 10% 10% 50%"
                          />
                        </motion.div>
                      </motion.div>
                    </Link>
                  )}
                </div>
              )}
            </BookWrapper>
          );
        })}
      </LibraryContainer>
      <BottomNavBar></BottomNavBar>
    </div>
  );
}

export default Library;
