import React from "react";
import styled from "styled-components";

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: #2d2d2d;
  padding: 2rem;
  border-radius: 16px;
  max-width: 90%;
  width: 400px;
  text-align: center;
`;

const Title = styled.h2`
  color: #f7c054;
  font-family: "Quicksand", sans-serif;
  margin-bottom: 1rem;
`;

const Message = styled.p`
  color: white;
  font-family: "Quicksand", sans-serif;
  margin-bottom: 2rem;
`;

const Button = styled.button`
  padding: 1rem 2rem;
  border-radius: 16px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  font-family: "Quicksand", sans-serif;
  background: #8747d4;
  color: white;
  border: none;
  width: 100%;
  margin-bottom: ${(props) => (props.marginBottom ? "1rem" : "0")};

  &:hover {
    background: #9859e0;
  }
`;

const UpdateModal = ({
  message,
  isMandatory,
  onUpdate,
  onClose,
  isComplete,
  onCompleteClick,
}) => {
  if (isComplete) {
    return (
      <ModalOverlay>
        <ModalContent>
          <Title>Thank You! 😊</Title>
          <Message>We hope you enjoy the latest features!</Message>
          <Button onClick={onCompleteClick}>Check Complete</Button>
        </ModalContent>
      </ModalOverlay>
    );
  }

  return (
    <ModalOverlay>
      <ModalContent>
        <Title>Update Available</Title>
        <Message>{message || "A new version of the app is available!"}</Message>
        <Button onClick={onUpdate} marginBottom={!isMandatory}>
          Check
        </Button>
        {!isMandatory && (
          <Button onClick={onClose} style={{ background: "#444" }}>
            Later
          </Button>
        )}
      </ModalContent>
    </ModalOverlay>
  );
};

export default UpdateModal;
