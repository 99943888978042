import React from "react";
import { motion } from "framer-motion";
import LottieAnimation from "../LottieAnimation";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import SolidButton from "../SolidButton";

const ResponsiveDiv1 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  /*desktop*/
  @media only screen and (min-width: 601px) {
    width: 300px; /* Change this to the desired width */
    margin-top: 40px;
  }
  /*mobile*/
  @media only screen and (max-width: 600px) {
    width: 280px;
    margin-top: 100px;
  }
`;

function WelcomeMessageFive() {
  const history = useHistory();

  const handleContinue = (e) => {
    e.preventDefault();
    setTimeout(() => {
      history.push("/WelcomeMessageCourses");
    }, 150);
  };

  const convertToCloudFrontUrl = (s3Url) => {
    if (!s3Url) return s3Url;
    return s3Url.replace(
      "https://hhcourses-assets.s3.us-east-2.amazonaws.com",
      "https://d10jmd9bhq0s4v.cloudfront.net"
    );
  };

  return (
    <div className="WelcomeMessageContainer">
      <div className="WelcomeMessageImagesContainer">
        <ResponsiveDiv1>
          <LottieAnimation
            lottieFile={convertToCloudFrontUrl(
              "https://hhcourses-assets.s3.us-east-2.amazonaws.com/General/LottieFiles/scheduleClean.json"
            )}
          />
        </ResponsiveDiv1>
      </div>
      <div className="WelcomeMessageFooter">
        <div className="MessageTopSection">
          <div className="GetStartedHeader" style={{ fontSize: "4vh" }}>
            Just 5 minutes a day
          </div>
          <div className="WelcomeMessageText" style={{ paddingTop: 0 }}>
            We understand you're busy. <br />
            So we designed the lessons to fit your schedule.
          </div>
        </div>
        <div className="MessageMiddleSection">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <SolidButton className="primary" onClick={handleContinue}>
              CONTINUE
            </SolidButton>
          </div>
        </div>
        <div className="MessageBottomSection"></div>
      </div>
    </div>
  );
}

export default WelcomeMessageFive;
