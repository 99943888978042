import React from "react";
import styled from "styled-components";
import { NavLink, useLocation } from "react-router-dom";
import { Home, BookOpen, User, Trophy } from "lucide-react";

const StyledBottomNavBar = styled.nav`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #ffffff;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-around;
  padding: 10px 0;
  z-index: 1000;
`;

const NavItem = styled(NavLink)`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #666;
  text-decoration: none;
  font-size: 12px;
  transition: color 0.3s ease;

  &.active {
    color: #8747d4;
  }

  svg {
    margin-bottom: 4px;
    width: 24px;
    height: 24px;
  }
`;

const BottomNavBar = () => {
  const location = useLocation();

  return (
    <StyledBottomNavBar>
      <NavItem to="/home" isActive={() => location.pathname === "/home"}>
        <Home strokeWidth={location.pathname === "/home" ? 2.5 : 1.5} />
        Home
      </NavItem>
      <NavItem to="/library" isActive={() => location.pathname === "/library"}>
        <BookOpen strokeWidth={location.pathname === "/library" ? 2.5 : 1.5} />
        Library
      </NavItem>
      <NavItem
        to="/leaderboard"
        isActive={() => location.pathname === "/leaderboard"}
      >
        <Trophy
          strokeWidth={location.pathname === "/leaderboard" ? 2.5 : 1.5}
        />
        Leaderboard
      </NavItem>
      <NavItem to="/profile" isActive={() => location.pathname === "/profile"}>
        <User strokeWidth={location.pathname === "/profile" ? 2.5 : 1.5} />
        Profile
      </NavItem>
    </StyledBottomNavBar>
  );
};

export default BottomNavBar;
