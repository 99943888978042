import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import api from "../api";
import { Header } from "./landingPageSharedStyledComponents";
import BottomNavBar from "./BottomNavBar";

const Container = styled.div`
  padding: 10px;
  max-width: 100%;
  width: 100%;
`;

const DetailCard = styled.div`
  background-color: #6535a3;
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 20px;
  width: 100%;
`;

const CardTitle = styled.h2`
  color: white;
  margin: 0;
  font-size: 1.2rem;
  text-align: center;
`;

const TableContainer = styled.div`
  width: 100%;
  overflow-x: auto;
  margin-top: 20px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  color: white;
`;

const TableHeader = styled.th`
  padding: 10px;
  text-align: ${(props) => props.align || "left"};
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  cursor: pointer;
  user-select: none;
  position: relative;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }

  &:after {
    content: "${(props) =>
      props.sortDirection === "asc"
        ? " ↑"
        : props.sortDirection === "desc"
        ? " ↓"
        : ""}";
    position: absolute;
    right: 8px;
  }
`;

const TableRow = styled.tr`
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
`;

const TableCell = styled.td`
  padding: 10px;
  text-align: ${(props) => props.align || "left"};
`;

const MetricsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 15px;
  margin: 20px 0;
`;

const MetricBox = styled.div`
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  padding: 15px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: rgba(255, 255, 255, 0.15);
  }
`;

const MetricTitle = styled.div`
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.9rem;
  margin-bottom: 8px;
`;

const MetricValue = styled.div`
  color: white;
  font-size: 1.2rem;
  font-weight: bold;
  display: flex;
  justify-content: center;
  gap: 10px;
`;

const MetricDetail = styled.span`
  font-size: 0.9rem;
  color: ${(props) => props.color || "rgba(255, 255, 255, 0.7)"};
`;

const TotalUsersBox = styled(MetricBox)`
  grid-column: 1 / -1; // Spans full width
  background-color: rgba(255, 255, 255, 0.15); // Slightly more prominent
  margin-bottom: 10px;
`;

const FilterBadge = styled.div`
  background-color: #4caf50;
  color: white;
  padding: 5px 10px;
  border-radius: 15px;
  margin: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

const ClearButton = styled.button`
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  padding: 0;
  font-size: 16px;
  opacity: 0.8;

  &:hover {
    opacity: 1;
  }
`;

function UserProfileDetail() {
  const [users, setUsers] = useState([]);
  const [metrics, setMetrics] = useState({
    total: 0,
    appVersion: { has: 0, missing: 0 },
    fcmToken: { has: 0, missing: 0 },
    notifications: { enabled: 0, disabled: 0 },
  });
  const [activeFilter, setActiveFilter] = useState(null);
  const [sortConfig, setSortConfig] = useState({
    key: "lastLogin",
    direction: "desc",
  });

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await api.get("/users");
        setUsers(response.data);

        // Calculate metrics
        const metrics = response.data.reduce(
          (acc, user) => {
            // Total users
            acc.total = response.data.length;

            // App Version metrics
            if (user.appVersion) {
              acc.appVersion.has++;
            } else {
              acc.appVersion.missing++;
            }

            // FCM Token metrics
            if (user.hasFcmToken) {
              // Updated to use the new boolean field
              acc.fcmToken.has++;
            } else {
              acc.fcmToken.missing++;
            }

            // Notifications metrics
            if (user.notificationsEnabled) {
              acc.notifications.enabled++;
            } else {
              acc.notifications.disabled++;
            }

            return acc;
          },
          {
            total: 0,
            appVersion: { has: 0, missing: 0 },
            fcmToken: { has: 0, missing: 0 },
            notifications: { enabled: 0, disabled: 0 },
          }
        );

        setMetrics(metrics);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUsers();
  }, []);

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const getSortDirection = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction;
    }
    return null;
  };

  const sortedUsers = [...users].sort((a, b) => {
    if (!a[sortConfig.key]) return 1;
    if (!b[sortConfig.key]) return -1;

    if (
      sortConfig.key === "lastLogin" ||
      sortConfig.key === "lastActivityDate"
    ) {
      return sortConfig.direction === "asc"
        ? new Date(a[sortConfig.key]) - new Date(b[sortConfig.key])
        : new Date(b[sortConfig.key]) - new Date(a[sortConfig.key]);
    }

    return sortConfig.direction === "asc"
      ? a[sortConfig.key].localeCompare(b[sortConfig.key])
      : b[sortConfig.key].localeCompare(a[sortConfig.key]);
  });

  const handleMetricClick = (metricType, value) => {
    if (activeFilter?.type === metricType && activeFilter?.value === value) {
      setActiveFilter(null);
    } else {
      setActiveFilter({ type: metricType, value });
    }
  };

  const getFilteredUsers = () => {
    if (!activeFilter) return sortedUsers;

    return sortedUsers.filter((user) => {
      switch (activeFilter.type) {
        case "appVersion":
          return activeFilter.value === "has"
            ? !!user.appVersion
            : !user.appVersion;
        case "fcmToken":
          return activeFilter.value === "has"
            ? user.hasFcmToken
            : !user.hasFcmToken;
        case "notifications":
          return activeFilter.value === "enabled"
            ? user.notificationsEnabled
            : !user.notificationsEnabled;
        default:
          return true;
      }
    });
  };

  const getFilterLabel = () => {
    if (!activeFilter) return "";

    const labels = {
      appVersion: {
        has: "Users with App Version",
        missing: "Users without App Version",
      },
      fcmToken: {
        has: "Users with FCM Token",
        missing: "Users without FCM Token",
      },
      notifications: {
        enabled: "Users with Notifications Enabled",
        disabled: "Users with Notifications Disabled",
      },
    };

    return labels[activeFilter.type][activeFilter.value];
  };

  return (
    <>
      <Header className="sideNavChapterHeader">
        <p>User Profiles</p>
      </Header>
      <Container>
        <DetailCard>
          <CardTitle>User Activity Details</CardTitle>

          <MetricsContainer>
            <TotalUsersBox>
              <MetricTitle>Total Active Users</MetricTitle>
              <MetricValue>
                <MetricDetail color="#fff">{metrics.total}</MetricDetail>
              </MetricValue>
            </TotalUsersBox>

            <MetricBox onClick={() => handleMetricClick("appVersion", "has")}>
              <MetricTitle>App Version Status</MetricTitle>
              <MetricValue>
                <MetricDetail color="#4CAF50">
                  {metrics.appVersion.has}
                </MetricDetail>
                <MetricDetail>/</MetricDetail>
                <MetricDetail
                  color="#f44336"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleMetricClick("appVersion", "missing");
                  }}
                >
                  {metrics.appVersion.missing}
                </MetricDetail>
              </MetricValue>
              <MetricDetail>Have Version / Missing</MetricDetail>
            </MetricBox>

            <MetricBox onClick={() => handleMetricClick("fcmToken", "has")}>
              <MetricTitle>FCM Token Status</MetricTitle>
              <MetricValue>
                <MetricDetail color="#4CAF50">
                  {metrics.fcmToken.has}
                </MetricDetail>
                <MetricDetail>/</MetricDetail>
                <MetricDetail
                  color="#f44336"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleMetricClick("fcmToken", "missing");
                  }}
                >
                  {metrics.fcmToken.missing}
                </MetricDetail>
              </MetricValue>
              <MetricDetail>Have Token / Missing</MetricDetail>
            </MetricBox>

            <MetricBox
              onClick={() => handleMetricClick("notifications", "enabled")}
            >
              <MetricTitle>Notifications Status</MetricTitle>
              <MetricValue>
                <MetricDetail color="#4CAF50">
                  {metrics.notifications.enabled}
                </MetricDetail>
                <MetricDetail>/</MetricDetail>
                <MetricDetail
                  color="#f44336"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleMetricClick("notifications", "disabled");
                  }}
                >
                  {metrics.notifications.disabled}
                </MetricDetail>
              </MetricValue>
              <MetricDetail>Enabled / Disabled</MetricDetail>
            </MetricBox>
          </MetricsContainer>

          {activeFilter && (
            <FilterBadge>
              {getFilterLabel()} ({getFilteredUsers().length} users)
              <ClearButton onClick={() => setActiveFilter(null)}>✕</ClearButton>
            </FilterBadge>
          )}

          <TableContainer>
            <Table>
              <thead>
                <tr>
                  <TableHeader
                    onClick={() => handleSort("givenName")}
                    sortDirection={getSortDirection("givenName")}
                  >
                    Name
                  </TableHeader>
                  <TableHeader
                    onClick={() => handleSort("userEmail")}
                    sortDirection={getSortDirection("userEmail")}
                  >
                    Email
                  </TableHeader>
                  <TableHeader
                    onClick={() => handleSort("lastLogin")}
                    sortDirection={getSortDirection("lastLogin")}
                  >
                    Last Login
                  </TableHeader>
                  <TableHeader
                    onClick={() => handleSort("lastActivityDate")}
                    sortDirection={getSortDirection("lastActivityDate")}
                  >
                    Last Activity
                  </TableHeader>
                  <TableHeader
                    onClick={() => handleSort("appVersion")}
                    sortDirection={getSortDirection("appVersion")}
                  >
                    App Version
                  </TableHeader>
                  <TableHeader
                    onClick={() => handleSort("notificationsEnabled")}
                    sortDirection={getSortDirection("notificationsEnabled")}
                  >
                    Notifications
                  </TableHeader>
                </tr>
              </thead>
              <tbody>
                {getFilteredUsers().map((user) => (
                  <TableRow key={user.userEmail}>
                    <TableCell>{`${user.givenName} ${
                      user.familyName || ""
                    }`}</TableCell>
                    <TableCell>{user.userEmail}</TableCell>
                    <TableCell>
                      {user.lastLogin
                        ? new Date(user.lastLogin).toLocaleDateString()
                        : "Never"}
                    </TableCell>
                    <TableCell>
                      {user.lastActivityDate
                        ? new Date(user.lastActivityDate).toLocaleDateString()
                        : "Never"}
                    </TableCell>
                    <TableCell>{user.appVersion || "Not Set"}</TableCell>
                    <TableCell>
                      {user.notificationsEnabled ? "Enabled" : "Disabled"}
                    </TableCell>
                  </TableRow>
                ))}
              </tbody>
            </Table>
          </TableContainer>
        </DetailCard>
        <div style={{ height: "60px" }} />
      </Container>
      <BottomNavBar />
    </>
  );
}

export default UserProfileDetail;
