import React, { useState, useEffect, useRef } from "react";
import styled, { createGlobalStyle } from "styled-components";
import { Link, useHistory } from "react-router-dom";
import HHLogo from "../sidebar/logo.svg";
import GooglePlayIcon from "../sidebar/googlePlayIcon.png";

const GlobalStyle = createGlobalStyle`
  html, body {
    background-color: #222222;
    margin: 0;
    padding: 0;
  }
`;

const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  max-width: 1200px;
  margin: 0 auto;
  background-color: #222222;

  @media (max-width: 768px) {
    padding: 1rem;
  }
`;

const LogoContainer = styled.div`
  height: 60px;
  cursor: pointer;
  margin-left: 20px;
  display: flex;
  align-items: center;

  img {
    height: 100%;
    width: auto;
  }

  @media (max-width: 768px) {
    height: 45px;
    margin-left: 10px;
  }
`;

const HeroSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
  gap: 4rem;
  min-height: calc(100vh - 100px);
  background-color: #222222;

  @media (max-width: 1024px) {
    gap: 2rem;
    padding: 0 1.5rem;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 2rem;
    min-height: auto;
    padding: 2rem 1rem;
  }
`;

const HeroContent = styled.div`
  max-width: 500px;
  text-align: center;
  margin-top: -5%;

  @media (max-width: 1024px) {
    margin-top: -3%;
    max-width: 450px;
  }

  @media (max-width: 768px) {
    margin-top: 0;
    max-width: 100%;
  }
`;

const HeroTitle = styled.h1`
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: #ffffff;
  font-family: "Quicksand", sans-serif;
  line-height: 1.3;

  @media (max-width: 1024px) {
    font-size: 2.25rem;
  }

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const SubHeader = styled.h2`
  font-size: 1.5rem;
  color: #f7c054;
  font-family: "Quicksand", sans-serif;
  margin-bottom: 2rem;
  font-weight: 500;
  font-weight: bold;

  @media (max-width: 1024px) {
    font-size: 1.35rem;
  }

  @media (max-width: 768px) {
    font-size: 1.25rem;
  }
`;

const SubHeader2 = styled.h2`
  font-size: 1.5rem;
  color: rgb(255, 255, 255);
  font-family: "Quicksand", sans-serif;
  margin-bottom: 2rem;
  font-weight: 500;

  @media (max-width: 1024px) {
    font-size: 1.35rem;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  margin-top: 2rem;
  width: 100%;
  max-width: 280px;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 768px) {
    width: 100%;
    max-width: 280px;
  }
`;

const Button = styled.button`
  padding: 1rem 2rem;
  border-radius: 16px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  font-family: "Quicksand", sans-serif;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  transition: transform 0.1s ease;

  &.primary {
    background: #8747d4;
    color: white;
    border: none;
    box-shadow: 0 4px 0 #6535a3;
    position: relative;
    top: 0;

    &:hover {
      background: #9859e0;
    }

    &:active {
      top: 4px;
      box-shadow: 0 0 0 #6535a3;
    }
  }

  &.google-play {
    background: white;
    color: #333;
    border: 2px solid #e5e5e5;
    padding: 0.75rem 2rem;

    img {
      height: 32px;
      width: auto;
    }

    &:hover {
      background: #f7f7f7;
    }
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const GooglePlayText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  span:first-child {
    font-size: 0.75rem;
    font-weight: normal;
  }

  span:last-child {
    font-size: 1.1rem;
    font-weight: bold;
  }
`;

const VideoContainer = styled.div`
  flex: 1;
  max-width: 390px;
  aspect-ratio: 9/16;
  border-radius: 24px;
  overflow: hidden;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
  margin-top: -5%;

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
  }

  @media (max-width: 1024px) {
    max-width: 350px;
    margin-top: -3%;
  }

  @media (max-width: 768px) {
    width: 100%;
    max-width: 300px;
    margin-top: 0;
  }

  @media (max-height: 800px) {
    max-width: 320px;
  }
`;

const ScrollBanner = styled.div`
  background-color: #222222;
  padding: 2rem;
  text-align: center;
  opacity: ${(props) => Math.min(props.scrollProgress, 1)};
  transform: scale(${(props) => 0.5 + props.scrollProgress * 0.5});
  transition: all 0.3s ease;
`;

const BannerText = styled.h2`
  font-size: 2rem;
  color: #ffffff;
  font-family: "Quicksand", sans-serif;
  font-weight: bold;
  margin: 0;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

const ContentWrapper = styled.div`
  background-color: #222222;
  min-height: 150vh; // Makes content taller than viewport to enable scrolling
`;

const FeatureSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 6rem auto;
  padding: 0 2rem;
  gap: 4rem;
  flex-direction: ${(props) => (props.reverse ? "row-reverse" : "row")};

  @media (max-width: 1024px) {
    gap: 2rem;
    padding: 0 1.5rem;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 2rem;
    padding: 2rem 1rem;
    margin: 3rem auto;
  }
`;

const CenteredFeatureSection = styled(FeatureSection)`
  flex-direction: column;
  text-align: center;

  ${VideoContainer} {
    margin: 2rem auto;
  }
`;

const FadeIn = styled.div`
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;

  &.visible {
    opacity: 1;
    transform: translateY(0);
  }
`;

const VideoFadeIn = styled(FadeIn)`
  transition-delay: 0.3s; // Delay video animation to start after text
`;

const FeatureContent = styled.div`
  max-width: 500px;
  text-align: left;

  ${(props) =>
    props.center &&
    `
    text-align: center;
  `}
`;

const FeatureTitle = styled.h2`
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: #f7c054;
  font-family: "Quicksand", sans-serif;
  line-height: 1.3;
  text-align: left;

  ${(props) =>
    props.center &&
    `
    text-align: center;
  `}

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const FeatureSubHeader = styled.h3`
  font-size: 1.5rem;
  color: rgb(255, 255, 255);
  font-family: "Quicksand", sans-serif;
  margin-bottom: 2rem;
  font-weight: 500;
  text-align: left;

  ${(props) =>
    props.center &&
    `
    text-align: center;
  `}

  @media (max-width: 768px) {
    font-size: 1.25rem;
  }
`;

const TestimonialsContainer = styled.div`
  max-width: 1200px;
  margin: 4rem auto;
  padding: 0 2rem;
  width: 100%;

  @media (max-width: 768px) {
    padding: 0 1rem;
  }
`;

const TestimonialsHeader = styled.div`
  text-align: center;
  margin-bottom: 3rem;
`;

const TestimonialsGrid = styled.ul`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  width: 100%;
  margin: 0 auto;
  padding: 0;
  list-style: none;

  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    max-width: 500px; // Limit width on mobile for better readability
  }
`;

const TestimonialCard = styled.li`
  background: #2d2d2d;
  border-radius: 16px;
  padding: 1.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  min-height: 300px; // Set a minimum height for consistency
`;

const QuoteIcon = styled.div`
  color: #8747d4;
  margin-bottom: 1rem;
`;

const TestimonialQuote = styled.p`
  color: #ffffff;
  font-size: 1.1rem;
  font-family: "Quicksand", sans-serif;
  flex-grow: 1; // Allow quote to take up available space
`;

const TestimonialFooter = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  padding-top: 1rem;
  border-top: 1px solid #3d3d3d;
  margin-top: auto; // Push footer to bottom
`;

const Avatar = styled.div`
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  border: 2px solid #8747d4;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const TestimonialAuthor = styled.div`
  font-family: "Quicksand", sans-serif;
`;

const AuthorName = styled.span`
  display: block;
  color: #ffffff;
  font-weight: bold;
`;

const AuthorTitle = styled.span`
  display: block;
  color: #f7c054;
  font-size: 0.9rem;
  margin-top: 0.25rem;
`;

const PrivacyPolicyLink = styled.a`
  display: block;
  text-align: center;
  margin: 2rem 0;
  color: #f7c054;
  font-family: "Quicksand", sans-serif;
  font-size: 1rem;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const MarketingHeader = () => {
  const [scrollProgress, setScrollProgress] = useState(0);
  const videoRefs = useRef([]);
  const contentRefs = useRef([]);
  const videoContainerRefs = useRef([]);
  const history = useHistory();

  const handleGetStarted = (e) => {
    e.preventDefault();
    setTimeout(() => {
      history.push("/WelcomeMessageOne");
    }, 150);
  };

  const handlePlayStoreClick = () => {
    window.open(
      "https://play.google.com/store/apps/details?id=com.anonymous.hhcourses",
      "_blank"
    );
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const startScroll = 100;
      const endScroll = 300;

      const progress = Math.max(
        0,
        Math.min(1, (scrollPosition - startScroll) / (endScroll - startScroll))
      );

      setScrollProgress(progress);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    const observerOptions = {
      threshold: 0.3,
      rootMargin: "0px 0px -100px 0px",
    };

    // Observer for text content
    const contentObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("visible");
        }
      });
    }, observerOptions);

    // Observer for videos
    const videoObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("visible");
          const video = entry.target.querySelector("video");
          if (video) video.play();
        } else {
          const video = entry.target.querySelector("video");
          if (video) video.pause();
        }
      });
    }, observerOptions);

    // Observe all content and video containers
    contentRefs.current.forEach((ref) => {
      if (ref) contentObserver.observe(ref);
    });

    videoContainerRefs.current.forEach((ref) => {
      if (ref) videoObserver.observe(ref);
    });

    return () => {
      contentObserver.disconnect();
      videoObserver.disconnect();
    };
  }, []);

  const testimonials = [
    {
      avatar: "",
      name: "Ayman N",
      title: "Home Maker",
      quote:
        " MashaAllah TabarakAllah, it's amazing! The best part is that you have to click to read further. As someone whose attention is always divided with kids, I used to skim through it rather than focus and read the long text.",
    },
    {
      avatar: "",
      name: "Saif Ahmed Syed",
      title: "Engineering Manager",
      quote:
        "It looks great and the content is nicely simplified and made easily understandable.",
    },
    {
      avatar: "",
      name: "A Muslimah",
      title: "",
      quote:
        "I felt I can do the courses longer than I would read the same content in a book.",
    },
  ];

  return (
    <>
      <GlobalStyle />
      <ContentWrapper>
        <HeaderContainer>
          <Link to="/">
            <LogoContainer>
              <img src={HHLogo} alt="HH Courses Logo" />
            </LogoContainer>
          </Link>
        </HeaderContainer>

        <HeroSection>
          <HeroContent>
            <HeroTitle>Master Knowledge, Anytime, Anywhere.</HeroTitle>
            <SubHeader>Easy. Engaging. Everlasting.</SubHeader>
            <ButtonGroup>
              <Button className="google-play" onClick={handlePlayStoreClick}>
                <img src={GooglePlayIcon} alt="Google Play" />
                <GooglePlayText>
                  <span>GET IT ON</span>
                  <span>Google Play</span>
                </GooglePlayText>
              </Button>
            </ButtonGroup>
          </HeroContent>

          <VideoContainer>
            <video
              autoPlay
              loop
              muted
              playsInline
              src="https://d10jmd9bhq0s4v.cloudfront.net/General/Video/HH+vertical.mp4"
            />
          </VideoContainer>
        </HeroSection>

        <ScrollBanner scrollProgress={scrollProgress}>
          <BannerText>
            Introducing <br />{" "}
            <span style={{ color: "#f7c054", fontSize: "3rem" }}>
              HH Courses
            </span>
          </BannerText>
        </ScrollBanner>

        {/* Easy Section */}
        <FeatureSection>
          <FadeIn ref={(el) => (contentRefs.current[0] = el)}>
            <FeatureContent>
              <FeatureTitle>Easy</FeatureTitle>
              <FeatureSubHeader>
                Master concepts with easy, clear, bite-sized lessons
              </FeatureSubHeader>
            </FeatureContent>
          </FadeIn>
          <VideoFadeIn ref={(el) => (videoContainerRefs.current[0] = el)}>
            <VideoContainer>
              <video
                ref={(el) => (videoRefs.current[0] = el)}
                loop
                muted
                playsInline
                src="https://d10jmd9bhq0s4v.cloudfront.net/General/Video/Easy.mp4"
              />
            </VideoContainer>
          </VideoFadeIn>
        </FeatureSection>

        {/* Engaging Section */}
        <FeatureSection reverse>
          <FadeIn ref={(el) => (contentRefs.current[1] = el)}>
            <FeatureContent>
              <FeatureTitle>Engaging</FeatureTitle>
              <FeatureSubHeader>
                Dive into visual and interactive lessons designed to keep you
                focused
              </FeatureSubHeader>
            </FeatureContent>
          </FadeIn>
          <VideoFadeIn ref={(el) => (videoContainerRefs.current[1] = el)}>
            <VideoContainer>
              <video
                ref={(el) => (videoRefs.current[1] = el)}
                loop
                muted
                playsInline
                src="https://d10jmd9bhq0s4v.cloudfront.net/General/Video/Engaging.mp4"
              />
            </VideoContainer>
          </VideoFadeIn>
        </FeatureSection>

        {/* Everlasting Section */}
        <FeatureSection>
          <FadeIn ref={(el) => (contentRefs.current[2] = el)}>
            <FeatureContent>
              <FeatureTitle>Everlasting</FeatureTitle>
              <FeatureSubHeader>
                Test your knowledge with quizzes, ensuring you retain what you
                learn forever
              </FeatureSubHeader>
            </FeatureContent>
          </FadeIn>
          <VideoFadeIn ref={(el) => (videoContainerRefs.current[2] = el)}>
            <VideoContainer>
              <video
                ref={(el) => (videoRefs.current[2] = el)}
                loop
                muted
                playsInline
                src="https://d10jmd9bhq0s4v.cloudfront.net/General/Video/Everlasting.mp4"
              />
            </VideoContainer>
          </VideoFadeIn>
        </FeatureSection>

        {/* Authentic Section */}
        <FeatureSection reverse>
          <FadeIn ref={(el) => (contentRefs.current[3] = el)}>
            <FeatureContent>
              <FeatureTitle>Authentic</FeatureTitle>
              <FeatureSubHeader>
                Sourced from the works of Scholars and Students of Knowledge
              </FeatureSubHeader>
            </FeatureContent>
          </FadeIn>
          <VideoFadeIn ref={(el) => (videoContainerRefs.current[3] = el)}>
            <VideoContainer>
              <video
                ref={(el) => (videoRefs.current[3] = el)}
                loop
                muted
                playsInline
                src="https://d10jmd9bhq0s4v.cloudfront.net/General/Video/Authentic.mp4"
              />
            </VideoContainer>
          </VideoFadeIn>
        </FeatureSection>
        <TestimonialsContainer>
          <FadeIn ref={(el) => (contentRefs.current[5] = el)}>
            <TestimonialsHeader>
              <FeatureTitle center>What Our Users Say</FeatureTitle>
              <FeatureSubHeader center>
                Join thousands of Muslims learning their deen the easy way
              </FeatureSubHeader>
            </TestimonialsHeader>
            <TestimonialsGrid>
              {testimonials.map((item, idx) => (
                <TestimonialCard key={idx}>
                  <QuoteIcon>
                    <svg
                      width="45"
                      height="45"
                      viewBox="0 0 35 35"
                      fill="currentColor"
                    >
                      <path d="M9.47895 14.5833C9.15374 14.5833 8.84166 14.6329 8.53103 14.6781C8.63166 14.3398 8.7352 13.9956 8.90145 13.6865C9.0677 13.2373 9.32728 12.8479 9.58541 12.4556C9.80124 12.0312 10.1819 11.7439 10.4619 11.3808C10.755 11.0279 11.1546 10.7931 11.471 10.5C11.7817 10.1937 12.1885 10.0406 12.5123 9.82478C12.8506 9.63082 13.1452 9.41645 13.4602 9.31437L14.2462 8.99062L14.9375 8.70332L14.2302 5.87708L13.3596 6.08707C13.081 6.15707 12.7412 6.23874 12.3548 6.33645C11.9596 6.40937 11.5381 6.60916 11.0685 6.79145C10.6048 6.99853 10.0681 7.13853 9.56937 7.47103C9.0677 7.78895 8.48874 8.05437 7.97833 8.4802C7.48395 8.91916 6.88749 9.29978 6.44708 9.85833C5.96583 10.3804 5.49041 10.9287 5.12145 11.5529C4.69416 12.1479 4.40395 12.8012 4.0977 13.4473C3.82062 14.0933 3.59749 14.754 3.4152 15.3956C3.06958 16.6819 2.91499 17.904 2.8552 18.9496C2.80562 19.9967 2.83478 20.8673 2.89603 21.4973C2.91791 21.7948 2.95874 22.0835 2.98791 22.2833L3.02437 22.5283L3.06228 22.5196C3.32167 23.7312 3.91877 24.8446 4.78452 25.7311C5.65028 26.6175 6.7493 27.2408 7.95447 27.5287C9.15963 27.8166 10.4217 27.7575 11.5946 27.3581C12.7676 26.9587 13.8035 26.2354 14.5825 25.2719C15.3616 24.3083 15.8519 23.1439 15.9969 21.9133C16.1418 20.6828 15.9353 19.4363 15.4014 18.3181C14.8675 17.2 14.028 16.2558 12.9799 15.5949C11.9318 14.934 10.718 14.5832 9.47895 14.5833Z" />
                    </svg>
                  </QuoteIcon>
                  <TestimonialQuote>{item.quote}</TestimonialQuote>
                  <TestimonialFooter>
                    {item.avatar && (
                      <Avatar>
                        <img src={item.avatar} alt={item.name} />
                      </Avatar>
                    )}
                    <TestimonialAuthor>
                      <AuthorName>{item.name}</AuthorName>
                      <AuthorTitle>{item.title}</AuthorTitle>
                    </TestimonialAuthor>
                  </TestimonialFooter>
                </TestimonialCard>
              ))}
            </TestimonialsGrid>
          </FadeIn>
        </TestimonialsContainer>
        {/* Download Section */}
        <CenteredFeatureSection>
          <FadeIn ref={(el) => (contentRefs.current[4] = el)}>
            <FeatureContent center>
              <FeatureTitle center>Download now!</FeatureTitle>
              <FeatureSubHeader center>
                And begin your learning adventure here for the hereafter.
              </FeatureSubHeader>
            </FeatureContent>
          </FadeIn>
          <VideoFadeIn ref={(el) => (videoContainerRefs.current[4] = el)}>
            <VideoContainer>
              <video
                ref={(el) => (videoRefs.current[4] = el)}
                muted
                playsInline
                src="https://d10jmd9bhq0s4v.cloudfront.net/General/Video/Begin.mp4"
              />
            </VideoContainer>
          </VideoFadeIn>

          <FadeIn ref={(el) => (contentRefs.current[6] = el)}>
            <ButtonGroup>
              <Button className="google-play" onClick={handlePlayStoreClick}>
                <img src={GooglePlayIcon} alt="Google Play" />
                <GooglePlayText>
                  <span>GET IT ON</span>
                  <span>Google Play</span>
                </GooglePlayText>
              </Button>
            </ButtonGroup>
          </FadeIn>
        </CenteredFeatureSection>
        <PrivacyPolicyLink href="/privacy-policy" style={{ color: "#f7c054" }}>
          Privacy Policy
        </PrivacyPolicyLink>
      </ContentWrapper>
    </>
  );
};

export default MarketingHeader;
