import React from "react";
import { motion } from "framer-motion";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import SolidButton from "../SolidButton";

const convertToCloudFrontUrl = (s3Url) => {
  if (!s3Url) return s3Url;
  return s3Url.replace(
    "https://hhcourses-assets.s3.us-east-2.amazonaws.com",
    "https://d10jmd9bhq0s4v.cloudfront.net"
  );
};

const images = [
  [
    convertToCloudFrontUrl(
      "https://hhcourses-assets.s3.us-east-2.amazonaws.com/KalimaTawheed/sectionImages/1.1.1.png"
    ),
    convertToCloudFrontUrl(
      "https://hhcourses-assets.s3.us-east-2.amazonaws.com/KalimaTawheed/sectionImages/1.1.2.png"
    ),
    convertToCloudFrontUrl(
      "https://hhcourses-assets.s3.us-east-2.amazonaws.com/KalimaTawheed/sectionImages/1.1.3.png"
    ),
    convertToCloudFrontUrl(
      "https://hhcourses-assets.s3.us-east-2.amazonaws.com/KalimaTawheed/sectionImages/1.1.4.png"
    ),
    convertToCloudFrontUrl(
      "https://hhcourses-assets.s3.us-east-2.amazonaws.com/KalimaTawheed/sectionImages/1.1.5.png"
    ),
    convertToCloudFrontUrl(
      "https://hhcourses-assets.s3.us-east-2.amazonaws.com/KalimaTawheed/sectionImages/2.1.1.png"
    ),
    convertToCloudFrontUrl(
      "https://hhcourses-assets.s3.us-east-2.amazonaws.com/KalimaTawheed/sectionImages/2.1.2.png"
    ),
    convertToCloudFrontUrl(
      "https://hhcourses-assets.s3.us-east-2.amazonaws.com/KalimaTawheed/sectionImages/2.1.3.png"
    ),
    convertToCloudFrontUrl(
      "https://hhcourses-assets.s3.us-east-2.amazonaws.com/KalimaTawheed/sectionImages/3.1.1.png"
    ),
    convertToCloudFrontUrl(
      "https://hhcourses-assets.s3.us-east-2.amazonaws.com/KalimaTawheed/sectionImages/3.1.2.png"
    ),
    convertToCloudFrontUrl(
      "https://hhcourses-assets.s3.us-east-2.amazonaws.com/KalimaTawheed/sectionImages/3.1.3.png"
    ),
    convertToCloudFrontUrl(
      "https://hhcourses-assets.s3.us-east-2.amazonaws.com/KalimaTawheed/sectionImages/3.3.1.png"
    ),
    convertToCloudFrontUrl(
      "https://hhcourses-assets.s3.us-east-2.amazonaws.com/KalimaTawheed/sectionImages/3.3.2.png"
    ),
    convertToCloudFrontUrl(
      "https://hhcourses-assets.s3.us-east-2.amazonaws.com/KalimaTawheed/sectionImages/3.3.3.png"
    ),
    convertToCloudFrontUrl(
      "https://hhcourses-assets.s3.us-east-2.amazonaws.com/KalimaTawheed/sectionImages/3.3.4.png"
    ),
    convertToCloudFrontUrl(
      "https://hhcourses-assets.s3.us-east-2.amazonaws.com/KalimaTawheed/sectionImages/3.3.5.png"
    ),
    convertToCloudFrontUrl(
      "https://hhcourses-assets.s3.us-east-2.amazonaws.com/KalimaTawheed/sectionImages/3.3.6.png"
    ),
    convertToCloudFrontUrl(
      "https://hhcourses-assets.s3.us-east-2.amazonaws.com/KalimaTawheed/sectionImages/3.3.7.png"
    ),
    convertToCloudFrontUrl(
      "https://hhcourses-assets.s3.us-east-2.amazonaws.com/KalimaTawheed/sectionImages/4.1.1.png"
    ),
    convertToCloudFrontUrl(
      "https://hhcourses-assets.s3.us-east-2.amazonaws.com/KalimaTawheed/sectionImages/4.1.2.png"
    ),
    convertToCloudFrontUrl(
      "https://hhcourses-assets.s3.us-east-2.amazonaws.com/KalimaTawheed/sectionImages/4.1.3.png"
    ),
    convertToCloudFrontUrl(
      "https://hhcourses-assets.s3.us-east-2.amazonaws.com/KalimaTawheed/sectionImages/4.1.4.png"
    ),
    convertToCloudFrontUrl(
      "https://hhcourses-assets.s3.us-east-2.amazonaws.com/KalimaTawheed/sectionImages/4.1.5.png"
    ),
    convertToCloudFrontUrl(
      "https://hhcourses-assets.s3.us-east-2.amazonaws.com/KalimaTawheed/sectionImages/4.1.6.png"
    ),
    // Add more images for the first row
  ],
  [
    convertToCloudFrontUrl(
      "https://hhcourses-assets.s3.us-east-2.amazonaws.com/BasicArabicGrammer/sectionImages/1.1.1.png"
    ),
    convertToCloudFrontUrl(
      "https://hhcourses-assets.s3.us-east-2.amazonaws.com/BasicArabicGrammer/sectionImages/1.1.2.png"
    ),
    convertToCloudFrontUrl(
      "https://hhcourses-assets.s3.us-east-2.amazonaws.com/BasicArabicGrammer/sectionImages/1.2.1.png"
    ),
    convertToCloudFrontUrl(
      "https://hhcourses-assets.s3.us-east-2.amazonaws.com/BasicArabicGrammer/sectionImages/1.2.2.png"
    ),
    convertToCloudFrontUrl(
      "https://hhcourses-assets.s3.us-east-2.amazonaws.com/BasicArabicGrammer/sectionImages/2.4.2.png"
    ),
    convertToCloudFrontUrl(
      "https://hhcourses-assets.s3.us-east-2.amazonaws.com/BasicArabicGrammer/sectionImages/1.3.1.png"
    ),
    convertToCloudFrontUrl(
      "https://hhcourses-assets.s3.us-east-2.amazonaws.com/BasicArabicGrammer/sectionImages/1.3.2.png"
    ),
    convertToCloudFrontUrl(
      "https://hhcourses-assets.s3.us-east-2.amazonaws.com/BasicArabicGrammer/sectionImages/1.3.3.png"
    ),
    convertToCloudFrontUrl(
      "https://hhcourses-assets.s3.us-east-2.amazonaws.com/BasicArabicGrammer/sectionImages/1.3.4.png"
    ),
    convertToCloudFrontUrl(
      "https://hhcourses-assets.s3.us-east-2.amazonaws.com/BasicArabicGrammer/sectionImages/2.1.1.png"
    ),
    convertToCloudFrontUrl(
      "https://hhcourses-assets.s3.us-east-2.amazonaws.com/BasicArabicGrammer/sectionImages/2.1.2.png"
    ),
    convertToCloudFrontUrl(
      "https://hhcourses-assets.s3.us-east-2.amazonaws.com/BasicArabicGrammer/sectionImages/2.1.3.png"
    ),
    convertToCloudFrontUrl(
      "https://hhcourses-assets.s3.us-east-2.amazonaws.com/BasicArabicGrammer/sectionImages/2.1.4.png"
    ),
    convertToCloudFrontUrl(
      "https://hhcourses-assets.s3.us-east-2.amazonaws.com/BasicArabicGrammer/sectionImages/2.2.1.png"
    ),
    convertToCloudFrontUrl(
      "https://hhcourses-assets.s3.us-east-2.amazonaws.com/BasicArabicGrammer/sectionImages/2.2.3.png"
    ),
    convertToCloudFrontUrl(
      "https://hhcourses-assets.s3.us-east-2.amazonaws.com/BasicArabicGrammer/sectionImages/2.2.2.png"
    ),
    convertToCloudFrontUrl(
      "https://hhcourses-assets.s3.us-east-2.amazonaws.com/BasicArabicGrammer/sectionImages/2.2.4.png"
    ),
    convertToCloudFrontUrl(
      "https://hhcourses-assets.s3.us-east-2.amazonaws.com/BasicArabicGrammer/sectionImages/2.3.1.png"
    ),
    convertToCloudFrontUrl(
      "https://hhcourses-assets.s3.us-east-2.amazonaws.com/BasicArabicGrammer/sectionImages/2.3.2.png"
    ),
    convertToCloudFrontUrl(
      "https://hhcourses-assets.s3.us-east-2.amazonaws.com/BasicArabicGrammer/sectionImages/2.3.3.png"
    ),
    convertToCloudFrontUrl(
      "https://hhcourses-assets.s3.us-east-2.amazonaws.com/BasicArabicGrammer/sectionImages/2.3.4.png"
    ),
    convertToCloudFrontUrl(
      "https://hhcourses-assets.s3.us-east-2.amazonaws.com/BasicArabicGrammer/sectionImages/2.3.5.png"
    ),
    convertToCloudFrontUrl(
      "https://hhcourses-assets.s3.us-east-2.amazonaws.com/BasicArabicGrammer/sectionImages/2.3.6.png"
    ),
    convertToCloudFrontUrl(
      "https://hhcourses-assets.s3.us-east-2.amazonaws.com/BasicArabicGrammer/sectionImages/2.4.1.png"
    ),
    // Add more images for the third row
  ],
  [
    convertToCloudFrontUrl(
      "https://hhcourses-assets.s3.us-east-2.amazonaws.com/The6PillarsOfIman/sectionImages/1.1.1.png"
    ),
    convertToCloudFrontUrl(
      "https://hhcourses-assets.s3.us-east-2.amazonaws.com/The6PillarsOfIman/sectionImages/1.1.2.png"
    ),
    convertToCloudFrontUrl(
      "https://hhcourses-assets.s3.us-east-2.amazonaws.com/The6PillarsOfIman/sectionImages/1.1.3.png"
    ),
    convertToCloudFrontUrl(
      "https://hhcourses-assets.s3.us-east-2.amazonaws.com/The6PillarsOfIman/sectionImages/1.1.4.png"
    ),
    convertToCloudFrontUrl(
      "https://hhcourses-assets.s3.us-east-2.amazonaws.com/The6PillarsOfIman/sectionImages/1.2.1.png"
    ),
    convertToCloudFrontUrl(
      "https://hhcourses-assets.s3.us-east-2.amazonaws.com/The6PillarsOfIman/sectionImages/1.3.1.png"
    ),
    convertToCloudFrontUrl(
      "https://hhcourses-assets.s3.us-east-2.amazonaws.com/The6PillarsOfIman/sectionImages/1.3.2.png"
    ),
    convertToCloudFrontUrl(
      "https://hhcourses-assets.s3.us-east-2.amazonaws.com/The6PillarsOfIman/sectionImages/1.3.3.png"
    ),
    convertToCloudFrontUrl(
      "https://hhcourses-assets.s3.us-east-2.amazonaws.com/The6PillarsOfIman/sectionImages/1.3.4.png"
    ),
    convertToCloudFrontUrl(
      "https://hhcourses-assets.s3.us-east-2.amazonaws.com/The6PillarsOfIman/sectionImages/1.4.1.png"
    ),
    convertToCloudFrontUrl(
      "https://hhcourses-assets.s3.us-east-2.amazonaws.com/The6PillarsOfIman/sectionImages/1.4.2.png"
    ),
    convertToCloudFrontUrl(
      "https://hhcourses-assets.s3.us-east-2.amazonaws.com/The6PillarsOfIman/sectionImages/1.4.3.png"
    ),
    convertToCloudFrontUrl(
      "https://hhcourses-assets.s3.us-east-2.amazonaws.com/The6PillarsOfIman/sectionImages/1.4.4.png"
    ),
    convertToCloudFrontUrl(
      "https://hhcourses-assets.s3.us-east-2.amazonaws.com/The6PillarsOfIman/sectionImages/1.4.5.png"
    ),
    convertToCloudFrontUrl(
      "https://hhcourses-assets.s3.us-east-2.amazonaws.com/The6PillarsOfIman/sectionImages/1.4.6.png"
    ),
    convertToCloudFrontUrl(
      "https://hhcourses-assets.s3.us-east-2.amazonaws.com/The6PillarsOfIman/sectionImages/2.1.1.png"
    ),
    convertToCloudFrontUrl(
      "https://hhcourses-assets.s3.us-east-2.amazonaws.com/The6PillarsOfIman/sectionImages/2.1.2.png"
    ),
    convertToCloudFrontUrl(
      "https://hhcourses-assets.s3.us-east-2.amazonaws.com/The6PillarsOfIman/sectionImages/2.1.3.png"
    ),
    convertToCloudFrontUrl(
      "https://hhcourses-assets.s3.us-east-2.amazonaws.com/The6PillarsOfIman/sectionImages/2.1.4.png"
    ),
    convertToCloudFrontUrl(
      "https://hhcourses-assets.s3.us-east-2.amazonaws.com/The6PillarsOfIman/sectionImages/3.1.1.png"
    ),
    convertToCloudFrontUrl(
      "https://hhcourses-assets.s3.us-east-2.amazonaws.com/The6PillarsOfIman/sectionImages/3.1.2.png"
    ),
    convertToCloudFrontUrl(
      "https://hhcourses-assets.s3.us-east-2.amazonaws.com/The6PillarsOfIman/sectionImages/3.1.3.png"
    ),
    convertToCloudFrontUrl(
      "https://hhcourses-assets.s3.us-east-2.amazonaws.com/The6PillarsOfIman/sectionImages/3.1.4.png"
    ),
    convertToCloudFrontUrl(
      "https://hhcourses-assets.s3.us-east-2.amazonaws.com/The6PillarsOfIman/sectionImages/3.1.5.png"
    ),

    // Add more images for the second row
  ],
];

const Button = styled.button`
  padding: 1rem 2rem;
  border-radius: 16px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  font-family: "Quicksand", sans-serif;
  width: 360px;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  transition: transform 0.1s ease;
  background: #8747d4;
  color: white;
  border: none;
  box-shadow: 0 4px 0 #6535a3;
  position: relative;
  top: 0;

  &:hover {
    background: #9859e0;
  }

  &:active {
    top: 4px;
    box-shadow: 0 0 0 #6535a3;
  }
`;

function GetStarted() {
  const history = useHistory();

  const handleGetStarted = (e) => {
    e.preventDefault();
    setTimeout(() => {
      history.push("/WelcomeMessageOne");
    }, 150);
  };

  return (
    <div className="WelcomeMessageContainer">
      <div className="GetStartedImagesContainer">
        {images.map((row, rowIndex) => (
          <div key={rowIndex} className="GetStartedRow">
            {row.map((image, index) => (
              <motion.img
                key={index}
                src={image}
                alt={`Image ${index + 1}`}
                className="GetStartedImage"
                loading="lazy"
                decoding="async"
                initial={{
                  x:
                    rowIndex === 0
                      ? "-2200%"
                      : rowIndex === 1
                      ? "0%"
                      : "-2200%",
                }}
                animate={{
                  x: rowIndex === 0 ? "0%" : rowIndex === 1 ? "-2200%" : "0%",
                }}
                transition={{
                  repeat: Infinity,
                  duration: 50,
                  ease: "linear",
                }}
              />
            ))}
          </div>
        ))}
      </div>
      <div className="WelcomeMessageFooter">
        <div className="MessageTopSection">
          <div className="GetStartedHeader">HH Courses</div>
          <div className="WelcomeMessageText" style={{ paddingTop: 0 }}>
            Interactive Islamic Studies
          </div>
        </div>
        <div className="MessageMiddleSection">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <SolidButton className="primary" onClick={handleGetStarted}>
              GET STARTED
            </SolidButton>
          </div>
        </div>
        <div className="MessageBottomSection">
          {" "}
          <Link to={"/LoginPage"}>
            <p className="GetStartedLoginText">
              Already have an account?{" "}
              <span style={{ color: "#8747d4" }}>Log In</span>
            </p>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default GetStarted;
