import React, { useState, useEffect } from "react";
import styled from "styled-components";
import api from "../api";
import Header from "../Header";
import BottomNavBar from "./BottomNavBar";

const Container = styled.div`
  padding: 10px;
  max-width: 100%;
  width: 100%;
`;

const DetailCard = styled.div`
  background-color: #6535a3;
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 20px;
  width: 100%;
`;

const CardTitle = styled.h2`
  color: white;
  margin: 0;
  font-size: 1.2rem;
  text-align: center;
`;

const DatePicker = styled.input`
  width: 200px;
  height: 50px;
  padding: 15px 20px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.2);
  margin: 20px auto;
  display: block;
  font-size: 16px;

  &:focus {
    outline: none;
    border-color: rgba(255, 255, 255, 0.5);
  }

  &::-webkit-calendar-picker-indicator {
    filter: invert(1);
  }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  color: white;
`;

const TableHeader = styled.th`
  padding: 10px;
  text-align: ${(props) => props.align || "left"};
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  cursor: pointer;
`;

const TableRow = styled.tr`
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
`;

const TableCell = styled.td`
  padding: 10px;
  text-align: ${(props) => props.align || "left"};
`;

function DailyTopPerformer() {
  const [selectedDate, setSelectedDate] = useState(() => {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);
    return yesterday.toISOString().split("T")[0];
  });
  const [performanceData, setPerformanceData] = useState([]);
  const [sortConfig, setSortConfig] = useState({
    key: "newLessons",
    direction: "desc",
  });

  useEffect(() => {
    if (selectedDate) {
      fetchPerformanceData();
    }
  }, [selectedDate]);

  const fetchPerformanceData = async () => {
    try {
      // Get all user lessons data
      const response = await api.get("/dailyUserLessons");
      const allData = response.data;

      // Get the selected date and previous date data
      const selectedDateData = allData.filter(
        (record) => record.date === selectedDate
      );

      // Calculate previous date
      const [year, month, day] = selectedDate.split("-");
      const prevDate = new Date(year, month - 1, parseInt(day) - 1);
      const prevDateString = prevDate.toISOString().split("T")[0];

      const prevDateData = allData.filter(
        (record) => record.date === prevDateString
      );

      // Calculate performance data and filter out 0 new lessons
      const performance = selectedDateData
        .map((currentRecord) => {
          const prevRecord = prevDateData.find(
            (prev) => prev.userEmail === currentRecord.userEmail
          );

          const newLessons = prevRecord
            ? currentRecord.lessonsCompleted - prevRecord.lessonsCompleted
            : currentRecord.lessonsCompleted;

          return {
            name: `${currentRecord.givenName} ${
              currentRecord.familyName || ""
            }`,
            newLessons,
            totalLessons: currentRecord.lessonsCompleted,
          };
        })
        .filter((record) => record.newLessons > 0); // Only keep records with new lessons

      setPerformanceData(performance);
    } catch (error) {
      console.error("Error fetching performance data:", error);
    }
  };

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const getSortedData = () => {
    const sorted = [...performanceData].sort((a, b) => {
      if (sortConfig.direction === "asc") {
        return a[sortConfig.key] - b[sortConfig.key];
      }
      return b[sortConfig.key] - a[sortConfig.key];
    });
    return sorted;
  };

  return (
    <>
      <Container>
        <DetailCard>
          <CardTitle>Daily Performance Analysis</CardTitle>
          <DatePicker
            type="date"
            value={selectedDate}
            onChange={(e) => setSelectedDate(e.target.value)}
          />

          <Table>
            <thead>
              <TableRow>
                <TableHeader onClick={() => handleSort("name")}>
                  User Name
                </TableHeader>
                <TableHeader
                  align="right"
                  onClick={() => handleSort("newLessons")}
                >
                  New Lessons
                </TableHeader>
              </TableRow>
            </thead>
            <tbody>
              {getSortedData().map((record, index) => (
                <TableRow key={index}>
                  <TableCell>{record.name}</TableCell>
                  <TableCell align="right">
                    {record.newLessons >= 0 ? "+" : ""}
                    {record.newLessons}
                  </TableCell>
                </TableRow>
              ))}
            </tbody>
          </Table>
        </DetailCard>
        <div style={{ height: "60px" }} />
      </Container>
      <BottomNavBar />
    </>
  );
}

export default DailyTopPerformer;
