import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useAmazonContext } from "../../Contexts/AmazonContext";
import api from "../api";
import { Header } from "./landingPageSharedStyledComponents";
import BottomNavBar from "./BottomNavBar";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  BarChart,
  Bar,
  ReferenceLine,
} from "recharts";
import { useHistory } from "react-router-dom";
import SolidButton from "../SolidButton";

const DashboardContainer = styled.div`
  padding: 10px;
  max-width: 100%;
  width: 100%;
`;

const MetricCard = styled.div`
  background-color: #6535a3;
  border-radius: 16px;
  padding: 20px;
  text-align: center;

  ${(props) =>
    props.clickable &&
    `
    cursor: pointer;
    box-shadow: 0 4px 0 #6535a3;
    position: relative;
    top: 0;
    transition: transform 0.01s ease;
    background: #8747d4;

    &:hover {
      background: #9859e0;
    }

    &:active {
      top: 4px;
      box-shadow: 0 0 0 #6535a3;
    }
  `}
`;

const MetricValue = styled.div`
  font-size: 2rem;
  font-weight: bold;
  color: white;
  margin: 10px 0;
`;

const MetricTitle = styled.div`
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.8);
  font-weight: bold;
  font-family: "Quicksand", sans-serif;
`;

const MetricSubtext = styled.div`
  font-size: 0.8rem;
  color: rgba(255, 255, 255, 0.6);
  font-family: "Quicksand", sans-serif;
`;

const ChartCard = styled.div`
  background-color: #6535a3;
  border-radius: 10px;
  padding: 15px 0px;
  margin-bottom: 20px;
  width: 100%;
`;

const ChartTitle = styled.h3`
  font-family: "Quicksand", sans-serif;
  color: rgba(255, 255, 255, 0.9);
  margin: 0 0 15px 0;
  font-size: 1.1rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
`;

const ChartSubtitle = styled.div`
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.8rem;
  margin-top: -10px;
  margin-bottom: 10px;
  text-align: center;
`;

// Add new styled component for metrics row
const MetricsRow = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 20px;
  width: 100%;

  ${MetricCard} {
    flex: 1;
    margin: 0;
  }
`;

const ChartContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 20px;
`;

const BarChartContainer = styled(ChartCard)`
  height: 400px;
`;

function Dashboard() {
  const [dailyCompletions, setDailyCompletions] = useState([]);
  const [dailyUsers, setDailyUsers] = useState([]);
  const [dailyUserVariance, setDailyUserVariance] = useState([]);
  const [dailyCompletionRate, setDailyCompletionRate] = useState([]);
  const [totalUsers, setTotalUsers] = useState(0);
  const [realTimeLessons, setRealTimeLessons] = useState(0);
  const [courseData, setCourseData] = useState([]);
  const [dailyVariance, setDailyVariance] = useState([]);
  const history = useHistory();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [
          completionsResponse,
          usersResponse,
          totalUsersResponse,
          currentTotalResponse,
          courseResponse,
        ] = await Promise.all([
          api.get("/dailyLessonCompletions"),
          api.get("/dailyUserTotals"),
          api.get("/totalUsers"),
          api.get("/currentTotalLessons"),
          api.get("/lessonsByCourse"),
        ]);

        // Format lessons data
        const formattedLessons = completionsResponse.data.map((stat) => ({
          date: new Date(stat.timestamp).toLocaleDateString("en-US", {
            weekday: "short",
            month: "short",
            day: "numeric",
          }),
          total: stat.totalLessonsCompleted,
        }));

        // Calculate daily variance
        const varianceData = formattedLessons.map((curr, index) => {
          const prevTotal =
            index > 0 ? formattedLessons[index - 1].total : curr.total;
          return {
            date: curr.date,
            variance: curr.total - prevTotal,
          };
        });

        // Format users data
        const formattedUsers = usersResponse.data.map((stat) => ({
          date: new Date(stat.timestamp).toLocaleDateString("en-US", {
            weekday: "short",
            month: "short",
            day: "numeric",
          }),
          total: stat.totalUsers,
        }));

        // Calculate daily user variance
        const userVarianceData = formattedUsers.map((curr, index) => {
          const prevTotal =
            index > 0 ? formattedUsers[index - 1].total : curr.total;
          return {
            date: curr.date,
            variance: curr.total - prevTotal,
          };
        });

        // Calculate completion rate per user
        const completionRateData = formattedLessons.map((lesson, index) => {
          const userCount = formattedUsers[index]?.total || 1; // Prevent division by zero
          const variance =
            index > 0
              ? lesson.total - formattedLessons[index - 1].total
              : lesson.total;
          return {
            date: lesson.date,
            rate: variance / userCount, // Daily lessons completed divided by total users
            target: 1.0, // 1 lesson per user target
          };
        });

        // Format course data - removed "Course" prefix
        const formattedCourseData = courseResponse.data.map((course) => ({
          name: course.courseId, // Removed the "Course" prefix
          lessons: course.total,
        }));

        setDailyCompletions(formattedLessons);
        setDailyUsers(formattedUsers);
        setDailyUserVariance(userVarianceData.slice(1)); // Remove first day since it has no variance
        setTotalUsers(totalUsersResponse.data.totalUsers);
        setRealTimeLessons(currentTotalResponse.data.totalLessons);
        setCourseData(formattedCourseData);
        setDailyVariance(varianceData.slice(1)); // Remove first day since it has no variance
        setDailyCompletionRate(completionRateData.slice(1)); // Remove first day since it has no variance
      } catch (error) {
        console.error("Error fetching dashboard data:", error);
      }
    };

    fetchData();
  }, []);

  // Custom label component for the data points
  const CustomizedLabel = ({ x, y, value }) => {
    return (
      <text x={x} y={y - 10} fill="#fff" fontSize={12} textAnchor="middle">
        {value.toLocaleString()}
      </text>
    );
  };

  const handleLessonsDetail = (e) => {
    e.preventDefault();
    setTimeout(() => {
      history.push("/user-lessons-detail");
    }, 150);
  };

  const handleUserDetail = (e) => {
    e.preventDefault();
    setTimeout(() => {
      history.push("/user-profile-detail");
    }, 150);
  };

  return (
    <>
      <Header className="sideNavChapterHeader">
        <p>Admin Dashboard</p>
      </Header>
      <DashboardContainer>
        <MetricsRow>
          <MetricCard clickable onClick={handleLessonsDetail}>
            <MetricTitle>Total Lessons Completed</MetricTitle>
            <MetricValue>
              {realTimeLessons !== null
                ? realTimeLessons.toLocaleString()
                : "0"}
            </MetricValue>
            <MetricSubtext>across all users</MetricSubtext>
          </MetricCard>

          <MetricCard clickable onClick={handleUserDetail}>
            <MetricTitle>Total Users</MetricTitle>
            <MetricValue>{totalUsers.toLocaleString()}</MetricValue>
            <MetricSubtext>active users</MetricSubtext>
          </MetricCard>
        </MetricsRow>

        <ChartContainer>
          <ChartCard>
            <ChartTitle>Daily Lesson Completions</ChartTitle>
            <ChartSubtitle>Lessons completed each day</ChartSubtitle>
            <div style={{ width: "100%", height: 300 }}>
              <ResponsiveContainer>
                <LineChart
                  data={dailyVariance}
                  margin={{
                    top: 30,
                    right: 15,
                    left: 0,
                    bottom: 40,
                  }}
                >
                  <CartesianGrid
                    strokeDasharray="3 3"
                    stroke="rgba(255,255,255,0.1)"
                  />
                  <XAxis
                    dataKey="date"
                    stroke="rgba(255,255,255,0.8)"
                    tick={{ fill: "rgba(255,255,255,0.8)", fontSize: 11 }}
                    angle={-45}
                    textAnchor="end"
                    height={60}
                    interval={0}
                    padding={{ left: 5, right: 5 }}
                  />
                  <YAxis
                    stroke="rgba(255,255,255,0.8)"
                    tick={{ fill: "rgba(255,255,255,0.8)" }}
                  />
                  <Tooltip
                    contentStyle={{
                      backgroundColor: "rgba(255,255,255,0.9)",
                      border: "none",
                      borderRadius: "4px",
                      color: "#6535a3",
                    }}
                    formatter={(value) => [
                      `${value > 0 ? "+" : ""}${value}`,
                      "Change",
                    ]}
                  />
                  <Line
                    type="monotone"
                    dataKey="variance"
                    stroke="#fff"
                    strokeWidth={2}
                    dot={{ fill: "#fff", strokeWidth: 2 }}
                    activeDot={{ r: 8 }}
                    label={<CustomizedLabel />}
                  />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </ChartCard>

          <ChartCard>
            <ChartTitle>Daily User Growth</ChartTitle>
            <ChartSubtitle>New users each day</ChartSubtitle>
            <div style={{ width: "100%", height: 300 }}>
              <ResponsiveContainer>
                <LineChart
                  data={dailyUserVariance}
                  margin={{
                    top: 30,
                    right: 15,
                    left: 0,
                    bottom: 40,
                  }}
                >
                  <CartesianGrid
                    strokeDasharray="3 3"
                    stroke="rgba(255,255,255,0.1)"
                  />
                  <XAxis
                    dataKey="date"
                    stroke="rgba(255,255,255,0.8)"
                    tick={{ fill: "rgba(255,255,255,0.8)", fontSize: 11 }}
                    angle={-45}
                    textAnchor="end"
                    height={60}
                    interval={0}
                    padding={{ left: 5, right: 5 }}
                  />
                  <YAxis
                    stroke="rgba(255,255,255,0.8)"
                    tick={{ fill: "rgba(255,255,255,0.8)" }}
                    tickFormatter={(value) => Math.round(value)}
                    allowDecimals={false}
                  />
                  <Tooltip
                    contentStyle={{
                      backgroundColor: "rgba(255,255,255,0.9)",
                      border: "none",
                      borderRadius: "4px",
                      color: "#6535a3",
                    }}
                    formatter={(value) => [
                      `${value > 0 ? "+" : ""}${Math.round(value)}`,
                      "Change",
                    ]}
                  />
                  <Line
                    type="monotone"
                    dataKey="variance"
                    stroke="#fff"
                    strokeWidth={2}
                    dot={{ fill: "#fff", strokeWidth: 2 }}
                    activeDot={{ r: 8 }}
                    label={{
                      position: "top",
                      fill: "#fff",
                      fontSize: 12,
                      formatter: (value) => Math.round(value),
                    }}
                  />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </ChartCard>

          <ChartCard>
            <ChartTitle>Daily Completion Rate per User</ChartTitle>
            <ChartSubtitle>
              Average lessons completed per user each day
              <br />
              <span style={{ color: "#ff6b6b", fontSize: "0.75rem" }}>
                Red line: Target (1 lesson per user)
              </span>
            </ChartSubtitle>
            <div style={{ width: "100%", height: 300 }}>
              <ResponsiveContainer>
                <LineChart
                  data={dailyCompletionRate}
                  margin={{
                    top: 30,
                    right: 15,
                    left: 0,
                    bottom: 40,
                  }}
                >
                  <CartesianGrid
                    strokeDasharray="3 3"
                    stroke="rgba(255,255,255,0.1)"
                  />
                  <XAxis
                    dataKey="date"
                    stroke="rgba(255,255,255,0.8)"
                    tick={{ fill: "rgba(255,255,255,0.8)", fontSize: 11 }}
                    angle={-45}
                    textAnchor="end"
                    height={60}
                    interval={0}
                    padding={{ left: 5, right: 5 }}
                  />
                  <YAxis
                    stroke="rgba(255,255,255,0.8)"
                    tick={{ fill: "rgba(255,255,255,0.8)" }}
                    domain={[0, "auto"]}
                  />
                  <ReferenceLine
                    y={1.0}
                    stroke="#ff6b6b"
                    strokeDasharray="3 3"
                    strokeWidth={2}
                    ifOverflow="extendDomain"
                  />
                  <Tooltip
                    contentStyle={{
                      backgroundColor: "rgba(255,255,255,0.9)",
                      border: "none",
                      borderRadius: "4px",
                      color: "#6535a3",
                    }}
                    formatter={(value) => [
                      value.toFixed(2),
                      "Lessons per User",
                    ]}
                  />
                  <Line
                    type="monotone"
                    dataKey="rate"
                    stroke="#fff"
                    strokeWidth={2}
                    dot={{ fill: "#fff", strokeWidth: 2 }}
                    activeDot={{ r: 8 }}
                    label={{
                      position: "top",
                      fill: "#fff",
                      fontSize: 12,
                      formatter: (value) => value.toFixed(2),
                    }}
                  />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </ChartCard>

          <ChartCard>
            <ChartTitle>Daily Lesson Completion Totals</ChartTitle>
            <ChartSubtitle>Recorded daily at 11:59 PM ET</ChartSubtitle>
            <div style={{ width: "100%", height: 300 }}>
              <ResponsiveContainer>
                <LineChart
                  data={dailyCompletions}
                  margin={{
                    top: 30,
                    right: 15,
                    left: 0,
                    bottom: 40,
                  }}
                >
                  <CartesianGrid
                    strokeDasharray="3 3"
                    stroke="rgba(255,255,255,0.1)"
                  />
                  <XAxis
                    dataKey="date"
                    stroke="rgba(255,255,255,0.8)"
                    tick={{ fill: "rgba(255,255,255,0.8)", fontSize: 11 }}
                    angle={-45}
                    textAnchor="end"
                    height={60}
                    interval={0}
                    padding={{ left: 5, right: 5 }}
                  />
                  <YAxis
                    stroke="rgba(255,255,255,0.8)"
                    tick={{ fill: "rgba(255,255,255,0.8)" }}
                    domain={["dataMin - 25", "dataMax + 25"]}
                  />
                  <Tooltip
                    contentStyle={{
                      backgroundColor: "rgba(255,255,255,0.9)",
                      border: "none",
                      borderRadius: "4px",
                      color: "#6535a3",
                    }}
                  />
                  <Line
                    type="monotone"
                    dataKey="total"
                    stroke="#fff"
                    strokeWidth={2}
                    dot={{ fill: "#fff", strokeWidth: 2 }}
                    activeDot={{ r: 8 }}
                    label={<CustomizedLabel />}
                  />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </ChartCard>

          <ChartCard>
            <ChartTitle>Daily User Totals</ChartTitle>
            <ChartSubtitle>Recorded daily at 11:59 PM ET</ChartSubtitle>
            <div style={{ width: "100%", height: 300 }}>
              <ResponsiveContainer>
                <LineChart
                  data={dailyUsers}
                  margin={{
                    top: 30,
                    right: 15,
                    left: 0,
                    bottom: 40,
                  }}
                >
                  <CartesianGrid
                    strokeDasharray="3 3"
                    stroke="rgba(255,255,255,0.1)"
                  />
                  <XAxis
                    dataKey="date"
                    stroke="rgba(255,255,255,0.8)"
                    tick={{ fill: "rgba(255,255,255,0.8)", fontSize: 11 }}
                    angle={-45}
                    textAnchor="end"
                    height={60}
                    interval={0}
                    padding={{ left: 5, right: 5 }}
                  />
                  <YAxis
                    stroke="rgba(255,255,255,0.8)"
                    tick={{ fill: "rgba(255,255,255,0.8)" }}
                    domain={["dataMin - 25", "dataMax + 25"]}
                  />
                  <Tooltip
                    contentStyle={{
                      backgroundColor: "rgba(255,255,255,0.9)",
                      border: "none",
                      borderRadius: "4px",
                      color: "#6535a3",
                    }}
                  />
                  <Line
                    type="monotone"
                    dataKey="total"
                    stroke="#fff"
                    strokeWidth={2}
                    dot={{ fill: "#fff", strokeWidth: 2 }}
                    activeDot={{ r: 8 }}
                    label={<CustomizedLabel />}
                  />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </ChartCard>

          <BarChartContainer>
            <ChartTitle>Lessons Completed by Course</ChartTitle>
            <ChartSubtitle>Total lessons completed per course</ChartSubtitle>
            <div style={{ width: "100%", height: "100%" }}>
              <ResponsiveContainer>
                <BarChart
                  data={courseData}
                  margin={{
                    top: 30,
                    right: 15,
                    left: 15,
                    bottom: 100,
                  }}
                >
                  <CartesianGrid
                    strokeDasharray="3 3"
                    stroke="rgba(255,255,255,0.1)"
                  />
                  <XAxis
                    dataKey="name"
                    stroke="rgba(255,255,255,0.8)"
                    tick={{
                      fill: "rgba(255,255,255,0.8)",
                      fontSize: 10, // Reduced font size for course names
                    }}
                    angle={-45}
                    textAnchor="end"
                    height={60}
                    interval={0}
                  />
                  <YAxis
                    stroke="rgba(255,255,255,0.8)"
                    tick={{ fill: "rgba(255,255,255,0.8)" }}
                  />
                  <Tooltip
                    contentStyle={{
                      backgroundColor: "rgba(255,255,255,0.9)",
                      border: "none",
                      borderRadius: "4px",
                      color: "#6535a3",
                    }}
                  />
                  <Bar
                    dataKey="lessons"
                    fill="#fff"
                    label={{
                      position: "top",
                      fill: "#fff",
                      fontSize: 12,
                    }}
                  />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </BarChartContainer>
        </ChartContainer>

        <div style={{ height: "300px" }} />
      </DashboardContainer>
      <BottomNavBar />
    </>
  );
}

export default Dashboard;
