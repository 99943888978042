import React, { useState, useEffect } from "react";
import LessonForm from "./LessonForm";
import TemplateSelector from "./TemplateSelector";
import TemplateEditor from "./TemplateEditor";
import LessonPreview from "./LessonPreview";
import SidebarTemplatePreview from "./SidebarTemplatePreview";
import { nanoid } from "nanoid";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import cloneDeep from "lodash/cloneDeep";
import axios from "axios";
import api from "../api";
import CourseSelector from "./CourseSelector";
import ChapterSelector from "./ChapterSelector";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSyncAlt,
  faCode,
  faChevronUp,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import { useAmazonContext } from "../../Contexts/AmazonContext";
import "./LessonBuilder.css";
import QuizSidebarTemplatePreview from "../quizBuilder/QuizSidebarTemplatePreview";
import QuizTemplateSelector from "../quizBuilder/QuizTemplateSelector";
import QuizTemplateEditor from "../quizBuilder/QuizTemplateEditor";
//
const LessonBuilder = () => {
  let { predefinedTemplates, setPredefinedTemplates, predefinedQuizTemplates } =
    useAmazonContext();

  const [selectedCourse, setSelectedCourse] = useState(null);
  const [selectedChapter, setSelectedChapter] = useState(null);
  const [newChapter, setNewChapter] = useState(null);
  const [isExistingChapter, setIsExistingChapter] = useState(false); // New state for existing chapter
  const [selectedTopicHeader, setSelectedTopicHeader] = useState(null);
  const [newTopicHeader, setNewTopicHeader] = useState(null);
  const [topicHeaderDetails, setTopicHeaderDetails] = useState({});
  const [topicNumber, setTopicNumber] = useState("");
  const [specificChapter, setSpecificChapter] = useState("");
  const [lesson, setLesson] = useState({
    sectionNumber: "",
    sectionTitle: "",
    sectionImage: "",
    topicHeaderNumber: "",
    topicHeader: "",
    topicNumber: "",
    topicTitle: "",
    lessonAnimation: [],
  });

  const [previewMode, setPreviewMode] = useState(true);
  const [currentSectionIndex, setCurrentSectionIndex] = useState(-1);
  const [chapters, setChapters] = useState([]);
  const [topicHeaders, setTopicHeaders] = useState([]);
  const [chapterType, setChapterType] = useState("");
  const [headerType, setHeaderType] = useState("");
  // Add new state for quizzes
  const [quizzes, setQuizzes] = useState([]);

  // Add new state for JSON editing mode
  const [isJsonEditorOpen, setIsJsonEditorOpen] = useState(false);
  const [quizzesJson, setQuizzesJson] = useState("");
  const [jsonError, setJsonError] = useState(null);

  // Add new state for saving
  const [isSaving, setIsSaving] = useState(false);

  // ... (keep all existing functions)

  // Add new function to handle JSON changes
  // const handleQuizzesJsonChange = (event) => {
  //   const newJson = event.target.value;
  //   setQuizzesJson(newJson);
  //   try {
  //     const parsedQuizzes = JSON.parse(newJson);
  //     setQuizzes(parsedQuizzes);
  //     setJsonError(null);
  //   } catch (error) {
  //     setJsonError("Invalid JSON: " + error.message);
  //   }
  // };

  // Add new function to toggle JSON editor
  const toggleJsonEditor = () => {
    if (!isJsonEditorOpen) {
      // When opening JSON editor, update the JSON string
      setQuizzesJson(JSON.stringify(quizzes, null, 2));
    }
    setIsJsonEditorOpen(!isJsonEditorOpen);
  };
  // Add this useEffect hook
  useEffect(() => {
    if (lesson.sectionNumber) {
      setQuizzes((prevQuizzes) =>
        prevQuizzes.map((quiz) => ({
          ...quiz,
          sectionNumber: lesson.sectionNumber,
        }))
      );
    }
  }, [lesson.sectionNumber]);

  // Update quizzesJson whenever quizzes changes
  // useEffect(() => {
  //   setQuizzesJson(JSON.stringify(quizzes, null, 2));
  // }, [quizzes]);

  const resetLessonFields = () => {
    setLesson((prev) => ({
      ...prev,
      sectionNumber: "",
      topicHeaderNumber: "",
      topicHeader: "",
      topicNumber: "",
      topicTitle: "",
    }));
  };

  const fetchChapters = async () => {
    try {
      if (selectedCourse) {
        await api
          .get(`/${selectedCourse.courseTitle}/topicsData`, {
            params: {
              modelName: selectedCourse.courseTopicsModelName,
              collectionName: selectedCourse.courseTopicsCollectionName,
            },
          })
          .then((response) => {
            setChapters(response.data);
          });
      }
    } catch (error) {
      console.error("Error fetching chapters:", error);
    }
  };

  const fetchTopicHeaders = async () => {
    try {
      if (selectedCourse) {
        await api
          .get(`/${selectedCourse.courseTitle}/topicsData`, {
            params: {
              modelName: selectedCourse.courseTopicsModelName,
              collectionName: selectedCourse.courseTopicsCollectionName,
            },
          })
          .then((response) => {
            const headers = response.data.map((topic) => ({
              topicHeader: topic.topicHeader,
              topicHeaderNumber: topic.topicHeaderNumber,
            }));
            // Remove duplicates
            const uniqueHeaders = headers.reduce((acc, current) => {
              const x = acc.find(
                (item) => item.topicHeader === current.topicHeader
              );
              if (!x) {
                return acc.concat([current]);
              } else {
                return acc;
              }
            }, []);
            setTopicHeaders(uniqueHeaders);
          });
      }
    } catch (error) {
      console.error("Error fetching topic headers:", error);
    }
  };

  useEffect(() => {
    if (selectedCourse) {
      fetchChapters();
      fetchTopicHeaders();
    }
  }, [selectedCourse]);

  const incrementLessonNumber = (lastLessonNumber) => {
    const parts = lastLessonNumber.split(".").map(Number);
    parts[2] += 1;
    return parts.join(".");
  };

  const incrementTopicNumber = (lastTopicNumber) => {
    const parts = lastTopicNumber.split(".").map(Number);
    parts[1] += 1;
    const nextTopicNumber = `${parts[0]}.${parts[1]}`;
    console.log(
      `Incrementing topic number from ${lastTopicNumber} to ${nextTopicNumber}`
    );
    return nextTopicNumber;
  };

  const addTemplate = (template) => {
    const newTemplate = { ...template, id: nanoid() };
    setLesson((prev) => ({
      ...prev,
      lessonAnimation: [...prev.lessonAnimation, newTemplate],
    }));
  };

  const updateTemplate = (id, updatedTemplate) => {
    const updatedAnimations = lesson.lessonAnimation.map((template) =>
      template.id === id ? updatedTemplate : template
    );
    setLesson({ ...lesson, lessonAnimation: updatedAnimations });
  };

  const deleteTemplate = (id) => {
    const updatedAnimations = lesson.lessonAnimation.filter(
      (template) => template.id !== id
    );
    setLesson({ ...lesson, lessonAnimation: updatedAnimations });
  };

  const duplicateTemplate = (template) => {
    const newTemplate = cloneDeep(template);
    newTemplate.id = nanoid();
    const templateIndex = lesson.lessonAnimation.findIndex(
      (t) => t.id === template.id
    );
    setLesson((prev) => {
      const newAnimations = [...prev.lessonAnimation];
      newAnimations.splice(templateIndex + 1, 0, newTemplate);
      return { ...prev, lessonAnimation: newAnimations };
    });
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;
    const items = Array.from(lesson.lessonAnimation);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setLesson((prev) => ({
      ...prev,
      lessonAnimation: items,
    }));
  };

  const saveLesson = async () => {
    if (isSaving) return;

    try {
      setIsSaving(true);

      // Validation checks
      const missingFields = [];

      if (!lesson.topicHeader) {
        missingFields.push("Topic Title");
      }

      if (!lesson.topicTitle) {
        missingFields.push("Chapter Title");
      }

      if (!lesson.sectionTitle) {
        missingFields.push("Lesson Title");
      }

      if (!lesson.sectionNumber) {
        missingFields.push("Lesson Number");
      }
      if (!lesson.sectionImage) {
        missingFields.push("Lesson Image");
      }

      if (!lesson.lessonAnimation || lesson.lessonAnimation.length === 0) {
        missingFields.push("Lesson Content");
      }

      if (quizzes.length < 2) {
        missingFields.push("At least two Quiz Questions");
      }

      // New validation for quiz answers
      const quizzesWithMissingExplanations = quizzes.filter((quiz) =>
        quiz.answers.some((answer) => !answer.isCorrect && !answer.explanation)
      );

      if (quizzesWithMissingExplanations.length > 0) {
        missingFields.push("Explanations for incorrect answers in quizzes");
      }

      // Check if JSON is valid using jsonError
      if (jsonError) {
        alert(`Cannot save lesson. The quiz JSON is invalid: ${jsonError}`);
        return;
      }

      if (missingFields.length > 0) {
        alert(
          `Cannot save lesson. The following are missing:\n${missingFields.join(
            "\n"
          )}`
        );
        return;
      }
      console.log("Lesson to be sent:", JSON.stringify(lesson, null, 2));
      console.log("Quizzes to be sent:", JSON.stringify(quizzes, null, 2));
      console.log(newChapter);

      // Ensure all quizzes have the correct sectionNumber before saving
      const updatedQuizzes = quizzes.map((quiz) => ({
        ...quiz,
        sectionNumber: lesson.sectionNumber,
      }));

      let lessonSaveResponse;

      // Save lesson first
      if (isExistingChapter) {
        lessonSaveResponse = await api.post(
          `/${selectedCourse.courseTitle}/addLesson`,
          {
            modelName: selectedCourse.courseTopicsModelName,
            chapterId: selectedChapter._id,
            lesson,
          }
        );

        if (!lessonSaveResponse.data) {
          throw new Error("Failed to save lesson");
        }
      } else if (newChapter) {
        // Build new chapter structure
        const newChapterStructure = {
          topicHeader: lesson.topicHeader,
          topicHeaderNumber: lesson.topicHeaderNumber,
          topicNumber: lesson.topicNumber,
          topicTitle: lesson.topicTitle,
          topicDescription: lesson.topicDescription || "", // Ensure this is pulled from the form if available
          topicYoutubeLink: lesson.topicYoutubeLink || "", // Ensure this is pulled from the form if available
          sections: [lesson], // The lesson is pushed within the sections array
        };

        console.log(
          "New chapter structure:",
          JSON.stringify(newChapterStructure, null, 2)
        );

        lessonSaveResponse = await api.post(
          `/${selectedCourse.courseTitle}/addChapter`,
          {
            modelName: selectedCourse.courseTopicsModelName,
            chapter: newChapterStructure,
          }
        );

        if (!lessonSaveResponse.data) {
          throw new Error("Failed to save new chapter");
        }
      }

      // Only save quizzes if lesson save was successful
      if (updatedQuizzes.length > 0) {
        const savedQuizzesResponse = await api.post(
          `/${selectedCourse.courseTitle}/addQuizzes`,
          {
            modelName: selectedCourse.courseExamplesModelName,
            collectionName: selectedCourse.courseExamplesCollectionName,
            quizzes: updatedQuizzes,
          }
        );

        if (!savedQuizzesResponse.data) {
          throw new Error("Failed to save quizzes");
        }
      }

      alert("Lesson and quizzes saved successfully!");
    } catch (error) {
      console.error(
        "Failed to save:",
        error.response ? error.response.data : error.message
      );
      alert(`Failed to save: ${error.message}`);
    } finally {
      setIsSaving(false);
    }
  };

  const refreshPreview = () => {
    setPreviewMode(false);
    setCurrentSectionIndex(-1);
    setTimeout(() => setPreviewMode(true), 100);
  };

  const startNewLesson = () => {
    const confirmNewLesson = window.confirm(
      "Are you sure you want to start a new lesson? Any unsaved changes will be lost."
    );

    if (confirmNewLesson) {
      fetchChapters();
      fetchTopicHeaders();
      if (chapterType === "new") {
        setChapterType("existing");
        setSpecificChapter(topicNumber);
        setIsExistingChapter(true);
        // Change chapter type to existing if criteria are met
      }
    }
  };

  // Add new functions for quiz handling
  const addQuiz = (template) => {
    const newQuiz = {
      sectionNumber: lesson.sectionNumber, // Add this line
      ...template,
      id: nanoid(),
    };
    setQuizzes((prev) => [...prev, newQuiz]);
  };

  const updateQuiz = (id, updatedQuiz) => {
    setQuizzes((prevQuizzes) =>
      prevQuizzes.map((quiz) =>
        quiz.id === id ? { ...quiz, ...updatedQuiz } : quiz
      )
    );
  };

  const deleteQuiz = (id) => {
    const updatedQuizzes = quizzes.filter((quiz) => quiz.id !== id);
    setQuizzes(updatedQuizzes);
  };

  const duplicateQuiz = (quiz) => {
    const newQuiz = { ...quiz, id: nanoid() };
    const quizIndex = quizzes.findIndex((q) => q.id === quiz.id);
    setQuizzes((prev) => {
      const newQuizzes = [...prev];
      newQuizzes.splice(quizIndex + 1, 0, newQuiz);
      return newQuizzes;
    });
  };

  console.log(lesson);
  console.log(quizzes);

  return (
    <div className="lesson-builder">
      <SidebarTemplatePreview
        templates={predefinedTemplates}
        onSelectTemplate={addTemplate}
      />
      <QuizSidebarTemplatePreview
        templates={predefinedQuizTemplates}
        onSelectTemplate={addQuiz}
      />
      <div className="left-side">
        <div className="lessonSelectorContainer">
          <CourseSelector setSelectedCourse={setSelectedCourse} />
          {selectedCourse && (
            <ChapterSelector
              selectedCourse={selectedCourse}
              selectedChapter={selectedChapter}
              setSelectedChapter={setSelectedChapter}
              setNewChapter={setNewChapter}
              setIsExistingChapter={setIsExistingChapter} // Pass the state setter as a prop
              setSelectedTopicHeader={setSelectedTopicHeader}
              setNewTopicHeader={setNewTopicHeader}
              topicHeaderDetails={topicHeaderDetails}
              setTopicHeaderDetails={setTopicHeaderDetails}
              topicNumber={topicNumber}
              setTopicNumber={setTopicNumber} // Pass down the setter
              incrementTopicNumber={incrementTopicNumber} // Pass down the increment function
              setLesson={setLesson} // Pass down setLesson to ChapterSelector
              resetLessonFields={resetLessonFields} // Pass down resetLessonFields to ChapterSelector
              chapters={chapters}
              topicHeaders={topicHeaders}
              startNewLesson={startNewLesson}
              chapterType={chapterType}
              setChapterType={setChapterType}
              headerType={headerType}
              setHeaderType={setHeaderType}
              specificChapter={specificChapter}
              setSpecificChapter={setSpecificChapter}
              quizzes={quizzes}
              setQuizzes={setQuizzes}
            />
          )}
          <LessonForm
            setLesson={setLesson}
            lesson={lesson}
            topicHeaderDetails={topicHeaderDetails}
            topicNumber={topicNumber}
            setTopicNumber={setTopicNumber}
          />
          <div className="lessonSelectorButtonsContainer">
            {" "}
            <button
              onClick={startNewLesson}
              className="save-lesson-button"
              style={{ backgroundColor: "rgb(221, 98, 96)" }}
            >
              New Lesson
            </button>
            <button onClick={saveLesson} className="save-lesson-button">
              Save Lesson
            </button>
          </div>
        </div>

        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="templates">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {lesson.lessonAnimation.map((template, index) => (
                  <Draggable
                    key={template.id}
                    draggableId={template.id}
                    index={index}
                  >
                    {(provided) => (
                      <div ref={provided.innerRef} {...provided.draggableProps}>
                        <TemplateEditor
                          template={template}
                          onDelete={() => deleteTemplate(template.id)}
                          onSave={(updatedTemplate) =>
                            updateTemplate(template.id, updatedTemplate)
                          }
                          onDuplicate={duplicateTemplate}
                          dragHandleProps={provided.dragHandleProps}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        {/* Add Quiz Editors */}

        <DragDropContext
          onDragEnd={(result) => {
            // Implement drag and drop for quizzes

            if (!result.destination) return;

            const items = Array.from(quizzes);

            const [reorderedItem] = items.splice(result.source.index, 1);

            items.splice(result.destination.index, 0, reorderedItem);

            setQuizzes(items);
          }}
        >
          <Droppable droppableId="quizzes">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {quizzes.map((quiz, index) => (
                  <Draggable key={quiz.id} draggableId={quiz.id} index={index}>
                    {(provided) => (
                      <div ref={provided.innerRef} {...provided.draggableProps}>
                        <QuizTemplateEditor
                          template={quiz}
                          sectionNumber={lesson.sectionNumber}
                          onDelete={() => deleteQuiz(quiz.id)}
                          onSave={(updatedQuiz) =>
                            updateQuiz(quiz.id, updatedQuiz)
                          }
                          onDuplicate={duplicateQuiz}
                          dragHandleProps={provided.dragHandleProps}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}

                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <TemplateSelector onSelect={addTemplate} />
        <QuizTemplateSelector></QuizTemplateSelector>
        {/* Add JSON editing toggle button */}
        {/* <button onClick={toggleJsonEditor} className="toggle-json-editor">
          <FontAwesomeIcon icon={faCode} />
          {isJsonEditorOpen ? "Hide Quiz JSON Editor" : "Show Quiz JSON Editor"}
          <FontAwesomeIcon
            icon={isJsonEditorOpen ? faChevronDown : faChevronUp}
          />
        </button> */}

        {/* Add collapsible JSON editor */}
        {/* {isJsonEditorOpen && (
          <div className="json-editor">
            <textarea
              value={quizzesJson}
              onChange={handleQuizzesJsonChange}
              rows={10}
              style={{ width: "100%", minHeight: "1000px" }}
            />
            {jsonError && <p className="error">{jsonError}</p>}
          </div>
        )} */}
      </div>
      <div className="right-side">
        <div className="refresh-container">
          <button onClick={refreshPreview} className="refresh-preview-button">
            <FontAwesomeIcon icon={faSyncAlt} />
          </button>
        </div>

        {previewMode && (
          <LessonPreview
            lesson={lesson}
            currentSectionIndex={currentSectionIndex}
            setCurrentSectionIndex={setCurrentSectionIndex}
            quizzes={quizzes}
          />
        )}
      </div>
    </div>
  );
};

export default LessonBuilder;
