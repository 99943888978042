import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { FaArrowLeft, FaArrowUp } from "react-icons/fa";
import api from "../api";
import SolidButton from "../SolidButton";

const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
`;

const Header = styled.div`
  position: relative;
  background: #f5f5f5;
  padding: 20px;
  text-align: center;
  border-bottom: 1px solid #eee;
  margin-bottom: 20px;

  p {
    margin: 0;
    font-size: 28px;
    font-weight: 600;
    font-family: "Quicksand", sans-serif;
    color: #333;
  }
`;

const BackButton = styled.button`
  background: none;
  border: none;
  color: #8747d4;
  font-size: 24px;
  cursor: pointer;
  padding: 10px;
  position: absolute;
  left: 20px;
  top: 20px;
`;

const FeedbackForm = styled.form`
  margin-bottom: 30px;
  background: #f5f5f5;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;

  button {
    width: 200px;
  }
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-family: "Quicksand", sans-serif;
`;

const Textarea = styled.textarea`
  width: 100%;
  min-height: 100px;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-family: "Quicksand", sans-serif;
`;

const FeedbackCard = styled.div`
  background: white;
  padding: 20px;
  margin-bottom: 15px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const FeedbackTitle = styled.h3`
  margin: 0 0 10px 0;
  color: #333;
  font-family: "Quicksand", sans-serif;
`;

const FeedbackDescription = styled.p`
  margin: 0 0 15px 0;
  color: #666;
  font-family: "Quicksand", sans-serif;
`;

const VoteButton = styled.button`
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 5px 10px;
  border: none;
  background: ${(props) => (props.voted ? "#8747d4" : "#f0f0f0")};
  color: ${(props) => (props.voted ? "white" : "#666")};
  border-radius: 5px;
  cursor: pointer;
  font-family: "Quicksand", sans-serif;

  &:hover {
    background: ${(props) => (props.voted ? "#7436b3" : "#e0e0e0")};
  }
`;

const FeedbackMeta = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  color: #999;
  font-size: 0.9em;
  font-family: "Quicksand", sans-serif;
`;

function FeedbackForum() {
  const [feedbackList, setFeedbackList] = useState([]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const history = useHistory();
  const userEmail = localStorage.getItem("userEmail");

  useEffect(() => {
    fetchFeedback();
  }, []);

  const fetchFeedback = async () => {
    try {
      const response = await api.get("/getFeedback");
      setFeedbackList(response.data);
    } catch (error) {
      console.error("Error fetching feedback:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await api.post("/submitFeedback", {
        userEmail,
        title,
        description,
      });
      setTitle("");
      setDescription("");
      fetchFeedback();
    } catch (error) {
      console.error("Error submitting feedback:", error);
    }
  };

  const handleVote = async (feedbackId) => {
    try {
      await api.post("/voteFeedback", {
        feedbackId,
        userEmail,
      });
      fetchFeedback();
    } catch (error) {
      console.error("Error voting:", error);
    }
  };

  return (
    <>
      <Header>
        <BackButton onClick={() => history.goBack()}>
          <FaArrowLeft />
        </BackButton>
        <p>Requests</p>
      </Header>

      <Container>
        <FeedbackForm onSubmit={handleSubmit}>
          <Input
            type="text"
            placeholder="Request title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
          <Textarea
            placeholder="Describe what you'd like to see..."
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          />
          <SolidButton type="submit" className="primary">
            SUBMIT REQUEST
          </SolidButton>
        </FeedbackForm>

        {feedbackList.map((feedback) => (
          <FeedbackCard key={feedback._id}>
            <FeedbackTitle>{feedback.title}</FeedbackTitle>
            <FeedbackDescription>{feedback.description}</FeedbackDescription>
            <FeedbackMeta>
              <VoteButton
                voted={feedback.votes.includes(userEmail)}
                onClick={() => handleVote(feedback._id)}
              >
                <FaArrowUp />
                {feedback.voteCount}
              </VoteButton>
              <span>{new Date(feedback.timestamp).toLocaleDateString()}</span>
            </FeedbackMeta>
          </FeedbackCard>
        ))}
      </Container>
    </>
  );
}

export default FeedbackForum;
