import React, { useEffect } from "react";
import HHLogo from "./HHLogo.png";
import HamburgerButton from "./HamburgerButton";
import styled, { keyframes, css } from "styled-components";
import Sidenav from "./Sidenav";
import BackgroundPage from "./BackgroundPage";
import { useState } from "react";
import { Transition, TransitionGroup } from "react-transition-group";
import {
  AmazonContextProvider,
  useAmazonContext,
} from "../../Contexts/AmazonContext";
import { Link } from "react-router-dom";
import axios from "axios";
import api from "../api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import Logout from "../userAuth/Logout";

const Navbar = styled.nav`
  /* background: #dd6260; */
  background: #a66ff0;
  /* top: 80; */
  height: 6vh;
  min-height: 50px;
  transform: translateY(${(props) => props.navTop + "vh"});
  transition: 0.2s;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ToggleSwitch = styled.label`
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #eb8381;
    transition: 0.4s;
    border-radius: 34px;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
  }

  input:checked + .slider {
    background-color: #b0240f;
  }

  input:checked + .slider:before {
    transform: translateX(26px);
    background-color: white;
  }
`;

const MenuButton = styled.button`
  padding: 0.5rem 1.2rem;
  border-radius: 12px;
  font-size: 0.9rem;
  font-weight: bold;
  cursor: pointer;
  font-family: "Quicksand", sans-serif;
  background: #8747d4;
  color: white;
  border: none;
  box-shadow: 0 3px 0 #6535a3;
  position: relative;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin: 8px;
  height: fit-content;
  min-height: 36px;
  transition: transform 0.01s ease;

  &:hover {
    background: #9859e0;
  }

  &:active {
    top: 3px;
    box-shadow: 0 0 0 #6535a3;
  }
`;

export default function LessonMainSideNav(props) {
  const [topicNumbersListfromBackEnd, setTopicNumbersListfromBackEnd] =
    useState([]);
  // const [entryStore, setEntryStore] = useState([]);
  const {
    courseTitleUserIsOn,
    entryStore,
    setEntryStore,
    courseDetails,
    setSubContainerEntries,
    subContainer,
    setSubContainer,
    rowClicked,
    setRowClicked,
    isDarkMode,
    setIsDarkMode,
    navOpen,
    setNavOpen,
    toggleMode,
  } = useAmazonContext();
  console.log(courseTitleUserIsOn, courseDetails, entryStore);

  //This use Effect is needed incase someone lands onto the topic page first, (we are loading sidebar data on the landing page, but need it for the topic page too)
  useEffect(() => {
    api
      .get(
        `/${courseTitleUserIsOn}/sideBarData?collectionName=${courseDetails.courseTopicsCollectionName}&modelName=${courseDetails.courseTopicsModelName}`
      )
      .then((response) => {
        setEntryStore(response.data);
        console.log(response.data);
      });
  }, [courseDetails]);
  console.log(
    `/api/${courseTitleUserIsOn}/sideBarData?collectionName=${courseDetails.courseTopicsCollectionName}&modelName=${courseDetails.courseTopicsModelName}`
  );
  console.log(entryStore);

  const [currentUrlState, setCurrentUrlState] = useState("0");
  const [currentTopicNumberState, setCurrentTopicNumberState] = useState("0");
  const [nextTopicNumberState, setNextTopicNumberState] = useState("0");
  const [previousTopicNumberState, setPreviousTopicNumberState] = useState("0");

  //Code for nav to hide scroll down and show on scroll up
  const [navTop, setNavTop] = useState(0);
  var lastScrollY = window.scrollY;
  window.addEventListener("scroll", function () {
    if (lastScrollY < window.scrollY) {
      setNavTop(-6); //needs to be same as Nav Height defined above
    } else {
      setNavTop(0);
    }
    lastScrollY = window.scrollY;
  });
  //End

  // In your openNav and closeNav functions, update to use window.setNavOpen if it exists
  const openNav = () => {
    if (window.setNavOpen) {
      window.setNavOpen(true);
    }
    setNavOpen(true);
  };

  const closeNav = () => {
    if (window.setNavOpen) {
      window.setNavOpen(false);
    }
    setNavOpen(false);
    // Force the Transition to update
    if (document.querySelector(".overlay")) {
      document.querySelector(".overlay").click();
    }
  };

  // Add useEffect to handle back button from React Native
  useEffect(() => {
    const handleBackButton = () => {
      if (navOpen) {
        closeNav();
      }
    };

    // Listen for messages from React Native
    const handleMessage = (event) => {
      if (
        event.data === "closeNav" ||
        (typeof event.data === "object" &&
          event.data.type === "setNavOpen" &&
          !event.data.value)
      ) {
        handleBackButton();
      }
    };

    window.addEventListener("message", handleMessage);

    // Cleanup
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [navOpen]);

  return (
    <div style={{ display: "unset" }}>
      <Navbar
        style={{
          position: "sticky",
          top: 0,
          zIndex: 10000,
        }}
        navTop={navTop}
      >
        <div>
          <MenuButton onClick={openNav}>Course Menu</MenuButton>
        </div>
        <div style={{ marginRight: "20px" }}>
          <ToggleSwitch>
            <input type="checkbox" checked={isDarkMode} onChange={toggleMode} />
            <span className="slider"></span>
          </ToggleSwitch>
        </div>
      </Navbar>

      <Transition
        in={navOpen && entryStore}
        timeout={300}
        mountOnEnter
        unmountOnExit
      >
        {(state) => (
          <>
            <Sidenav state={state} click={closeNav} />
            <div
              className="overlay"
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                background: "rgba(0,0,0,0.5)",
                zIndex: 9999,
                display: state === "entered" ? "block" : "none",
              }}
              onClick={closeNav}
            />
          </>
        )}
      </Transition>
    </div>
  );
}
