import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import api from "../api";
import Header from "../Header";
import BottomNavBar from "./BottomNavBar";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { useHistory } from "react-router-dom";
import SolidButton from "../SolidButton";

const SelectContainer = styled.div`
  position: relative;
  min-width: 200px;
`;

const SearchInput = styled.input`
  width: 100%;
  height: 50px;
  padding: 15px 20px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.2);
  cursor: pointer;
  font-size: 16px;

  &:focus {
    outline: none;
    border-color: rgba(255, 255, 255, 0.5);
  }

  &::placeholder {
    color: rgba(255, 255, 255, 0.7);
  }
`;

const DropdownList = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  max-height: 300px;
  overflow-y: auto;
  background-color: #6535a3;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  margin-top: 5px;
  z-index: 1000;
`;

const DropdownItem = styled.div`
  padding: 15px 20px;
  cursor: pointer;
  color: white;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }

  ${(props) =>
    props.selected &&
    `
    background-color: rgba(255, 255, 255, 0.2);
  `}
`;

const TableHeader = styled.th`
  padding: 10px;
  text-align: ${(props) => props.align || "left"};
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  cursor: pointer;
  user-select: none;
  position: relative;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }

  &:after {
    content: "${(props) =>
      props.sortDirection === "asc"
        ? " ↑"
        : props.sortDirection === "desc"
        ? " ↓"
        : ""}";
    position: absolute;
    right: 8px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 10px 0;
  padding: 0 10px;
`;

function UserLessonsDetail() {
  const [userLessons, setUserLessons] = useState([]);
  const [selectedUser, setSelectedUser] = useState("all");
  const [uniqueUsers, setUniqueUsers] = useState([]);
  const [graphData, setGraphData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const dropdownRef = useRef(null);
  const [sortConfig, setSortConfig] = useState({
    key: "timestamp",
    direction: "desc",
  });
  const history = useHistory();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userLessonsResponse = await api.get("/dailyUserLessons");
        const data = userLessonsResponse.data;
        setUserLessons(data);

        // Get unique users
        const users = [...new Set(data.map((record) => record.userEmail))].map(
          (email) => {
            const user = data.find((record) => record.userEmail === email);
            return {
              email: user.userEmail,
              name: `${user.givenName} ${user.familyName || ""}`,
            };
          }
        );
        setUniqueUsers(users);

        // Process initial graph data (all users)
        processGraphData(data, "all");
      } catch (error) {
        console.error("Error fetching user lessons data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const processGraphData = (data, selectedEmail) => {
    // Filter for selected user first if not "all"
    const filteredData =
      selectedEmail === "all"
        ? data
        : data.filter((record) => record.userEmail === selectedEmail);

    // Group by date string from the date field
    const groupedData = filteredData.reduce((acc, record) => {
      const [year, month, day] = record.date.split("-");
      const dateObj = new Date(year, month - 1, day);
      const date = dateObj.toLocaleDateString("en-US", {
        weekday: "short",
        month: "short",
        day: "numeric",
      });

      if (!acc[date]) {
        acc[date] = {
          date,
          lessons: record.lessonsCompleted || 0,
        };
      } else if (selectedEmail === "all") {
        acc[date].lessons += record.lessonsCompleted || 0;
      }

      return acc;
    }, {});

    // Convert to array and sort by date
    const sortedData = Object.values(groupedData).sort((a, b) => {
      const [aMonth, aDay] = a.date.split(" ");
      const [bMonth, bDay] = b.date.split(" ");
      const aDate = new Date(2025, getMonthNumber(aMonth), parseInt(aDay));
      const bDate = new Date(2025, getMonthNumber(bMonth), parseInt(bDay));
      return aDate - bDate;
    });

    setGraphData(sortedData);
  };

  // Helper function to convert month abbreviation to number (0-based)
  const getMonthNumber = (monthAbbr) => {
    const months = {
      Jan: 0,
      Feb: 1,
      Mar: 2,
      Apr: 3,
      May: 4,
      Jun: 5,
      Jul: 6,
      Aug: 7,
      Sep: 8,
      Oct: 9,
      Nov: 10,
      Dec: 11,
    };
    return months[monthAbbr];
  };

  const handleSelect = (email) => {
    setSelectedUser(email);
    processGraphData(userLessons, email);
    setIsOpen(false);
    setSearchTerm("");
  };

  const getSelectedUserName = () => {
    if (selectedUser === "all") return "All Users";
    const user = uniqueUsers.find((u) => u.email === selectedUser);
    return user ? user.name : "All Users";
  };

  const filteredUsers = [
    { email: "all", name: "All Users" },
    ...uniqueUsers,
  ].filter((user) =>
    user.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const sortData = (data, key, direction) => {
    return [...data].sort((a, b) => {
      let aValue, bValue;

      switch (key) {
        case "timestamp":
          aValue = new Date(a.timestamp);
          bValue = new Date(b.timestamp);
          break;
        case "name":
          aValue = `${a.givenName} ${a.familyName || ""}`.toLowerCase();
          bValue = `${b.givenName} ${b.familyName || ""}`.toLowerCase();
          break;
        case "lessonsCompleted":
          aValue = a.lessonsCompleted;
          bValue = b.lessonsCompleted;
          break;
        default:
          aValue = a[key];
          bValue = b[key];
      }

      if (aValue < bValue) return direction === "asc" ? -1 : 1;
      if (aValue > bValue) return direction === "asc" ? 1 : -1;
      return 0;
    });
  };

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const getSortDirection = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction;
    }
    return null;
  };

  // Filter and sort the data
  const getFilteredAndSortedData = () => {
    const filtered = userLessons.filter(
      (record) => selectedUser === "all" || record.userEmail === selectedUser
    );

    // Format the dates consistently with the graph
    const formattedData = filtered.map((record) => {
      const [year, month, day] = record.date.split("-");
      const dateObj = new Date(year, month - 1, day);

      return {
        ...record,
        formattedDate: dateObj.toLocaleDateString("en-US", {
          month: "numeric",
          day: "numeric",
          year: "numeric",
        }),
      };
    });

    return sortData(formattedData, sortConfig.key, sortConfig.direction);
  };

  const handleTopPerformerClick = (e) => {
    e.preventDefault();
    setTimeout(() => {
      history.push("/daily-top-performer");
    }, 150); // 150ms delay to match other button animations
  };

  return (
    <>
      <Container>
        <ButtonContainer>
          <SolidButton onClick={handleTopPerformerClick} className="primary">
            View Daily Top Performers
          </SolidButton>
        </ButtonContainer>
        <DetailCard>
          <CardTitle>Daily User Lesson Completions</CardTitle>
          <CardSubtitle>Recorded daily at 11:59 PM ET</CardSubtitle>

          <FilterContainer>
            <SelectContainer ref={dropdownRef}>
              <SearchInput
                type="text"
                placeholder={getSelectedUserName()}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                onClick={() => setIsOpen(true)}
              />
              {isOpen && (
                <DropdownList>
                  {filteredUsers.map((user) => (
                    <DropdownItem
                      key={user.email}
                      selected={user.email === selectedUser}
                      onClick={() => handleSelect(user.email)}
                    >
                      {user.name}
                    </DropdownItem>
                  ))}
                </DropdownList>
              )}
            </SelectContainer>
          </FilterContainer>

          <div style={{ width: "100%", height: 300 }}>
            <ResponsiveContainer>
              <LineChart
                data={graphData}
                margin={{
                  top: 30,
                  right: 15,
                  left: 0,
                  bottom: 40,
                }}
              >
                <CartesianGrid
                  strokeDasharray="3 3"
                  stroke="rgba(255,255,255,0.1)"
                />
                <XAxis
                  dataKey="date"
                  stroke="rgba(255,255,255,0.8)"
                  tick={{ fill: "rgba(255,255,255,0.8)", fontSize: 11 }}
                  angle={-45}
                  textAnchor="end"
                  height={60}
                  interval={0}
                  padding={{ left: 10, right: 10 }}
                />
                <YAxis
                  stroke="rgba(255,255,255,0.8)"
                  tick={{ fill: "rgba(255,255,255,0.8)" }}
                  domain={["dataMin - 25", "dataMax + 25"]}
                />
                <Tooltip
                  contentStyle={{
                    backgroundColor: "rgba(255,255,255,0.9)",
                    border: "none",
                    borderRadius: "4px",
                    color: "#6535a3",
                  }}
                />
                <Line
                  type="monotone"
                  dataKey="lessons"
                  stroke="#fff"
                  strokeWidth={2}
                  dot={{ fill: "#fff", strokeWidth: 2 }}
                  activeDot={{ r: 8 }}
                  label={{
                    position: "top",
                    fill: "#fff",
                    fontSize: 12,
                  }}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>

          <TableContainer>
            <Table>
              <thead>
                <TableRow>
                  <TableHeader
                    onClick={() => handleSort("timestamp")}
                    sortDirection={getSortDirection("timestamp")}
                  >
                    Date
                  </TableHeader>
                  <TableHeader
                    onClick={() => handleSort("name")}
                    sortDirection={getSortDirection("name")}
                  >
                    Name
                  </TableHeader>
                  <TableHeader
                    align="right"
                    onClick={() => handleSort("lessonsCompleted")}
                    sortDirection={getSortDirection("lessonsCompleted")}
                  >
                    Lessons
                  </TableHeader>
                </TableRow>
              </thead>
              <tbody>
                {getFilteredAndSortedData().map((record, index) => (
                  <TableRow key={`${record.userEmail}-${record.date}-${index}`}>
                    <TableCell>{record.formattedDate}</TableCell>
                    <TableCell>
                      {`${record.givenName} ${record.familyName || ""}`}
                    </TableCell>
                    <TableCell align="right">
                      {record.lessonsCompleted}
                    </TableCell>
                  </TableRow>
                ))}
              </tbody>
            </Table>
          </TableContainer>
        </DetailCard>
        <div style={{ height: "60px" }} />
      </Container>
      <BottomNavBar />
    </>
  );
}

const Container = styled.div`
  padding: 10px;
  max-width: 100%;
  width: 100%;
`;

const DetailCard = styled.div`
  background-color: #6535a3;
  border-radius: 10px;
  padding: 15px 0px;
  margin-bottom: 20px;
  width: 100%;
`;

const CardTitle = styled.h3`
  font-family: "Quicksand", sans-serif;
  color: rgba(255, 255, 255, 0.9);
  margin: 0 0 15px 0;
  font-size: 1.1rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  text-align: center;
`;

const CardSubtitle = styled.div`
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.8rem;
  margin-top: -10px;
  margin-bottom: 10px;
  text-align: center;
`;

const TableContainer = styled.div`
  width: 100%;
  overflow-x: auto;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  color: white;
`;

const TableRow = styled.tr`
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
`;

const TableCell = styled.td`
  padding: 10px;
  text-align: ${(props) => props.align || "left"};
`;

const FilterContainer = styled.div`
  margin: 25px 0;
  display: flex;
  justify-content: center;
`;

export default UserLessonsDetail;
