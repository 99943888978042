import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import api from "../api";
import HHLogo from "../sidebar/logo.svg";
import BottomNavBar from "./BottomNavBar";
import { useAmazonContext } from "../../Contexts/AmazonContext";
import LottieAnimation from "../LottieAnimation";

const LeaderboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  max-width: 600px;
  margin: 0 auto;
  height: 90vh;
  box-sizing: border-box;
`;

const Header = styled.div`
  text-align: center;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Logo = styled.img`
  width: 120px;
  height: 120px;
  margin-bottom: 15px;
  background-color: #222222;
  border-radius: 10px;
  padding: 10px;
`;

const Title = styled.h1`
  font-size: 24px;
  color: #333;
  margin: 10px 0;
`;

const LeadersList = styled.div`
  flex: 1;
  background: white;
  border-radius: 15px;
  overflow-y: auto;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;

const LeaderItem = styled(motion.div)`
  display: flex;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid #eee;
  background: ${(props) => {
    if (props.rank === 1)
      return "linear-gradient(135deg, #ffd700 0%, #fff6a8 50%, #ffd700 100%)";
    if (props.rank === 2)
      return "linear-gradient(135deg, #C0C0C0 0%, #e8e8e8 50%, #C0C0C0 100%)";
    if (props.rank === 3)
      return "linear-gradient(135deg, #CD7F32 0%, #ffa96b 50%, #CD7F32 100%)";
    return "white";
  }};

  &:last-child {
    border-bottom: none;
  }
`;

const Rank = styled.div`
  width: 40px;
  font-weight: bold;
  font-size: 24px;
  color: ${(props) => {
    if (props.rank === 1) return "#806000";
    if (props.rank === 2) return "#707070";
    if (props.rank === 3) return "#804000";
    return "#333";
  }};
`;

const UserInfo = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 15px;
  gap: 10px;
  min-width: 0;
`;

const NameContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  min-width: 0;
  flex: 1;
  overflow: visible;
`;

const Name = styled.div`
  font-weight: 600;
  font-size: clamp(12px, 4vw, 20px);
  color: ${(props) => (props.rank <= 3 ? "#000" : "#333")};
  display: flex;
  flex-direction: column;
  line-height: 1.2;

  div:first-child {
    text-transform: capitalize;
  }

  div:last-child {
    text-transform: capitalize;
  }
`;

const LessonCount = styled.div`
  font-weight: 600;
  font-size: 20px;
  color: ${(props) => (props.rank <= 3 ? "#000" : "#666")};
  white-space: nowrap;
  flex-shrink: 0;
  padding-left: 8px;
  align-self: center;
`;

const YouBadge = styled.div`
  background-color: #8747d4;
  color: white;
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: bold;
  flex-shrink: 0;
`;

const ShineEffect = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.5),
    transparent
  );
  pointer-events: none;
`;

const beatAnimation = {
  scale: [1, 1.02, 1],
  transition: {
    duration: 1.2,
    repeat: Infinity,
    ease: "easeInOut",
    times: [0, 0.5, 1],
  },
};

const entryAnimation = {
  opacity: 1,
  y: 0,
};

// Define a styled button similar to SolidButton
const StyledButton = styled.button`
  padding: 1rem 2rem;
  border-radius: 16px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  font-family: "Quicksand", sans-serif;
  width: 360px;
  max-width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  transition: transform 0.01s ease;
  background: #8747d4;
  color: white;
  border: none;
  box-shadow: 0 4px 0 #6535a3;
  position: relative;
  top: 0;

  &:hover {
    background: #9859e0;
  }

  &:active {
    top: 4px;
    box-shadow: 0 0 0 #6535a3;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const calculateRanks = (data) => {
  let currentRank = 0;
  let previousScore = null;

  return data.map((user) => {
    if (user.completedLessons !== previousScore) {
      currentRank++;
    }
    previousScore = user.completedLessons;
    return { ...user, rank: currentRank };
  });
};

// Add this styled component with the other styled components
const LottieContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: #fff;

  > div {
    width: 100%;
    max-width: 300px;

    @media only screen and (min-width: 601px) {
      max-width: 50%;
    }
  }
`;

function Leaderboard() {
  const { isDarkMode } = useAmazonContext();
  const [leaderboardData, setLeaderboardData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const currentUserEmail = localStorage.getItem("userEmail");
  const userRefs = useRef([]);

  useEffect(() => {
    const fetchLeaderboardData = async () => {
      try {
        const response = await api.get("/leaderboard");
        const rankedData = calculateRanks(response.data);
        setLeaderboardData(rankedData);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching leaderboard data:", error);
        setIsLoading(false);
      }
    };

    fetchLeaderboardData();
  }, []);

  const scrollToCurrentUser = () => {
    const currentUserIndex = leaderboardData.findIndex(
      (user) => user.userEmail === currentUserEmail
    );
    if (currentUserIndex !== -1 && userRefs.current[currentUserIndex]) {
      userRefs.current[currentUserIndex].scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };

  if (isLoading) {
    return (
      <div>
        <LottieContainer>
          <LottieAnimation
            lottieFile="https://hhcourses-assets.s3.us-east-2.amazonaws.com/General/LottieFiles/loading.json"
            loop={true}
          ></LottieAnimation>
        </LottieContainer>
        <BottomNavBar></BottomNavBar>
      </div>
    );
  }

  return (
    <LeaderboardContainer>
      <Header>
        <Logo src={HHLogo} alt="Quranic" />
        <Title style={{ color: isDarkMode ? "white" : "#333" }}>
          Leaderboard
        </Title>
        <StyledButton onClick={scrollToCurrentUser}>
          GO TO MY POSITION
        </StyledButton>
      </Header>

      <LeadersList>
        {leaderboardData.map((user, index) => {
          const isCurrentUser = user.userEmail === currentUserEmail;
          return (
            <LeaderItem
              key={user.userEmail}
              rank={user.rank}
              ref={(el) => (userRefs.current[index] = el)}
              initial={{ opacity: 0 }}
              animate={{
                opacity: 1,
                scale: isCurrentUser ? beatAnimation.scale : 1,
              }}
              transition={{
                duration: 0.3,
                scale: isCurrentUser
                  ? {
                      duration: 1.2,
                      repeat: Infinity,
                      ease: "easeInOut",
                      times: [0, 0.5, 1],
                    }
                  : undefined,
              }}
              style={{
                position: "relative",
                overflow: "hidden",
                boxShadow: isCurrentUser
                  ? "0 0 10px rgba(135, 71, 212, 0.3)"
                  : "none",
                transformOrigin: "center center",
              }}
            >
              <Rank rank={user.rank}>{user.rank}</Rank>
              <UserInfo>
                <NameContainer>
                  <Name rank={user.rank}>
                    <div>{user.givenName}</div>
                    <div>{user.familyName}</div>
                  </Name>
                  {isCurrentUser && <YouBadge>YOU</YouBadge>}
                </NameContainer>
                <LessonCount rank={user.rank}>
                  {user.completedLessons} Lessons
                </LessonCount>
              </UserInfo>
              {index < 3 && (
                <ShineEffect
                  initial={{ x: "-100%" }}
                  animate={{ x: "100%" }}
                  transition={{
                    duration: 1.5,
                    repeat: Infinity,
                    repeatDelay: 3,
                    ease: "easeInOut",
                  }}
                />
              )}
            </LeaderItem>
          );
        })}
      </LeadersList>
      <BottomNavBar />
    </LeaderboardContainer>
  );
}

export default Leaderboard;
