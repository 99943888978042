import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import "./LogoutMessage.css";

const LogoutMessage = ({ setIsLoggedIn }) => {
  const history = useHistory();

  useEffect(() => {
    // Redirect to the home page after 2 seconds
    const timeoutId = setTimeout(() => {
      // Push the home route to navigate to it
      history.push("/home");
      // Set isLoggedIn to false after redirecting
      setIsLoggedIn(false);
      // Clear all items from local storage
      localStorage.clear();
    }, 2000);

    // Clean up the timeout when the component unmounts
    return () => {
      clearTimeout(timeoutId);
    };
  }, [history, setIsLoggedIn]);

  return (
    <div className="message-container">
      <h1>
        You have logged out successfully! Redirecting you to the Login Page
        again...
      </h1>
    </div>
  );
};

export default LogoutMessage;
