import React, { useState, useContext, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import TopicData, { allExampleData } from "./TopicData";
import QuizExample from "./QuizExample";
import ScoreCard from "./ScoreCard";
import { ScoreContext, SubmitButtonContext } from "../Contexts/ScoreContext";
import { ButtonH1 } from "./Buttons";
import styled from "styled-components";
import { useAmazonContext } from "../Contexts/AmazonContext";
import axios from "axios";
import api from "./api";
import QuizMainSideNav from "./quiz sidebar/QuizMainSideNav";
import { Link } from "react-router-dom";
import LessonMainSideNav from "./sidebar/LessonMainSideNav";
import ConfettiExplosion from "react-confetti-explosion";
import QuizProgressBar from "./QuizProgressBar";
import { motion, AnimatePresence } from "framer-motion";
import ColorThief from "colorthief";
import ReactHtmlParser from "react-html-parser";
import SideNavRowLesson from "./sidebar/SideNavRowLesson";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-solid-svg-icons";

const MessageText = styled.div`
  font-size: 3vh;
  text-align: center;
  margin: 20px;
  font-family: "Quicksand", sans-serif;
`;

const FullScreenMessage = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const QuizTitle = styled.p`
  color: #eb8381;
  /* font-size: 3vh; */
  font-family: "Quicksand", sans-serif;
  justify-content: center;
  text-align: center;
  /* margin: 30px 30px; */
  display: flex;

  /* justify-content: space-between; */
`;

const CenteredButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const StudyModeButton = styled.button`
  background: red;
  font-family: "Quicksand", sans-serif;
  padding: 7px 12px;
  margin: 20px;
  text-transform: uppercase;
  /* font-size: 15px; */
  font-size: 3vh;
  overflow: hidden;
  border: 0;
  border-radius: 5px;
  background: #eb3535;
  color: white;
  transition: all 0.25s ease;
  cursor: pointer;

  &:active {
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.5);
    background: #f2b1ae;
  }
`;

const QuizSolidButton = styled.button`
  font-family: "Quicksand", sans-serif;
  padding: 1rem 2rem;
  font-size: 1rem;
  width: 360px;
  max-width: 95%;
  border: 0;
  border-radius: 16px;
  font-weight: bold;
  color: white;
  transition: transform 0.01s ease;
  cursor: pointer;
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0 4px 0 ${(props) => props.shadowColor};
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  &:hover {
    filter: brightness(110%);
  }

  &:active {
    transform: translateX(-50%) translateY(4px);
    box-shadow: none;
  }

  @media (max-width: 768px) {
    width: 90%;
    font-size: 1.2rem;
    padding: 0.8rem 1.6rem;
  }
`;

const TopicTitle = styled.div`
  color: #9589be;
  /* font-size: 1rem; */
  /* font-size: clamp(4vh, 30px, 30px); */
  /* font-size: min(2rem, 4vh); */
  font-size: 4vh;
  display: block;
  margin: 30px;
  font-family: "Quicksand", sans-serif;

  /* @media (max-width: 40em) {
    font-size: 5vh;
  } */

  /* justify-content: space-between; */
`;

const StatusContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
  margin-bottom: 0;
  padding: 0 20px;
`;

const ProgressBarContainer = styled.div`
  flex-grow: 1;
  width: 90%;
  /* margin-right: 20px; */
`;

const HeartContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 35px;
  color: red;
  width: 10%;
`;

const HeartIcon = styled(FontAwesomeIcon)`
  width: 80%;
`;

const HeartCount = styled.span`
  font-size: 24px;
  margin-left: 5px;
  color: red;
  font-weight: bold;
  width: 20%;
`;

const Heart = styled.span`
  font-size: 40px;
  color: ${(props) => (props.filled ? "red" : "gray")};
  margin: 0 5px;
`;

const StickyContainer = styled.div`
  position: sticky;
  top: 0;
  z-index: 100;
  padding: 10px 0;
  padding-bottom: 0;
`;

const AnimatedQuizContainer = styled(motion.div)`
  width: 100%;
`;

const ExampleQuestion = styled.div`
  /* font-size: 17px; */
  font-size: 2.5vh;
  font-weight: bold;
  text-align: center;
  color: #a678de;
`;

const Quiz = (props) => {
  //set the courseTitle for whatever course the User is on here as well as course landing page in case a user is lands onto a topic page directly
  let {
    courseTitleUserIsOn,
    setCourseTitleUserIsOn,
    courseDetails,
    setCourseDetails,
    entryStore,
    setEntryStore,
    setSubContainerEntries,
    setSubContainer,
    navOpen,
  } = useAmazonContext();

  console.log(courseDetails);
  useEffect(() => {
    setCourseTitleUserIsOn(props.match.params.courseTitle);
  }, []);
  console.log(courseTitleUserIsOn);
  console.log(props.match.params.courseTitle);

  console.log(courseDetails);

  const [submitted, setSubmitted] = useState(false);
  const [sumCorrect, setSumCorrect] = useState([]);

  const [whereDidIComeFrom, setWhereDidIComeFrom] = useState("none");
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [wrongQuestions, setWrongQuestions] = useState([]);
  const [showingWrongQuestions, setShowingWrongQuestions] = useState(false);
  const [quizCompleted, setQuizCompleted] = useState(false);
  const location = useLocation(); //Props obtained from Router Link
  const [answerSelected, setAnswerSelected] = useState(false);
  const [wrongQuestionIndex, setWrongQuestionIndex] = useState(0);
  const [correctedWrongQuestions, setCorrectedWrongQuestions] = useState([]);
  const [remainingWrongQuestions, setRemainingWrongQuestions] = useState([]);
  const [resetTrigger, setResetTrigger] = useState(0);
  const [lives, setLives] = useState(3);
  const [showFullScreenMessage, setShowFullScreenMessage] = useState(false);
  const checkSubmission = () => setSubmitted(true);
  const quizContainerRef = useRef(null);
  // Add refs for audio elements
  const correctSound = useRef(
    new Audio(
      "https://hhcourses-assets.s3.us-east-2.amazonaws.com/General/Audio/success11.mp3"
    )
  );
  const incorrectSound = useRef(
    new Audio(
      "https://hhcourses-assets.s3.us-east-2.amazonaws.com/General/Audio/wrongAnswer.mp3"
    )
  );
  const quizCompletedSound = useRef(
    new Audio(
      "https://hhcourses-assets.s3.us-east-2.amazonaws.com/General/Audio/chapterComplete.mp3"
    )
  );

  useEffect(() => {
    // Preload the audio files
    correctSound.current.load();
    incorrectSound.current.load();
    quizCompletedSound.current.load();
  }, []);

  useEffect(() => {
    if (quizCompleted) {
      quizCompletedSound.current.play();
    }
  }, [quizCompleted]);

  useEffect(() => window.scrollTo({ top: 0, behavior: "smooth" }), [submitted]);

  // console.log(sumCorrect);

  console.log(whereDidIComeFrom);
  console.log(location);
  console.log(location.state);
  // console.log(location.state.comingFrom);

  //set the course Details
  useEffect(() => {
    api.get(`/coursesData`).then((response) => {
      console.log(response.data);
      let allCourses = response.data;
      let courseChosen = allCourses.find(
        (o) =>
          o.courseTitle.replace(/\s/g, "") === props.match.params.courseTitle
      );
      setCourseDetails(courseChosen);
    });
  }, [courseTitleUserIsOn]);
  console.log(courseDetails);

  //Topic to be displayed
  console.log(props.match);
  console.log(props.match.params.topicNumber);

  let topicNumberFromSideBar = props.match.params.topicNumber;
  console.log(topicNumberFromSideBar);
  console.log(courseDetails);
  //Examples Data from BackEnd
  const [exampleDatafromBackEnd, setExampleDatafromBackEnd] = useState([]);

  useEffect(() => {
    api
      .get(
        `/examplesData?collectionName=${courseDetails.courseExamplesCollectionName}&modelName=${courseDetails.courseExamplesModelName}`
      )
      .then((response) => {
        setExampleDatafromBackEnd(response.data);
        console.log(response.data);
      });
  }, [courseDetails]);

  // let { exampleDatafromBackEnd } = useAmazonContext();
  console.log(exampleDatafromBackEnd);

  // // Finding quiz questions algorithm starts here

  //Topic Data from BackEnd
  const [selectedTopic, setSelectedTopic] = useState([]);

  console.log(courseTitleUserIsOn);

  //Set the selected topic
  useEffect(() => {
    api
      .get(
        `/${courseTitleUserIsOn}/topicRESTCAll/${topicNumberFromSideBar}?collectionName=${courseDetails.courseTopicsCollectionName}&modelName=${courseDetails.courseTopicsModelName}`
      )
      .then((response) => {
        setSelectedTopic(response.data);
        console.log(response.data);
      });
  }, [topicNumberFromSideBar, courseDetails]);

  // let selectedTopic = topicDatafromBackEnd.find(
  //   (o) => o.topicNumber === topicNumberFromSideBar
  // );

  console.log(selectedTopic);
  //End

  //This use Effect is needed incase someone lands onto the topic page first, (we are loading sidebar data on the landing page, but need it for the topic page too)
  useEffect(() => {
    api
      .get(
        `/${courseTitleUserIsOn}/sideBarData?collectionName=${courseDetails.courseTopicsCollectionName}&modelName=${courseDetails.courseTopicsModelName}`
      )
      .then((response) => {
        setEntryStore(response.data);
        console.log(response.data);
      });
  }, [selectedTopic.topicNumber]);
  console.log(entryStore);

  //Logic to open the Chapter Lessons Menu subentry (on the subcontainer in the side bar) related to the lesson the user is on

  console.log(entryStore);
  useEffect(() => {
    let nextSectionNumber = null;
    let nextSectionTitle = null;
    let foundCurrentSection = false;
    // Example usage:
    const currentChapterNumber = selectedTopic.topicNumber;

    if (entryStore) {
      //Needed to wait for entryStore to load
      // Iterate through the entryStore data
      for (const topic of entryStore) {
        for (const entry of topic.entries) {
          for (const subEntry of entry.entries) {
            // Check if the section number matches the currentSectionNumber
            if (subEntry.topicNumber === currentChapterNumber) {
              foundCurrentSection = true;

              setSubContainerEntries(entry.entries);
              setSubContainer(true);
              break;
            }
          }
          if (foundCurrentSection) break;
        }
        if (foundCurrentSection) break;
      }
    }
  }, [navOpen, courseDetails, selectedTopic]);

  //End

  //function to find next chapter number and title along with the first lesson in that chapter
  //Start
  function findNextTopic(currentTopicNumber) {
    let foundCurrentTopic = false;

    if (entryStore) {
      // Iterate through the entryStore data
      for (const topicHeader of entryStore) {
        for (const topic of topicHeader.entries) {
          // Check if the topic number matches the currentTopicNumber
          if (topic.topicNumber === currentTopicNumber) {
            foundCurrentTopic = true;
            console.log(currentTopicNumber);

            // Find the index of the current topic in the topicHeader
            const currentIndex = topicHeader.entries.findIndex(
              (topic) => topic.topicNumber === currentTopicNumber
            );
            console.log(currentIndex);

            // Find the index of the current topicHeader

            console.log(topicHeader.title);

            const currentTopicHeaderIndex = entryStore.findIndex(
              (entry) =>
                entry.topicHeaderNumber ===
                String(Math.floor(parseFloat(currentTopicNumber)))
            );
            console.log(entryStore);
            console.log(Math.floor(parseFloat(currentTopicNumber)));
            console.log(topicHeader.topicHeaderNumber, currentTopicHeaderIndex);

            // If the current topic is not the last one in the entry
            if (currentIndex !== topicHeader.entries.length - 1) {
              // Get the next topic's data
              const nextTopic = topicHeader.entries[currentIndex + 1];
              return nextTopic;
            } else if (currentIndex == topicHeader.entries.length - 1) {
              // Get the next topicHeaders first chapter
              if (currentTopicHeaderIndex !== entryStore.length - 1) {
                const nextTopic =
                  entryStore[currentTopicHeaderIndex + 1].entries[0];
                return nextTopic;
              }
            }
            break;
          }
        }
        if (foundCurrentTopic) break;
      }
    }

    if (!foundCurrentTopic) {
      console.log("Current topic not found.");
    }

    return null;
  }

  // Example usage:
  const currentTopicNumber = topicNumberFromSideBar; // Assuming this is the last topic
  const nextTopic = findNextTopic(currentTopicNumber, entryStore);

  var nextChapterTitle = "";
  var nextChapterNumber = "";
  var nextChapterFirstLessonNumber = "";
  var nextChapterFirstLessonTitle = "";
  var nextChapterFirstLessonImage = "";

  if (nextTopic) {
    console.log("Next Chapter Number:", nextTopic.entries[0].topicNumber);
    console.log("Next Chapter Title:", nextTopic.entries[0].title);
    console.log(
      "Next Chapter First Lesson Number:",
      nextTopic.entries[0].entries[0].sectionNumber
    );
    console.log(
      "Next Chapter First Lesson Title:",
      nextTopic.entries[0].entries[0].title
    );
    console.log(
      "Next Chapter First Lesson Image:",
      nextTopic.entries[0].entries[0].sectionImage
    );

    nextChapterNumber = nextTopic.entries[0].topicNumber;
    nextChapterTitle = nextTopic.entries[0].title;
    nextChapterFirstLessonNumber =
      nextTopic.entries[0].entries[0].sectionNumber;
    nextChapterFirstLessonTitle = nextTopic.entries[0].entries[0].title;
    nextChapterFirstLessonImage = nextTopic.entries[0].entries[0].sectionImage;
  } else {
    console.log("No next topic.");
    nextChapterNumber = false;
    nextChapterTitle = false;
    nextChapterFirstLessonNumber = false;
    nextChapterFirstLessonTitle = false;
    nextChapterFirstLessonImage = false;
  }

  //End

  const [topicNumbersListfromBackEnd, setTopicNumbersListfromBackEnd] =
    useState([]);
  useEffect(() => {
    api
      .get(
        `/${courseTitleUserIsOn}/topicNumbersList?collectionName=${courseDetails.courseTopicsCollectionName}&modelName=${courseDetails.courseTopicsModelName}`
      )
      .then((response) => {
        setTopicNumbersListfromBackEnd(response.data);
        console.log(response.data);
      });
  }, [selectedTopic.topicNumber]);
  console.log(topicNumbersListfromBackEnd);

  const sectionList = []; //Creates a list of all the sectionNumbers on this topic
  selectedTopic.sections &&
    selectedTopic.sections.map((section) => {
      return sectionList.push(section.sectionNumber);
    });

  console.log(sectionList);

  // Number of questions display properties

  // let needToDisplay = 100;
  // let needtoSelect = Math.ceil(needToDisplay / sectionList.length);
  // console.log(needtoSelect);

  let selectedSectionExamples = []; //List of selected examples from each section
  let selectedSectionExamplesPool = []; //List of all selected examples from all sections
  let newselectedSectionExamplesPool = []; //Needed this to use the concat logic because concat requires you to store the concatenation into a new variable
  // let finalSetOfExamplesToDisplay = []; //final list of examples to display

  // Algorithm for obtaining questions from each section:

  // Randomiser function to create quiz for all topics

  const chooseRandom = (arr, num) => {
    const res = [];
    for (let i = 0; i < num; ) {
      const random = Math.floor(Math.random() * arr.length);
      if (res.includes(arr[random])) {
        continue;
      }
      res.push(arr[random]);
      i++;
    }
    return res;
  };

  // New: Define the threshold X //
  //   I adjusted the quiz needtodisplay to mean:

  // if too many sections i'll keep the quiz qty to the number of sections so that there is full section coverage
  // if too less sections i'll give you more questions from the same sections.
  const QUESTION_THRESHOLD = 5; // You can adjust this value as needed

  // Updated: Use state for needToDisplay
  const [needToDisplay, setNeedToDisplay] = useState(QUESTION_THRESHOLD);

  // New: Update needToDisplay based on the number of sections
  useEffect(() => {
    if (sectionList.length > QUESTION_THRESHOLD) {
      setNeedToDisplay(sectionList.length);
    } else {
      setNeedToDisplay(QUESTION_THRESHOLD);
    }
  }, [sectionList]);

  const [finalSetOfExamplesToDisplay, setFinalSetOfExamplesToDisplay] =
    useState([]);

  // Helper function to select a random item from an array
  const getRandomItem = (array) => {
    return array[Math.floor(Math.random() * array.length)];
  };

  useEffect(() => {
    const selectQuestions = () => {
      let selectedSectionExamplesPool = [];

      // First pass: Select one random question from each section, skipping the first question if possible
      for (let sectionNumber of sectionList) {
        let allSectionExamples = exampleDatafromBackEnd.filter(
          (example) => example.sectionNumber == sectionNumber
        );

        if (allSectionExamples.length > 1) {
          // Skip the first question and select randomly from the rest
          let availableExamples = allSectionExamples.slice(1);
          let randomExample = getRandomItem(availableExamples);
          selectedSectionExamplesPool.push(randomExample);
        } else if (allSectionExamples.length === 1) {
          // If there's only one question, we have no choice but to select it
          selectedSectionExamplesPool.push(allSectionExamples[0]);
        }
        // If there are no questions for this section, we skip it
      }

      // If we need more questions to meet needToDisplay, select additional random questions
      while (selectedSectionExamplesPool.length < needToDisplay) {
        for (let sectionNumber of sectionList) {
          if (selectedSectionExamplesPool.length >= needToDisplay) break;

          let remainingExamples = exampleDatafromBackEnd.filter(
            (example) =>
              example.sectionNumber == sectionNumber &&
              !selectedSectionExamplesPool.includes(example)
          );

          // Reintroduced: Skip the first question if it hasn't been selected and there are other options
          if (remainingExamples.length > 1) {
            remainingExamples = remainingExamples.slice(1);
          }

          if (remainingExamples.length > 0) {
            let randomExample = getRandomItem(remainingExamples);
            selectedSectionExamplesPool.push(randomExample);
          }
        }
        // If we've gone through all sections and still don't have enough questions, break to avoid infinite loop
        if (selectedSectionExamplesPool.length < needToDisplay) break;
      }

      setFinalSetOfExamplesToDisplay(selectedSectionExamplesPool);
    };

    selectQuestions();
  }, [selectedTopic.topicNumber, exampleDatafromBackEnd]);

  console.log(finalSetOfExamplesToDisplay);

  // let selectedQuizQuestions = [];

  // // if (location.state.comingFrom == "Topic") {
  // //   selectedQuizQuestions = location.state.topicSelectedQuizQuestions;
  // // } else if (location.state.comingFrom == "Section") {
  // //   selectedQuizQuestions = location.state.sectionSelectedQuizQuestions;
  // // } else if (location.state.comingFrom == "ScoreCard") {
  // //   selectedQuizQuestions = location.state.scoreCardSelectedQuizQuestons;
  // // }

  // console.log(selectedQuizQuestions);

  // //When user changes quiz then set submitted back to false

  useEffect(() => {
    setSubmitted(false);
    setSumCorrect([]);
  }, [selectedTopic.topicNumber]);

  useEffect(() => {
    setLives(3);
    setCurrentQuestionIndex(0);
    setWrongQuestions([]);
    setShowingWrongQuestions(false);
    setQuizCompleted(false);
    setSumCorrect([]);
    setAnswerSelected(false);
    setResetTrigger((prev) => prev + 1);
  }, [selectedTopic.topicNumber]);

  //End

  const sum = sumCorrect.reduce(function (a, b) {
    return a + b;
  }, 0);

  const scorePercentage = Math.round((sum / sumCorrect.length) * 100);

  const handleAnswer = (isCorrect) => {
    const newSumCorrect = [...sumCorrect];
    if (!showingWrongQuestions) {
      newSumCorrect[currentQuestionIndex] = isCorrect ? 1 : 0;
      if (!isCorrect) {
        setWrongQuestions((prev) => [...prev, currentQuestionIndex]);
        setLives((prev) => prev - 1);
      }
    } else {
      const originalIndex = remainingWrongQuestions[currentQuestionIndex];
      newSumCorrect[originalIndex] = isCorrect ? 1 : 0;
      if (isCorrect) {
        setCorrectedWrongQuestions((prev) => [...prev, currentQuestionIndex]);
      } else {
        setLives((prev) => prev - 1);
      }
    }
    setSumCorrect(newSumCorrect);
    setAnswerSelected(true);
  };

  const handleContinue = () => {
    // Add small delay to see button press animation
    setTimeout(() => {
      if (lives === 0) {
        setQuizCompleted(true);
        return;
      }

      if (quizContainerRef.current) {
        quizContainerRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }

      if (!showingWrongQuestions) {
        if (currentQuestionIndex < finalSetOfExamplesToDisplay.length - 1) {
          setCurrentQuestionIndex((prev) => prev + 1);
        } else {
          if (wrongQuestions.length > 0) {
            setShowFullScreenMessage(true);
          } else {
            setQuizCompleted(true);
          }
        }
      } else {
        const newRemainingWrongQuestions = remainingWrongQuestions.filter(
          (_, index) => !correctedWrongQuestions.includes(index)
        );

        if (newRemainingWrongQuestions.length === 0) {
          setQuizCompleted(true);
        } else {
          setRemainingWrongQuestions(newRemainingWrongQuestions);
          setCorrectedWrongQuestions([]);

          if (currentQuestionIndex < newRemainingWrongQuestions.length - 1) {
            setCurrentQuestionIndex((prev) => prev + 1);
          } else {
            setCurrentQuestionIndex(0);
          }
        }
      }
      setResetTrigger((prev) => prev + 1);
      setAnswerSelected(false);
    }, 150); // 150ms delay to see the button press
  };

  const startReviewingWrongQuestions = () => {
    setTimeout(() => {
      setShowFullScreenMessage(false);
      setShowingWrongQuestions(true);
      setCurrentQuestionIndex(0);
      setRemainingWrongQuestions([...wrongQuestions]);
      setCorrectedWrongQuestions([]);
    }, 150);
  };

  const currentQuestion = showingWrongQuestions
    ? finalSetOfExamplesToDisplay[remainingWrongQuestions[currentQuestionIndex]]
    : finalSetOfExamplesToDisplay[currentQuestionIndex];

  const calculateProgressPercentage = () => {
    const totalQuestions = finalSetOfExamplesToDisplay.length;
    const correctAnswers = sumCorrect.reduce((a, b) => a + b, 0);

    if (sumCorrect.length === 0) return 0;
    return Math.ceil((correctAnswers / totalQuestions) * 100);
  };

  const [progressPercentage, setProgressPercentage] = useState(0);

  useEffect(() => {
    setProgressPercentage(calculateProgressPercentage());
  }, [sumCorrect]);

  const resetQuiz = () => {
    setTimeout(() => {
      setLives(3);
      setCurrentQuestionIndex(0);
      setWrongQuestions([]);
      setShowingWrongQuestions(false);
      setQuizCompleted(false);
      setSumCorrect([]);
      setAnswerSelected(false);
      setResetTrigger((prev) => prev + 1);
    }, 150);
  };

  console.log(
    sumCorrect,
    sum,
    "currentQuestionIndex ",
    currentQuestionIndex,
    "wrongQuestionIndex",
    wrongQuestionIndex,
    "wrongQuestions",
    wrongQuestions,
    "correctedWrongQuestions",
    correctedWrongQuestions,
    "remainingWrongQuestions",
    remainingWrongQuestions
  );

  //Code for setting background of scorecard

  const [dominantColor, setDominantColor] = useState("");
  const [textColor, setTextColor] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [backgroundColor, setBackgroundColor] = useState("#dd6260");

  console.log(props.entries);

  function getFirstEntrySectionImage(data) {
    if (Array.isArray(data) && data.length > 0) {
      const firstTopic = data[0];
      if (
        firstTopic.entries &&
        Array.isArray(firstTopic.entries) &&
        firstTopic.entries.length > 0
      ) {
        const firstEntry = firstTopic.entries[0];
        return firstEntry.sectionImage || null;
      }
    }
    return null;
  }

  const firstSectionImage = nextChapterFirstLessonImage;
  console.log(nextChapterFirstLessonImage);

  useEffect(() => {
    const imageUrl = firstSectionImage;

    fetchAndExtractColorWithCache(imageUrl);
  }, [nextChapterFirstLessonImage]);

  const fetchAndExtractColorWithCache = async (imageUrl) => {
    const cachedColor = localStorage.getItem(imageUrl);

    if (cachedColor) {
      handleColorExtraction(cachedColor);
    } else {
      try {
        const response = await fetch(imageUrl, {
          method: "GET",
          headers: {
            "Cache-Control": "no-cache",
          },
        });

        if (response.ok) {
          const blob = await response.blob();
          const reader = new FileReader();

          reader.readAsDataURL(blob);
          reader.onloadend = () => {
            const base64data = reader.result;

            const image = new Image();
            image.src = base64data;

            image.onload = () => {
              const colorThief = new ColorThief();
              const dominantColorRGB = colorThief.getColor(image);
              const dominantColorCSS = `rgb(${dominantColorRGB[0]}, ${dominantColorRGB[1]}, ${dominantColorRGB[2]})`;

              handleColorExtraction(dominantColorCSS);

              // Store the color in local storage for caching
              localStorage.setItem(imageUrl, dominantColorCSS);
            };
          };
        } else {
          console.error("Image fetch failed:", response.status);
        }
      } catch (error) {
        console.error("Error fetching image:", error);
      }
    }
  };

  const handleColorExtraction = (color) => {
    setDominantColor(color);
    const luminance = calculateLuminance(color);
    const textColor = luminance > 0.5 ? "#000" : "#fff";
    setTextColor(textColor);
    setIsLoading(false);
  };

  const calculateLuminance = (color) => {
    const rgb = color.match(/\d+/g);
    const luminance = (0.299 * rgb[0] + 0.587 * rgb[1] + 0.114 * rgb[2]) / 255;
    return luminance;
  };

  console.log(dominantColor);

  const getSectionInfoByNumber = (sectionNumber) => {
    if (entryStore) {
      for (const topic of entryStore) {
        for (const entry of topic.entries) {
          for (const section of entry.entries) {
            for (const lesson of section.entries) {
              if (lesson.sectionNumber === sectionNumber) {
                return {
                  title: lesson.title,
                  image: lesson.sectionImage,
                };
              }
            }
          }
        }
      }
    }
    return { title: null, image: null };
  };

  return (
    <ScoreContext.Provider value={[sumCorrect, setSumCorrect]}>
      <SubmitButtonContext.Provider value={[submitted, setSubmitted]}>
        <div>
          <LessonMainSideNav></LessonMainSideNav>
          <div className="Quiz" ref={quizContainerRef}>
            <div className="QuizContainer">
              {/* <CenteredButtonContainer>
            <Link
              to={`/${courseTitleUserIsOn}/topic/` + selectedTopic.topicNumber}
            >
              <StudyModeButton>
                <p style={{ fontSize: "2vh", margin: "0px" }}>Switch to</p>
                <b>Study Mode</b>
              </StudyModeButton>
            </Link>
          </CenteredButtonContainer> */}

              {!quizCompleted && (
                <>
                  <div style={{}} className="LessonTitleContainer">
                    <span className="LessonNumber">
                      Chapter Quiz {selectedTopic.topicNumber}
                    </span>{" "}
                    <br />
                    <span className="LessonTitle">
                      {selectedTopic.topicTitle}
                    </span>
                    <div
                      className="confettiContainer"
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    ></div>
                  </div>{" "}
                  <StatusContainer>
                    <ProgressBarContainer>
                      <QuizProgressBar percentage={progressPercentage} />
                    </ProgressBarContainer>
                    <HeartContainer>
                      <HeartIcon icon={faHeart} />
                      <HeartCount>{lives}</HeartCount>
                    </HeartContainer>
                  </StatusContainer>
                </>
              )}

              {lives === 0 && (
                <>
                  <MessageText>
                    <div style={{ color: "red", fontSize: "2.5vh" }}>
                      {" "}
                      You ran out of chances!
                    </div>
                  </MessageText>

                  {showingWrongQuestions
                    ? remainingWrongQuestions.map((index) => {
                        const sectionNumber =
                          finalSetOfExamplesToDisplay[index].sectionNumber;
                        const { title: sectionTitle, image: sectionImage } =
                          getSectionInfoByNumber(sectionNumber);

                        return (
                          <div
                            key={index}
                            style={{
                              background: "#D2CFE6",
                              borderRadius: "20px",
                              margin: "20px",
                              marginBottom: "50px",
                              padding: "20px",
                            }}
                          >
                            <ExampleQuestion>
                              {ReactHtmlParser(
                                finalSetOfExamplesToDisplay[index].question
                              )}
                            </ExampleQuestion>
                            <motion.div
                              className="LessonExampleNextLessonBox"
                              style={{ marginTop: "0" }}
                              initial={{ opacity: 0, scale: 0.5 }}
                              animate={{ opacity: 1, scale: [1, 1.03, 1] }}
                              transition={{
                                duration: 0.8,
                                delay: 0.3,
                                ease: [0, 0.71, 0.2, 1.01],
                              }}
                            >
                              <Link
                                to={`/${courseTitleUserIsOn}/lesson/${sectionNumber}`}
                              >
                                <motion.div
                                  whileTap={{ scale: 0.8 }}
                                  transition={{
                                    type: "spring",
                                    stiffness: 400,
                                    damping: 17,
                                  }}
                                  style={{}}
                                >
                                  <SideNavRowLesson
                                    number={sectionNumber}
                                    image={sectionImage}
                                    title={sectionTitle}
                                    width="100%"
                                    marginLeft="0"
                                    borderRadius="14% 2% 2% 14% / 50% 10% 10% 50%"
                                  />
                                </motion.div>
                              </Link>
                            </motion.div>
                          </div>
                        );
                      })
                    : wrongQuestions.map((index) => {
                        const sectionNumber =
                          finalSetOfExamplesToDisplay[index].sectionNumber;
                        const { title: sectionTitle, image: sectionImage } =
                          getSectionInfoByNumber(sectionNumber);

                        return (
                          <div
                            key={index}
                            style={{
                              background: "#D2CFE6",
                              borderRadius: "20px",
                              margin: "20px",
                              marginBottom: "50px",
                              padding: "20px",
                            }}
                          >
                            <ExampleQuestion>
                              {ReactHtmlParser(
                                finalSetOfExamplesToDisplay[index].question
                              )}
                            </ExampleQuestion>
                            <motion.div
                              className="LessonExampleNextLessonBox"
                              style={{ marginTop: "0" }}
                              initial={{ opacity: 0, scale: 0.5 }}
                              animate={{ opacity: 1, scale: [1, 1.03, 1] }}
                              transition={{
                                duration: 0.8,
                                delay: 0.3,
                                ease: [0, 0.71, 0.2, 1.01],
                              }}
                            >
                              <Link
                                to={`/${courseTitleUserIsOn}/lesson/${sectionNumber}`}
                              >
                                <motion.div
                                  whileTap={{ scale: 0.8 }}
                                  transition={{
                                    type: "spring",
                                    stiffness: 400,
                                    damping: 17,
                                  }}
                                  style={{}}
                                >
                                  <SideNavRowLesson
                                    number={sectionNumber}
                                    image={sectionImage}
                                    title={sectionTitle}
                                    width="100%"
                                    marginLeft="0"
                                    borderRadius="14% 2% 2% 14% / 50% 10% 10% 50%"
                                  />
                                </motion.div>
                              </Link>
                            </motion.div>
                          </div>
                        );
                      })}

                  <QuizSolidButton
                    onClick={resetQuiz}
                    style={{
                      backgroundColor: "#8747d4",
                      position: "fixed",
                    }}
                    shadowColor="#6535a0"
                  >
                    TRY AGAIN
                  </QuizSolidButton>
                </>
              )}
              {quizCompleted ? (
                <>
                  <div
                    className="confettiContainer"
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {sumCorrect.reduce((a, b) => a + b, 0) ===
                      sumCorrect.length && (
                      <ConfettiExplosion
                        height={"1000vh"}
                        particleSize={15}
                        duration={5000}
                        particleCount={350}
                        spread={360}
                        origin={{ x: 0.8, y: 0.5 }}
                        force={0.8}
                        width={1600}
                      />
                    )}
                  </div>
                  <div style={{ marginTop: "50px" }}>
                    {" "}
                    <ScoreCard
                      sections={sectionList}
                      topicNumber={props.match.params.topicNumber}
                      chapterNumber={selectedTopic.topicNumber}
                      nextChapterNumber={nextChapterNumber}
                      nextChapterTitle={nextChapterTitle}
                      nextChapterFirstLessonNumber={
                        nextChapterFirstLessonNumber
                      }
                      nextChapterFirstLessonTitle={nextChapterFirstLessonTitle}
                      nextChapterFirstLessonImage={nextChapterFirstLessonImage}
                      dominantColor={dominantColor}
                      textColor={textColor}
                    />
                  </div>
                </>
              ) : (
                <>
                  {showFullScreenMessage && (
                    <FullScreenMessage>
                      <MessageText>
                        Let's get the missed questions corrected!
                      </MessageText>
                      <QuizSolidButton
                        onClick={startReviewingWrongQuestions}
                        style={{
                          backgroundColor: "#8747d4",
                          position: "fixed",
                        }}
                        shadowColor="#6535a0"
                      >
                        CONTINUE
                      </QuizSolidButton>
                    </FullScreenMessage>
                  )}
                  {!quizCompleted && !showFullScreenMessage && lives !== 0 && (
                    <div
                      style={{
                        position: "relative",
                        width: "100%",
                      }}
                    >
                      <AnimatePresence mode="wait">
                        <AnimatedQuizContainer
                          key={currentQuestionIndex}
                          initial={{ opacity: 0, x: "100%" }}
                          animate={{ opacity: 1, x: 0 }}
                          exit={{ opacity: 0, x: "-100%" }}
                          transition={{ duration: 0.3, ease: "easeInOut" }}
                          style={{
                            position: "absolute",
                            width: "100%",
                            left: 0,
                            right: 0,
                          }}
                        >
                          <QuizExample
                            topicNumber={props.match.params.topicNumber}
                            example={currentQuestion}
                            exampleIndex={
                              showingWrongQuestions
                                ? remainingWrongQuestions[currentQuestionIndex]
                                : currentQuestionIndex
                            }
                            onAnswer={handleAnswer}
                            resetTrigger={resetTrigger}
                            correctSound={correctSound}
                            incorrectSound={incorrectSound}
                          />
                        </AnimatedQuizContainer>
                      </AnimatePresence>

                      {answerSelected && lives > 0 && (
                        <QuizSolidButton
                          onClick={handleContinue}
                          style={{
                            backgroundColor: showingWrongQuestions
                              ? correctedWrongQuestions.includes(
                                  currentQuestionIndex
                                )
                                ? "rgb(80, 200, 120)"
                                : "red"
                              : sumCorrect[currentQuestionIndex]
                              ? "rgb(80, 200, 120)"
                              : "red",
                            position: "fixed",
                          }}
                          shadowColor={
                            showingWrongQuestions
                              ? correctedWrongQuestions.includes(
                                  currentQuestionIndex
                                )
                                ? "rgb(60, 150, 90)"
                                : "#cc0000"
                              : sumCorrect[currentQuestionIndex]
                              ? "rgb(60, 150, 90)"
                              : "#cc0000"
                          }
                        >
                          CONTINUE
                        </QuizSolidButton>
                      )}
                      <div style={{ minHeight: "800px" }}></div>
                    </div>
                  )}
                </>
              )}
              <div style={{ height: "100px" }}></div>
            </div>
          </div>
        </div>
      </SubmitButtonContext.Provider>
    </ScoreContext.Provider>
  );
};

export default Quiz;
