import React, { useState } from "react";
import styled from "styled-components";
import Logout from "../userAuth/Logout";
import { Link, useHistory } from "react-router-dom";
import { useAmazonContext } from "../../Contexts/AmazonContext";
import BottomNavBar from "./BottomNavBar";
import { FaUser, FaEnvelope, FaCrown, FaSmile } from "react-icons/fa";
import SolidButton from "../SolidButton";
import api from "../api";

import {
  Header,
  ScrollContainer,
  BookCover,
  CoverImage,
  NavButton,
} from "./landingPageSharedStyledComponents";

const Container = styled.div`
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const ProfileInfo = styled.div`
  background-color: #d2cfe6;
  padding: 20px;
  border-radius: 10px;
  margin: 0;
`;

const InfoItem = styled.div`
  font-size: 2.5vh;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  color: ${(props) =>
    props.isDarkMode ? "var(--font-dark)" : "var(--font-light)"};
  font-family: "Quicksand", sans-serif;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;

  svg {
    margin-right: 10px;
    color: #8747d4;
  }
`;

const ButtonContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 150px);
  gap: 10px;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0 20px;
  margin: 0 auto;

  button,
  a {
    font-size: 14px;
    width: 150px;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
`;

const ToggleSwitch = styled.label`
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin-top: 20px;
  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #eb8381;
    transition: 0.4s;
    border-radius: 34px;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
  }

  input:checked + .slider {
    background-color: #b0240f;
  }

  input:checked + .slider:before {
    transform: translateX(26px);
    background-color: white;
  }
`;

const ThemeToggleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding: 0 20px;
  width: 100%;
  margin: 0;
  margin-top: 20px;
`;

const ThemeLabel = styled.p`
  font-family: "Quicksand", sans-serif;
  font-size: 2.5vh;
  color: ${(props) =>
    props.isDarkMode ? "var(--font-dark)" : "var(--font-light)"};
  margin: 0;
  display: flex;
  align-items: center;
  height: 34px;
`;

const LogoutContainer = styled.div`
  position: fixed;
  bottom: 250px;
  left: 28%;
  transform: translateX(-50%);
  width: calc(100% - 40px);
  max-width: 560px;
  padding: 0 20px;
`;

const SolidButtonGold = styled(SolidButton)`
  &.primary {
    background: #f7c054;
    color: white;
    box-shadow: 0 4px 0 #d9a43d;
    width: 150px !important;
    height: 150px !important;

    &:hover {
      background: #ffd066;
    }

    &:active {
      box-shadow: 0 0 0 #d9a43d;
    }
  }
`;

const DonationSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FeedbackButton = styled(SolidButton)`
  &.primary {
    background: #8747d4;
    color: white;
    box-shadow: 0 4px 0 #6535a0;
    width: 150px !important;
    height: 150px !important;

    &:hover {
      background: #9859e0;
    }

    &:active {
      box-shadow: 0 0 0 #6535a0;
    }
  }
`;

function Profile() {
  const userEmail = localStorage.getItem("userEmail");
  let { userRole, isDarkMode, toggleMode } = useAmazonContext();
  const history = useHistory();

  const handleLessonBuilder = (e) => {
    e.preventDefault();
    setTimeout(() => {
      history.push("/lesson-builder");
    }, 150);
  };

  const handleDashboard = (e) => {
    e.preventDefault();
    setTimeout(() => {
      history.push("/dashboard");
    }, 150);
  };

  const handleFeedbackClick = () => {
    history.push("/feedback-forum");
  };

  return (
    <>
      <Header className="sideNavChapterHeader">
        <p>My Profile</p>
      </Header>
      <Container>
        <ProfileInfo>
          <InfoItem isDarkMode={isDarkMode}>
            <FaEnvelope /> {userEmail}
          </InfoItem>
          {userRole === "admin" && (
            <InfoItem isDarkMode={isDarkMode}>
              <FaCrown /> Admin privileges
            </InfoItem>
          )}
        </ProfileInfo>

        <ButtonContainer>
          {userRole === "admin" && (
            <>
              <SolidButton className="primary" onClick={handleLessonBuilder}>
                GO TO LESSON BUILDER
              </SolidButton>
              <SolidButton className="primary" onClick={handleDashboard}>
                DASHBOARD
              </SolidButton>
            </>
          )}
          <SolidButtonGold
            className="primary"
            as="a"
            href="https://www.paypal.com/donate/?hosted_button_id=82TNM9C7875YC"
            target="_blank"
            rel="noopener noreferrer"
          >
            INVEST IN OUR SCHOOL
          </SolidButtonGold>
          <FeedbackButton className="primary" onClick={handleFeedbackClick}>
            REQUEST A COURSE / FEATURE
          </FeedbackButton>
        </ButtonContainer>

        <ThemeToggleContainer>
          <ThemeLabel isDarkMode={isDarkMode}>Dark/Light Mode</ThemeLabel>
          <ToggleSwitch>
            <input type="checkbox" checked={isDarkMode} onChange={toggleMode} />
            <span className="slider"></span>
          </ToggleSwitch>
        </ThemeToggleContainer>
      </Container>

      <LogoutContainer>
        <Logout />
      </LogoutContainer>
      <BottomNavBar />
    </>
  );
}

export default Profile;
