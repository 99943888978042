import React from "react";
import { motion } from "framer-motion";
import { useHistory } from "react-router-dom";
import LottieAnimation from "../LottieAnimation";
import SolidButton from "../SolidButton";

function WelcomeMessageTwo() {
  const history = useHistory();

  const handleContinue = (e) => {
    e.preventDefault();
    setTimeout(() => {
      history.push("/WelcomeMessageThree");
    }, 150);
  };

  const convertToCloudFrontUrl = (s3Url) => {
    if (!s3Url) return s3Url;
    return s3Url.replace(
      "https://hhcourses-assets.s3.us-east-2.amazonaws.com",
      "https://d10jmd9bhq0s4v.cloudfront.net"
    );
  };

  return (
    <div className="WelcomeMessageContainer">
      <div className="WelcomeMessageImagesContainer">
        <div>
          <LottieAnimation
            lottieFile={convertToCloudFrontUrl(
              "https://hhcourses-assets.s3.us-east-2.amazonaws.com/General/LottieFiles/InteractiveLesson.json"
            )}
            style={{ width: "400px", marginTop: "200px" }}
          />
        </div>
      </div>
      <div className="WelcomeMessageFooter">
        <div className="MessageTopSection">
          <div className="WelcomeMessageText">
            <b style={{ color: "#dd6260" }}>Lessons</b> are bite sized, visual,
            and tappable to hold your attention.
          </div>
        </div>
        <div className="MessageMiddleSection">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <SolidButton className="primary" onClick={handleContinue}>
              CONTINUE
            </SolidButton>
          </div>
        </div>
        <div className="MessageBottomSection"></div>
      </div>
    </div>
  );
}

export default WelcomeMessageTwo;
